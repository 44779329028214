import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Link } from 'react-router-dom';

function Referral() {
	const baseUrl = process.env.REACT_APP_REST_API_URL;
	const reactBaseUrl = process.env.REACT_APP_URL;

	const [affiliate, setAffiliate] = useState();
	var user_id = localStorage.getItem('user_id');

	useEffect(() => {
		const apiUrl = `${baseUrl}/affiliates/${user_id}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setAffiliate(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
	};

	return (
		<main>
			<section class="py-5 homepage-search-block position-relative">
				<div class="container">
					{affiliate && (
						<div>
							<div class="row py-lg-2">
								<div class="col-lg-6">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0"><b> Referral Partner Program </b></h6>
											<p class="mb-0 mt-0 small">Share the link below and get paid every time the service provider completes a job.
												<br />
											</p>
										</div>
										<div class="box-body">
											<div class="p-3">
												<b>Referral Partner Program Rules</b>:<br /><br />
												Earn continuous monthly income by referring service providers to TaskBeehive. Service providers pay a 5% fee on earnings from completed jobs. The referrer will get this entire fee. The more jobs the service provier does the more the referrer earns. <br /><br />
												<b>Your Referral URL</b>: <br /><br />
												<Link to={`${reactBaseUrl}/partner?id=${user_id}`}>
													{reactBaseUrl}/partner?id={user_id}
												</Link><br /><br />
												<button class="btn btn-sm btn-success" onClick={() => copyToClipboard(`${reactBaseUrl}/partner?id=${user_id}`)}>Copy Referral Link To Clipboard</button>
												<br />
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-6">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0"><b> Current Stats And Payment</b></h6>
											<p class="mb-0 mt-0 small">The amount of people you've signed up and cash earned.
												<br />
											</p>
										</div>
										<div class="box-body">
											<div class="p-3">
												<b>Total Service Providers Registered</b>: {affiliate.totalRegistered}<br />
												<b>Total Money Earned</b>: ${affiliate.totalMoney}<br />
												<b>Total Paid Out</b>: ${affiliate.totalPaidOut}<br />
												<b>Current Balance</b>: ${affiliate.totalMoney - affiliate.totalPaidOut}<br /><br />
												<b>Payout</b>:<br /><br />
												<Link to="/requestPayout"><button class="btn btn-sm btn-success">Request Payout</button></Link>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row py-lg-2">

								<div class="col-lg-12">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3" id="benefits-for-service-providers">
											<h6 class="m-0"> Benefits Of Joining For Service Providers And Businesses
											</h6>
										</div>
										<div class="box-body">
											<br />
											<div class="container">
												<div class="row justify-content-center align-items-center">
													<div class="col-lg-6">
														<div class="text-content justify-content-center align-items-center">
															<p style={{ fontSize: "13px" }}>
																<b>Service Providers</b><br /> Work for yourself, connect with customers, and turn your skills or passion into a business.
																<br /><br />
																<b>Businesses</b><br /> Find new customers, create a business page, manage providers, and track their earnings and reviews.
															</p>
															<Link to="/about">
																<button class="btn btn-sm btn-success">Learn More About Task Beehive</button>
															</Link>
														</div>
													</div>
													<div class="col-lg-6">
														<div class="p-3" style={{ maxWidth: '560px' }}>
															<div class="video-responsive">
																<iframe
																	width="560"
																	height="315"
																	src="https://www.youtube.com/embed/3wWNVWbyL8g?rel=0&showinfo=0&autoplay=0&controls=0"
																	frameborder="0"
																	allowfullscreen
																	title="Embedded YouTube"
																></iframe>
															</div>
														</div>
													</div>
												</div>
											</div>
											<br />
										</div>
									</div>
								</div>

							</div>
						</div>
					)}
				</div>
			</section >
		</main >
	);
}

export default Referral;