import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function EditUser() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable
	const location = useLocation();

	const [user, setUser] = useState();
	const [prevEmail, setPrevEmail] = useState();
	var user_id = localStorage.getItem('user_id');

	// Get services effect
	useEffect(() => {
		const apiUrl = `${baseUrl}/users/${user_id}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				data["password"] = "*****"
				setUser(data);
				setPrevEmail(data.email);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	useEffect(() => {
		$("#password2").val("*****");

		$("#target").off('submit');
		$("#target").submit(function(event) {
			event.preventDefault();
			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});

			const email = $('#email').val();
			if (!validateEmail(email)) {
				$("#alertText").html("Incorrect email format. Please try again.");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
				return false;
			}

			if ($('#password').val() !== $('#password2').val()) {
				$("#alertText").html("Passwords didn't match.");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
				return false;
			}
			
			if (prevEmail != email) {
				indexed_array["emailConfirmed"] = false;
			}

			const apiUrl = `${baseUrl}/users/${user_id}`;
			$.ajax({
				url: apiUrl,
				type: 'PUT',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(msg) {
					if (prevEmail != email) {
						localStorage.setItem('user_email', email)
						navigate(`/emailVerification?forward=/&userId=${user_id}`);
					} else {
						navigate("/");
					} 
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		});
	}, [user]);

	const handleChange = (event) => {
		setUser({ ...user, [event.target.name]: event.target.value });
	};

	function validateEmail(email) {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(String(email).toLowerCase());
	}

	return (
		<main>
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>

				{user && (
					<form action="index" id="target">
						<div class="dlevitin-login py-4">

							<div class="row justify-content-center align-items-center">
								<div class="col-md-10">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-body p-3" >
											<div class="row justify-content-center align-items-center d-flex vh-70">
												<div class="col-lg-5">
													<div class="text-center mb-4">
														<h5 class="font-weight-bold mt-4">Edit User Profile</h5>
														<p class="text-muted">Update your basic user information.</p>
													</div>
												</div>
											</div>
											<div class="row justify-content-center align-items-center d-flex vh-70">
												<div class="col-lg-8">
													<div class="form-row">
														<div class="col">
															<div class="form-group">
																<label class="mb-1">Full Name</label>
																<div class="input-group">
																	<input required name="name" type="text" maxlength="250" class="form-control" value={user.name} onChange={handleChange} />
																</div>
															</div>
														</div>
														<div class="col">
															<div class="form-group ">
																<label class="mb-1" for="email"> Email </label>
																<div class="input-group">
																	<input required class="form-control" id="email" name="email" value={user.email} onChange={handleChange} type="email" maxlength="250" />
																</div>
															</div>
														</div>
													</div>
													<div class="form-row">
														<div class="col">
															<div class="form-group">
																<label class="mb-1">Password</label>
																<div class="input-group">
																	<input required type="password" minlength="4" maxlength="250" name="password" value={user.password} onChange={handleChange} id="password"
																		class="form-control" />
																</div>
															</div>
														</div>
														<div class="col">
															<div class="form-group">
																<label class="mb-1">Confirm Password</label>
																<div class="input-group">
																	<input required type="password" minlength="4" maxlength="250" name="password2" id="password2"
																		class="form-control" />
																</div>
															</div>
														</div>
													</div>
													<div class="form-row">
														<div class="col">
															<div class="form-group">
																<label class="mb-1">Address</label>
																<div class="input-group">
																	<input required type="text" maxlength="250" name="address" value={user.address} onChange={handleChange}
																		class="form-control" />
																</div>
															</div>
														</div>
													</div>
													<div class="form-row">
														<div class="col">
															<div class="form-group">
																<label class="mb-1">City</label>
																<div class="position-relativel">
																	<input required type="text" maxlength="250" name="city" value={user.city} onChange={handleChange} class="form-control" />
																</div>
															</div>
														</div>
														<div class="col">
															<div class="form-group">
																<label class="mb-1">State</label>
																<div class="position-relative">
																	<input required type="text" maxlength="250" name="state" value={user.state} onChange={handleChange}
																		class="form-control" />
																</div>
															</div>
														</div>
														<div class="col">
															<div class="form-group">
																<label class="mb-1">Zip Code</label>
																<div class="position-relative">
																	<input required type="text" maxlength="250" name="zip" value={user.zip} onChange={handleChange} class="form-control" />
																</div>
															</div>
														</div>
														<input hidden name="emailConfirmed" value={user.emailConfirmed} />
													</div>
													<br />
													<br />
													<button class="btn btn-success btn-block text-uppercase"
														type="submit">Update User</button>
													<br />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				)}
			</div >
		</main >
	);
}

export default EditUser;