import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const CheckLogin = () => {
	const userName = localStorage.getItem('user_name');
	const location = useLocation();
	const redirectPath = location.pathname + location.search;
	return userName ? <Outlet /> : <Navigate to={`/login?redirect=${redirectPath}`} />;
}

export default CheckLogin