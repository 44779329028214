import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function ServiceProviderAgreement() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	return (
		<main>
			<br /> <br />
			<div class="container">
				<div class="box mb-3 shadow-sm rounded bg-white profile-box">
					<div class="box-body p-5">
						<br />
						<div style={{ textAlign: 'center' }}><h2>Task Beehive LLC Service Provider Agreement <small>(updated May 19, 2024)</small></h2></div>
						<br /><br />
						<p>This Service Provider Agreement constitutes a legally binding agreement between you and Task Beehive LLC. ( “Task Beehive” ) governing your use of TaskBeehive Website, and related services, information and communications, further on referred to Task Beehive Platform</p>
						<p><b>IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT, AS WELL AS THE <Link to="/userAgreement">USER AGREEMENT</Link>, AND ABIDE BY ITS TERMS, YOU MAY NOT USE OR ACCESS TASK BEEHIVE PLATFORM</b></p>
						<br />
						<h4>1. Introduction </h4>
						<p> Task Beehive Platform provides a way for Service Providers to find Clients for their service. </p>
						<p> Upon your agreement to perform a Task for the Client, you form a binding contract between yourself and a Client</p>
						<br />
						<h4>2. Service Provider </h4>
						<p> Service Provider - an individual or an entity that is registered through Task Beehive LLC to provide services to Task Beehive LLC users.</p>
						<p> Service Providers are independent contractors of their clients and are not employees, business partners, representatives, or agents of Task Beehive LLC. </p>
						<p> Acceptance to the Task Beehive Platform does not constitute a business partnership. </p>
						<p> Users employ Service Providers and upon the agreement have a binding contract with Service Providers only. There is no binding contract created between the User and Task Beehive LLC or Service Provider and Task Beehive LLC. </p>
						<p> The relationship between Users and Service Providers on the Task Beehive platform is that of an independent contractor. This means that Service Providers are not employees, agents, or representatives of Task Beehive, LLC. As such, Service Providers are responsible for their own work schedules, tax withholdings, insurance, and adherence to local, state, and federal regulations pertinent to their services. Users, in turn, engage with Service Providers at their own risk, acknowledging that Task Beehive, LLC does not supervise, direct, control, or monitor a Service Provider's work and is not responsible for the work performed or the process in which it is completed.</p>
						<p> Service Providers must adhere to the scope of services they define on their profile. Any significant deviation from the advertised scope may result in corrective actions by Task Beehive LLC, including termination of the agreement. </p>
						<br />
						<h4>3. Requirements </h4>
						<p>a. Service Providers must be at least 16 years of age and be able to enter into binding contracts </p>
						<p>b. Service Providers must have the skills and experience to do the tasks they are advertising </p>
						<p>c. Service Providers must have the necessary licenses and certifications as required by law </p>
						<p>d. Service Providers must carry all the necessary insurances to cover themselves and the users as required by law</p>
						<p>e. Service Providers must honor their commitments to other Users on the Websites, including by promptly responding; performing the job as agreed upon with your Client; and providing timely, high-quality services to your Clients </p>
						<p>f. Service Providers must honor their availability and scheduled appointments. </p>
						<p>g. Service Providers must honor their payment or fee requirements to Task Beehive LLC. There is a registration service fee which is liable to change at anytime at Task Beehive LLC's discretion  </p>
						<p>h. Service Providers must collect their own payments from their Clients. Through the messaging platform or direct communication the Service Providers can agree for payment before or after the job at their own discretion. </p>
						<p>i. Service Providers are responsible for any content that they post or upload through Task Beehive LLC. Any Service Provider content is the property of the Service Provider. </p>
						<br />
						<h4>4. Code of Conduct </h4>
						<p>a. Service Providers may not use Offensive, harmful, and/or abusive language, including without limitation: expletives, profanities, obscenities, harassment, vulgarities, sexually explicit language and hate speech (e.g., racist/discriminatory speech.) in their description page or in any communication with Users or Task Beehive LLC employees. This includes but is not limited to any communication in-person, via Task Beehive LLC chat system, or email system. </p>
						<p>b. Service Providers may not upload Offensive, harmful, and/or abusive content including anything sexually explicit or any nudity. </p>
						<p>c. Service Providers may not engage in any activity that would be against the local, state, or federal law. </p>
						<p>d. Service Providers may not use Task Beehive Platform for the purchase or delivery of alcohol, tobacco, or any other controlled or illegal substances or services. </p>
						<p>d. Service Providers may not use Task Beehive Platform for the purchase or delivery of weapons. </p>
						<p>e. Service Providers may not use Task Beehive Platform for any reason other than to advertise their task and communicate to the Client about their task. That includes that Service Providers may not use the Task Beehive platform to harrass, threaten, or solicit.</p>
						<p>f. Service Providers are fully responsible and retain full ownership of any content that is uploaded to Task Beehive LLC website database or servers. </p>
						<p>g. Task Beehive LLC will have the final authority on what is and isn't appropriate on the site. Task Beehive LLC may remove or modify any content at its discretion. </p>
						<p>h. Service Providers must maintain the confidentiality of all proprietary or confidential information they are exposed to during their engagement with Task Beehive LLC and its users, except when disclosure is authorized or legally mandated.</p>
						<br />
						<h4>5. Limit on Liability </h4>
						<p>a. Task Beehive LLC does not endorse any Clients and provides no warranty about the worksite conditions, scope of work required, or the Client's ability to pay. </p>
						<p>b. Upon agreement the binding contract exists between the Service Provider and the Client and it's up to the Service Provider to make sure it contains all the relevant terms. It is also up to the Service Provider to enforce it.</p>
						<p>c. It is up to the Service Provider to make sure that the jobsite is safe. Task Beehive LLC is not liable for any damages resulting from the Service Provider's performance of their job. </p>
						<p>d. Task Beehive LLC provides no vetting of it's users, hence Task Beehive LLC is not liable for any erroneous information contained in the User's profile, including the description of the jobsite, scope of work, and any other information not mentioned. </p>
						<p>e. Service Providers agree to indemnify Task Beehive LLC from any damage caused by it's review system including negative impact on reputation, negative image, or loss of revenue. Task Beehive LLC will consider all available information and is a final authority on the legitimacy of the review in it's review section. </p>
						<p>f. It is up to the Service Provider to make sure that the jobsite is safe. Task Beehive LLC is not liable for any damages, injuries, and bodily harm resulting from the Service Provider's performance of their job. </p>
						<p>g. Service Providers are responsible for their services, actions, and any liabilities arising from their work, and agree to indemnify Task Beehive LLC from any claims regarding this. Service Providers agree to indemnify Task Beehive LLC against any claims, damages, or liabilities arising from their services, including personal injury for either the client or service provider, property damage, or legal liabilities.</p>
						<p>h. Task Beehive LLC, can help at their discretion, but is in no way responsible for collecting payments from the Clients. Service Providers agree to indemnify Task Beehive LLC. from any payment dispute with the client. </p>
						<p>i. Task Beehive LLC is not responsible for any stage of the Client/Service Provider negotiation. </p>
						<p>j. In no event shall Task Beehive LLC be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly."</p>
						<br />
						<h4>6. Legal Disputes </h4>
						<p> Governing Law: This Agreement shall be governed by and construed in accordance with the laws of the State of Massachussets, without giving effect to any choice or conflict of law provision or rule (either of the State of Massachussets or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than the State of Massachussets. </p>
						<p> Arbitration: Any disputes between Service Providers and Task Beehive LLC will be resolved through the Binding Arbitration. </p>
						<br />
						<h4>7. Severability </h4>
						<p> If any clause, or portion of a clause, in this Agreement is considered invalid under the rule of law, it shall be regarded as stricken while the remainder of this Agreement shall continue to be in full effect. </p>
						<p> Task Beehive LLC reserves the right to terminate any section of this agreement at any time, with or without cause, and with immediate effect. Notification of termination will be communicated to the Service Provider. </p>
						<br />
						<h4>8. Demonstration Data Disclaimer </h4>
						<p>TaskBeehive may use test data such as, but not limited to, service providers, user profiles, and reviews for demonstration, test, and any other purposes it chooses. These test profiles and services are created to illustrate how the platform functions and appear to users, service providers, potential investors, and other parties that may want to learn about the platform. These demonstration profiles are non-functional, with no availability in their calendars, and cannot be ordered. By agreeing to this Service Provider Agreement, you acknowledge the presence of this test data and agree to indemnify, defend, and hold harmless TaskBeehive and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses arising out of or in any way connected with these test profiles. You agree that TaskBeehive is not engaging in any deceptive practices or malfeasance through the use of these test profiles. The sole purpose of these demonstrations is to provide a visual and functional representation of how the platform operates. You hereby waive any and all rights to bring any form of legal claim against TaskBeehive related to the use of these test profiles and demonstration data.</p>
						<br />
						<h4>9. Promotional Terms </h4>
						<p>TaskBeehive may initiate temporary promotions at any time, which may include registration fees, monthly fees, or other types of fees as outlined in section 10, 'Fee Collection and Payment Obligations'. These promotional offers may allow you to access our platform at no cost or a reduced cost. TaskBeehive reserves the right to modify or terminate any promotional offers at its sole discretion without prior notice. By agreeing to these terms, you acknowledge that promotions are subject to change at any time. After the promotional period, standard registration fees may apply. TaskBeehive retains full discretion over any modifications, extensions, or discontinuations of promotional offers. You hereby waive any right to challenge, dispute, or claim damages arising from the modification or termination of any promotional offers.</p>
						<br />

						<h4>10. Service Provider Agreement - Fee Collection and Service Inactivation Clause</h4>
						<p><b>Fee Collection and Payment Obligations</b>:<br/><br/>
							As a Service Provider on TaskBeehive.com, you acknowledge and agree that TaskBeehive LLC reserves the right to collect fees for the use of the platform and its services. These fees may be collected in various forms, including but not limited to:<br/><br/>
							1. Monthly Invoices: At the end of each month, TaskBeehive LLC will issue an invoice for a percentage of your total earnings from services provided through the platform. The specific percentage will be determined at the sole discretion of Task Beehive.<br/>
							2. Monthly Fees: TaskBeehive LLC may charge a fixed monthly fee for continued use of the platform.<br/>
							3. Registration Fees: A one-time or recurring fee for registering as a service provider.<br/>
							4. Lead Fees: Charges incurred for each potential client lead provided through the platform.<br/>
							5. Feature Charges: Fees for access to premium features or tools on the platform.<br/>
							6. Other Fees: Any other means of fee collection as deemed necessary by TaskBeehive LLC.<br/><br/>
							
							<b>Service Inactivation for Non-Payment</b>:<br/><br/>
							Failure to pay any required fees, including but not limited to monthly invoices and an initial registration fee, within the specified timeframe (usually within seven (7) days from the date of the invoice for the monthly fee, and an immediate payment for a registration fee after registration) will result in the temporary inactivation of your service provider account. During the period of inactivation:<br/><br/>

							- Previous Orders: You will still have access to existing orders and most features of your account.<br/>
							- New Orders: You will not be able to receive or accept new orders until the outstanding fees are paid in full.<br/><br/>
							TaskBeehive LLC retains full rights to enforce this inactivation policy to ensure compliance with the platform’s fee structure. The inactivation will remain in effect until all outstanding fees are settled. <br/><br/>
							TaskBeehive LLC and TaskBeehive.com shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages resulting from any inactivation of your service provider account or services due to non-payment of fees. You agree to indemnify and hold the Company harmless from any claims or demands, including reasonable attorney fees, arising out of your breach of this agreement or violation of any law or the rights of a third party.
						</p>
						<br/>
					</div>
				</div>
			</div>
		</main >
	);
}

export default ServiceProviderAgreement;
