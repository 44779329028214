import $ from 'jquery';

(function($) {
	Date.prototype.addDays = function(days) {
		var date = new Date(this.valueOf());
		date.setDate(date.getDate() + days);
		return date;
	}

	$.fn.markyourcalendar = function(opts) {
		var prevHtml = `
            <div id="myc-prev-week">
                <
            </div>
        `;
		var nextHtml = `<div id="myc-next-week">></div>`;
		var defaults = {
			availability: [[], [], [], [], [], [], []], // listahan ng mga oras na pwedeng piliin
			isMultiple: false,
			months: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
			prevHtml: prevHtml,
			nextHtml: nextHtml,
			selectedDates: [],
			startDate: new Date(),
			weekdays: ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'],
		};
		var settings = $.extend({}, defaults, opts);
		var html = ``;

		var onClick = settings.onClick;
		var onClickNavigator = settings.onClickNavigator;
		var instance = this;

		// kuhanin ang buwan
		this.getMonthName = function(idx) {
			return settings.months[idx];
		};

		var formatDate = function(d) {
			var date = '' + d.getDate();
			var month = '' + (d.getMonth() + 1);
			var year = d.getFullYear();
			if (date.length < 2) {
				date = '0' + date;
			}
			if (month.length < 2) {
				month = '0' + month;
			}
			return year + '-' + month + '-' + date;
		};

		function formatTime(time) {
			const timeArray = time.split(":");
			var hour = timeArray[0];
			if (hour === '24') {
				hour = '00'; // Convert 24:00 to 00:00
			}
			if (hour.startsWith("0") && hour != "0") {
				hour = hour.slice(1);
			}
			const amPm = hour >= 12 && hour < 24 ? "pm" : "am";
			const updatedHour = hour > 12 ? hour - 12 : hour === '0' ? 12 : hour;
			return `${updatedHour} ${amPm}`;
		}


		// Controller to change 
		this.getNavControl = function() {
			var previousWeekHtml = `<div id="myc-prev-week-container">` + settings.prevHtml + `</div>`;
			var nextWeekHtml = `<div id="myc-prev-week-container">` + settings.nextHtml + `</div>`;
			var monthYearHtml = `
                <div id="myc-current-month-year-container">
                    ` + this.getMonthName(settings.startDate.getMonth()) + ' ' + settings.startDate.getFullYear() + `
                </div>
            `;

			var navHtml = `
                <div id="myc-nav-container">
                    ` + previousWeekHtml + `
                    ` + monthYearHtml + `
                    ` + nextWeekHtml + `
                    <div style="clear:both;"></div>
                </div>
            `;
			return navHtml;
		};

		// kuhanin at ipakita ang mga araw
		this.getDatesHeader = function() {
			var tmp = ``;
			for (let i = 0; i < 7; i++) {
				var d = settings.startDate.addDays(i);
				tmp += `
                    <div class="myc-date-header" id="myc-date-header-` + i + `">
                        <div class="myc-date-number">` + d.getDate() + `</div>
                        <div class="myc-date-display">` + settings.weekdays[d.getDay()] + `</div>
                    </div>
                `;
			}
			var ret = `<div id="myc-dates-container">` + tmp + `</div>`;
			return ret;
		}

		// kuhanin ang mga pwedeng oras sa bawat araw ng kasalukuyang linggo
		this.getAvailableTimes = function() {
			var tmp = ``;
			for (let i = 0; i < 7; i++) {
				var tmpAvailTimes = ``;
				$.each(settings.availability[i], function() {
					tmpAvailTimes += `
                        <a href="javascript:;" class="myc-available-time" data-time="` + this + `" data-date="` + formatDate(settings.startDate.addDays(i)) + `">
                            ` + formatTime(this) + `
                        </a>
                    `;
				});
				tmp += `
                    <div class="myc-day-time-container" id="myc-day-time-container-` + i + `">
                        ` + tmpAvailTimes + `
                        <div style="clear:both;"></div>
                    </div>
                `;
			}
			return tmp
		}

		// i-set ang mga oras na pwedeng ilaan
		this.setAvailability = function(arr) {
			settings.availability = arr;
			render();
		}

		// clear
		this.clearAvailability = function() {
			settings.availability = [[], [], [], [], [], [], []];
		}

		// pag napindot ang nakaraang linggo
		this.on('click', '#myc-prev-week', function() {
			settings.startDate = settings.startDate.addDays(-7);
			instance.clearAvailability();
			render(instance);

			if ($.isFunction(onClickNavigator)) {
				onClickNavigator.call(this, ...arguments, instance);
			}
		});

		// pag napindot ang susunod na linggo
		this.on('click', '#myc-next-week', function() {
			settings.startDate = settings.startDate.addDays(7);
			instance.clearAvailability();
			render(instance);

			if ($.isFunction(onClickNavigator)) {
				onClickNavigator.call(this, ...arguments, instance);
			}
		});

		// pag namili ng oras
		this.on('click', '.myc-available-time', function() {
			var date = $(this).data('date');
			var time = $(this).data('time');
			var tmp = date + ' ' + time;
			if ($(this).hasClass('selected')) {
				$(this).removeClass('selected');
				var idx = settings.selectedDates.indexOf(tmp);
				if (idx !== -1) {
					settings.selectedDates.splice(idx, 1);
				}
			} else {
				if (settings.isMultiple) {
					$(this).addClass('selected');
					settings.selectedDates.push(tmp);
				} else {
					settings.selectedDates.pop();
					if (!settings.selectedDates.length) {
						$('.myc-available-time').removeClass('selected');
						$(this).addClass('selected');
						settings.selectedDates.push(tmp);
					}
				}
			}
			if ($.isFunction(onClick)) {
				onClick.call(this, ...arguments, settings.selectedDates);
			}
		});

		var render = function() {
			var ret = `
                <div id="myc-container">
                    <div id="myc-nav-container">` + instance.getNavControl() + `</div>
                    <div id="myc-week-container">
                        <div id="myc-dates-container">` + instance.getDatesHeader() + `</div>
                        <div id="myc-available-time-container">` + instance.getAvailableTimes() + `</div>
                    </div>
                </div>
            `;
			instance.html(ret);

			// Iterate through each selected date/time and highlight the corresponding time slot
			settings.selectedDates.forEach(function(selectedDate) {
				var parts = selectedDate.split(' ');
				var date = parts[0];
				var time = parts[1];
				var element = instance.find(`.myc-available-time[data-date="${date}"][data-time="${time}"]`);
				element.addClass('selected'); // Add the 'selected' class to highlight the time slot
			});
		};

		render();

		// Method to select a specific date/time
		this.selectDateTime = function(date) {
			if (!(date instanceof Date)) {
				throw new Error('Input must be a Date object');
			}

			// Format the date to YYYY-MM-DD
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
			const day = String(date.getDate()).padStart(2, '0');
			const formattedDate = `${year}-${month}-${day}`;

			// Format the time to HH:mm
			const hours = date.getHours();
			const minutes = String(date.getMinutes()).padStart(2, '0');
			const time = `${hours}:${minutes}`;

			// Select the time slot element based on the formatted date and time
			const element = $(this).find(`.myc-available-time[data-date="${formattedDate}"][data-time="${time}"]`);

			if (!element.length) {
				settings.selectedDates.push(`${formattedDate} ${time}`);
			} else if (!element.hasClass('selected')) {
				element.trigger('click');
			}

		};

		// Clear all selectedDates
		this.clearSelectedDates = function() {
			settings.selectedDates = [];
			instance.find('.myc-available-time').removeClass('selected');
		};

		this.getSelectedDates = function() {
			return settings.selectedDates;
		};

		// Return the calendar instance
		return this;
	};
})($);