import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function UserDetails() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [services, setServices] = useState([]);
	const [reviews, setReviews] = useState([]);
	const [averageRating, setAverageRating] = useState();
	const [profile, setProfile] = useState();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var profile_id = queryParams.get('id');

	useEffect(() => {
		if (!profile) {
			return;
		}
		const apiUrl = `${baseUrl}/tasks/user/` + profile.userInfo.id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(services) {
				setServices(services);

				var allReviews = [];
				$.each(services, function(index, service) {
					allReviews.push(...service.reviews);
				});
				setReviews(allReviews);

				const avgRating = allReviews.length > 0
					? allReviews.reduce((sum, review) => sum + review.rating, 0) / allReviews.length
					: undefined;
				const averageRating = avgRating?.toFixed(1) ?? 'No Reviews';
				setAverageRating(averageRating);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [profile])

	useEffect(() => {
		const apiUrl = `${baseUrl}/profiles/` + profile_id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(profile) {
				setProfile(profile);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleContactSeller = (event) => {
		event.preventDefault();
		var user_id = localStorage.getItem('user_id');
		if (!user_id) {
			return;
		}

		const indexed_array = {};
		var user = { "id": user_id };
		indexed_array["fromUser"] = user;
		var to_user = { "id": profile.userInfo.id };
		indexed_array["toUser"] = to_user;
		indexed_array["message"] = "Intro: Hi, I have a question about your profile and services.";

		const apiUrl = `${baseUrl}/messages`;
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function() {
				navigate('/messages');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	function truncateString(str, maxLength = 100) {
		return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
	}

	function calculateAverageRating(reviews) {
		if (reviews.length > 0) {
			const sumOfRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
			const averageRating = sumOfRatings / reviews.length;
			return averageRating.toFixed(1); // Formatted average
		} else {
			return '';
		}
	}

	function formatDate(isoDateString) {
		const date = new Date(isoDateString);
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true
		};
		return date.toLocaleTimeString('en-US', options);
	}

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div class="row">
						<div class="col-lg-6 left mb-5 mb-lg-0">
							{profile && (
								<div>
									<div class="profile_info">
										<div class="seller-card">
											{/*
											<div>
												<div class="user-online-indicator is-online " data-user-id="1152855">
													<i class="fa fa-circle"></i>online
												</div>
											</div>
											*/}
											<div>
												<div><a href="#" class="ambassadors-badge">Service Provider</a></div><br /><br />
												<div class="user-profile-info">
													<div>
														<div class="user-profile-image" style={{ width: '50%', margin: '0 auto' }}>
															<img src={`${profile.images[0].imgUrl}`} class="img-fluid user-pict-img" alt="Askbootstrap" />
														</div>
													</div><br />
													<div class="user-profile-label">
														<div class="username-line">
															<a href="#" class="seller-link">{profile.userInfo.name}</a>
														</div>
														<div class="oneliner-wrapper">
															<small class="oneliner">Professional Service Provider</small>
															<div class="ratings-wrapper">
																<p class="rating-text"><strong>{averageRating}</strong> ({reviews.length} reviews)</p>
															</div>
														</div>
													</div>
												</div>
												<div class="buttons-wrapper contact-seller-wrapper">
													<Link class="fit-button" to="#" onClick={handleContactSeller}>Contact Service Provider</Link>
												</div>
												{/* 
												<div class="user-stats-desc">
												    <ul class="user-stats">
												        <li class="member-since">Id<strong>{profile.id}</strong></li><br />
												    </ul>
												</div>
												*/}

											</div>
										</div>
									</div>
									<div class="seller-profile">
										<div class="description">
											<h3>About</h3>
											<p>{profile.about}
											</p>
										</div>
										<div class="linked-accounts">
											<h3>Linked Accounts</h3>
											<ul>
												<li class="platform social-account">
													<i class="fa-solid fa-id-card"></i> <span class="text"> {profile.website || 'No Website'}</span>
												</li>
												<li class="platform social-account">
													<i class="fa-brands fa-linkedin" ></i> <span class="text"> {profile.linkedInProfile || 'No LinkedIn Profile'}</span>
												</li>
											</ul>
										</div>
									</div>

									<br />
									<div class="profile-card">
										<label id="reviews" class="profile-pict" for="profile_image">
											<h5 style={{ fontSize: '1.15rem' }} class="m-0"> {reviews.length} Reviews </h5>
										</label><br /><br />

										<div class="user-profile-image d-flex">
											<div class="review-list" style={{ width: '100%' }}>
												<ul>
													{reviews.slice(0, 8).map((review, index) => (
														<li>
															{index > 0 && <hr />}
															<div class="d-flex">
																<div class="right">
																	<h4>
																		{review.reviewerName}
																		<span class="gig-rating text-body-2">
																			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15" height="15">
																				<path fill="currentColor" d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
																				</path>
																			</svg>
																			{review.rating}.0
																		</span>
																	</h4>
																	<div class="review-description">
																		<p>
																			{review.comment}
																		</p>
																	</div>
																	<span class="publish py-3 d-inline-block w-100">{formatDate(review.dateTime)}</span>
																</div>
															</div>
														</li>
													))}
												</ul>
												{reviews.length === 0 && <p>No reviews for this service provider yet.</p>}
											</div>
										</div>
									</div>
								</div>
							)}
						</div>

						<div class="col-lg-6 right">
							<h5>Provider Services</h5><br />
							<div class="recommended">
								<div class="row">

									{services.map(service => (
										<div class="col-md-6">
											<Link to={`/serviceDetails?id=${service.id}`}>
												<img class="img-fluid" src={`${service.images[0].imgUrl}`} />
											</Link>
											<div class="inner-slider">
												<div class="inner-wrapper">
													<div class="d-flex align-items-center">
														<span class="seller-image">
															<img class="img-fluid" src={`${service.ownerInfo.images[0].imgUrl}`} />
														</span>
														<span class="seller-name">
															<Link to={`/serviceDetails?id=${service.id}`}>{truncateString(service.title, 70)}</Link>
															{/*
																<span class="level hint--top level-one-seller">
																	Level 1 Seller
																</span>
																*/}
														</span>
													</div>
													{/*
														<h3>
															<span>{truncateString(service.detail, 100)}</span>
														</h3>
														*/}
													<div class="content-info">
														<div class="rating-wrapper">
															<span class="gig-rating text-body-2">
																<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15" height="15">
																	<path fill="currentColor" d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
																	</path>
																</svg>
																{calculateAverageRating(service.reviews)}
																<span>({service.reviews.length})</span>
															</span>
														</div>
													</div>
													<div class="footer">
														<i class="" aria-hidden="true"></i>
														<div class="price">
															<a href="#">
																Cost Per Hour <span> ${service.cost}</span>
															</a>
														</div>
													</div>
												</div>
											</div>
											<br />
										</div>
									))}

								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default UserDetails;
