import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Favorites() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [favorites, setFavorites] = useState([]);

	useEffect(() => {
		var user_id = localStorage.getItem('user_id');
		const apiUrl = `${baseUrl}/favorites/user/` + user_id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(favorites) {
				setFavorites(favorites);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleDeleteFavorite = (taskId) => {
		var userId = localStorage.getItem('user_id');
		const apiUrl2 = `${baseUrl}/favorites/${userId}/${taskId}`;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 class="mb-0 p-0">Favorite Services</h2>

								<Link class="btn btn-sm btn-success ml-auto" to="/search?keywords=">Search Services</Link>							</div>
							<ul class="nav dlevitin-tabs nav-tabs flex-column flex-sm-row ">
								<li class="nav-item">
									<a href="#active" data-toggle="tab" class="nav-link make-black active">
										ALL SERVICES
									</a>
								</li>
							</ul>
							<div class="tab-content dlevitin-table bg-white rounded shadow-sm px-3 pt-1">
								<div class="tab-pane active" id="active">
									<div class="table-responsive box-table mt-3" style={{ overflowY: 'auto', maxHeight: '600px' }}>
										<table class="table table-bordered">
											<thead>
												<tr>
													<th style={{ width: '60%' }}>SERVICE TITLE</th>
													<th style={{ width: '40%' }}>FAVORITE ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{favorites.length > 0 ? (
													favorites.map(favorite => (
														<tr>
															<td style={{ minWidth: '350px' }}>
																<Link to={`/serviceDetails?id=${favorite.task.id}`} class="make-black">
																	<img class="order-proposal-image" src={`${favorite.task.images[0].imgUrl}`} />
																	<p class="order-proposal-title">{favorite.task.title}</p>
																</Link>
															</td>
															<td style={{ minWidth: '250px' }}>
																<div>
																	<Link to={`/serviceDetails?id=${favorite.task.id}`}>
																		<button style={{ margin: '5px' }} class="btn btn-sm btn-success">View Service</button>
																	</Link>
																	<button onClick={() => handleDeleteFavorite(favorite.task.id)} class="btn btn-sm btn-success">Delete</button>
																</div>
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="2">No Favorite Services</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</main>

	);
}

export default Favorites;
