import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { useNavigate, Link } from 'react-router-dom';

function Account() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variables

	var user_id = localStorage.getItem('user_id');
	var profile_id = localStorage.getItem('profile_id');
	var hasBusiness = localStorage.getItem('has_business') === 'true';

	return (
		<main>
			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<div class="row">
						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Account</h6>
									<p class="mb-0 mt-0 small">Quick links for your account</p>
								</div>
								<div class="box-body p-3">
									{user_id && (
										<div>
											<Link className="dropdown-item" to="/editUser">
												<i class="fa-regular fa-user-pen"></i> Edit User Profile
											</Link>
											<Link className="dropdown-item" to={`/emailSettings?id=${user_id}`}>
												<i class="fa-regular fa-envelope-open-text"></i> Update Email Settings
											</Link>
											<Link className="dropdown-item" to="/favorites">
												<i class="fa-duotone fa-heart"></i> Favorites
											</Link>
											<Link className="dropdown-item" to="/orders">
												<i class="fa-sharp fa-light fa-list-check"></i> Orders
											</Link>
											<Link className="dropdown-item" to="/alerts">
												<i class="fa-regular fa-bell"></i> Notifications
											</Link>
											<Link class="dropdown-item" to="/messages">
												<i class="fa-regular fa-messages"></i> Messages
											</Link>
										</div>
									)}

								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Site Links </h6>
									<p class="mb-0 mt-0 small">Other Site Links</p>
								</div>
								<div class="box-body p-3">
									<Link className="dropdown-item" to="/categories">
										<i class="fa-regular fa-layer-group"></i> Categories
									</Link>
									<Link className="dropdown-item" to="/contact">
										<i class="fa-regular fa-envelope"></i> Contact
									</Link>
									<Link to="/referral" class="dropdown-item" >
										<i class="fa-regular fa-badge-dollar"></i> Become Referral Partner
									</Link>
									<Link to="/about" class="dropdown-item" >
										<i class="fa-regular fa-chalkboard-user"></i> Learn About Task Beehive
									</Link>
									<Link to="/faq" class="dropdown-item" >
										<i class="fa-regular fa-square-question"></i> FAQ
									</Link>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Service Provider</h6>
									<p class="mb-0 mt-0 small">Quick links for service provider</p>
								</div>
								<div class="box-body p-3">
									{!profile_id && (
										<Link className="dropdown-item" to="/becomeSeller">
											<i class="fa-regular fa-fw fa-dollar"></i> Join Providers
										</Link>
									)}
									{profile_id && (
										<div>
											<Link className="dropdown-item" to="/editSeller">
												<i class="fa-regular fa-user-pen"></i> Edit Service Provider Profile
											</Link>
											<Link className="dropdown-item" to="/createService">
												<i class="fa-regular fa-house-person-leave"></i> Create Service
											</Link>
											<Link class="dropdown-item" to="/services">
												<i class="fa-regular fa-list-ul"></i> View Your Services
											</Link>
											<Link class="dropdown-item" to="/ordersServiceProvider">
												<i class="fa-regular fa-money-check-dollar-pen"></i> View Customer Orders
											</Link>
											<Link class="dropdown-item" to="/dailySchedule">
												<i class="fa-regular fa-list-check"></i> Daily Job Schedule
											</Link>
											<Link class="dropdown-item" to="/earnings">
												<i class="fa-regular fa-sack-dollar"></i> Earnings
											</Link>
										</div>
									)}
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Business</h6>
									<p class="mb-0 mt-0 small">Quick links for business</p>
								</div>
								<div class="box-body p-3">
									{hasBusiness && (
										<div>
											<Link class="dropdown-item" to="/businesses">
												<i class="fa-regular fa-handshake"></i> View Your Businesses
											</Link>
											<Link class="dropdown-item" to="/businesses">
												<i class="fa-regular fa-sack-dollar"></i> View Earnings
											</Link>
										</div>
									)}
									<div>
										<Link class="dropdown-item" to="/createBusiness">
											<i class="fa-regular fa-handshake-simple"></i> Create Business
										</Link>
									</div>
								</div>
							</div>
						</div>

					</div>

				</div>
			</div>
		</main>
	);
}

export default Account;
