import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

function AdminUserManagement() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [user, setUser] = useState();

	const handleGetUser = (e) => {
		if (e) {
			e.preventDefault();
		}
		const input = $("#userIdOrEmail").val();
		let apiUrl;
		if (input.includes('@')) {
			apiUrl = `${baseUrl}/users/byEmail?email=` + input + `&includeDisabled=true`;
		} else {
			apiUrl = `${baseUrl}/users/` + input;
		}

		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(user) {
				setUser(user);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const handleUserEnabledUpdate = (userId, enabled) => {
		const apiUrl2 = `${baseUrl}/users/updateEnabled/${userId}?enabled=` + enabled;
		$.ajax({
			url: apiUrl2,
			type: 'PUT',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(msg) {
				handleGetUser();
				handleCloseModal();
				handleCloseModal2();
				$("#alertText").html("User has been udpated.");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);

	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const handleOpenModal2 = () => setIsModalOpen2(true);
	const handleCloseModal2 = () => setIsModalOpen2(false);

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<form id="target" onSubmit={(e) => { handleGetUser(e); }}>

						<div class="row">
							<div class="col-md-12">
								<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
									<h2 class="mb-0 p-0">Manage Users</h2>
								</div>

								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Lookup User</h6>
										<p class="mb-0 mt-0 small">Look User by email or id.</p>
									</div>
									<div class="box-body p-3">
										<div class="row">
											<div class="col-sm-10 mb-2">
												<div class="js-form-message">
													<label id="businessName" class="form-label">
														Lookup User By Email Or ID: </label>
													<div class="form-group">
														<input type="text" maxlength="250" class="form-control" id="userIdOrEmail" /><br />
														<button id="lookupServiceProvider" class="btn btn-success">Lookup User</button><br /><br />

														<div class="list-container">
															<ul class="list-group" id="serviceProviderIds">
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<ul class="nav dlevitin-tabs nav-tabs flex-column flex-sm-row ">
									<li class="nav-item">
										<a href="#active" data-toggle="tab" class="nav-link make-black active">
											MANAGE USERS
										</a>
									</li>
								</ul>
								<div class="tab-content dlevitin-table bg-white rounded shadow-sm px-3 pt-1">
									<div class="tab-pane active" id="active">
										<div class="table-responsive box-table mt-3">
											<table class="table table-bordered">
												<thead>
													<tr>
														<th>USER</th>
														<th>ACTIONS</th>
													</tr>
												</thead>
												<tbody>
													{user && (
														<tr>
															<td style={{ minWidth: '200px' }}>
																<Link to={`/serviceDetails?id=${user.id}`} class="make-black">
																	<p class="order-proposal-title">{user.id}</p>
																	<p class="order-proposal-title">{user.email}</p>
																	<p class="order-proposal-title">Disabled: {user.disabled.toString()}</p>
																</Link>
															</td>
															<td style={{ minWidth: '200px' }}>
																<div>
																	<button style={{ margin: '3px' }} onClick={() => handleOpenModal()} class="btn btn-sm btn-success">Enable User</button>
																	<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
																		<div style={{ textAlign: 'center' }}>
																			<br /><h5>Are you sure you want to enable user?</h5><br />
																			<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleUserEnabledUpdate(user.id, true)}>Yes, Continue</button>
																			<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal()}>No, Go Back</button>
																		</div>
																	</Modal>
																	<button style={{ margin: '3px' }} onClick={() => handleOpenModal2()} class="btn btn-sm btn-success">Disable User</button>
																	<Modal isOpen={isModalOpen2} class="cancelModal" onRequestClose={handleCloseModal2} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
																		<div style={{ textAlign: 'center' }}>
																			<br /><h5>Are you sure you want to disable user?</h5><br />
																			<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleUserEnabledUpdate(user.id, false)}>Yes, Continue</button>
																			<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal2()}>No, Go Back</button>
																		</div>
																	</Modal>
																</div>
															</td>
														</tr>
													)}
													{!user && (
														<tr>
															<td colSpan="2">No User Found</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
						<br />
					</form>
				</div>
			</div>
		</main>

	);
}

export default AdminUserManagement;
