import React, { useEffect, useState } from "react";
import {
	PaymentElement,
	useStripe,
	useElements
} from "@stripe/react-stripe-js";
import { useNavigate } from 'react-router-dom';
import $ from 'jquery';

export default function CheckoutForm({ invoiceId }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL;
	const baseReactUrl = process.env.REACT_APP_URL;
	// Accessing the REST_API_URL environment variable
	const stripe = useStripe();
	const elements = useElements();

	//const [email, setEmail] = useState('');
	const [message, setMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (!stripe) {
			return;
		}
		const clientSecret = new URLSearchParams(window.location.search).get(
			"payment_intent_client_secret"
		);
		if (!clientSecret) {
			return;
		}

		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			switch (paymentIntent.status) {
				case "succeeded":
					setMessage("Payment succeeded!");
					break;
				case "processing":
					setMessage("Your payment is processing.");
					break;
				case "requires_payment_method":
					setMessage("Your payment was not successful, please try again.");
					break;
				default:
					setMessage("Something went wrong.");
					break;
			}
		});
	}, [stripe]);

	const apiUrl = `${baseReactUrl}/invoice?invoiceId=${invoiceId}`;
	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!stripe || !elements) {
			return;
		}
		setIsLoading(true);
		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: apiUrl
			}
		});

		if (error.type === "card_error" || error.type === "validation_error") {
			setMessage(error.message);
		} else {
			setMessage("An unexpected error occurred.");
		}
		setIsLoading(false);
	};

	const paymentElementOptions = {
		layout: "tabs"
	}
	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			{/*
			<LinkAuthenticationElement
				id="link-authentication-element"
				onChange={(e) => setEmail(e.value.email)}
			/>
			*/}
			<PaymentElement id="payment-element" options={paymentElementOptions} />
			<br />
			<button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-success btn-block btn-s btn-gradient shadow-sm">
				<span id="button-text">
					{isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
				</span>
			</button>
			{/* Show any error or success messages */}
			{message && <div id="payment-message">{message}</div>}
		</form>
	);
}