import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function EmailSettings({ userId, setUserId, profileId, setProfileId }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var userId = queryParams.get('id');

	const [user, setUser] = useState();

	useEffect(() => {
		const apiUrl = `${baseUrl}/users/` + userId;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(user) {
				setUser(user);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	useEffect(() => {
		if (!user) {
			return;
		}
		$("#target").off('submit');
		$("#target").submit(function(event) {
			event.preventDefault();
			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});

			var userObj = { "userId": userId };
			indexed_array["user"] = userObj;

			if (!indexed_array["onReceivedOrder"]) {
				indexed_array["onReceivedOrder"] = "false";
			}
			if (!indexed_array["onPlacedOrder"]) {
				indexed_array["onPlacedOrder"] = "false";
			}

			const apiUrl = `${baseUrl}/emails/updateEmailSettings/${user.emailSettings.id}`;
			$.ajax({
				url: apiUrl,
				type: 'PUT',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				success: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		});
	}, [user]);

	const handleEmailSettingsChange = (event) => {
		setUser({
			...user,
			emailSettings: {
				...user.emailSettings,
				[event.target.name]: event.target.checked,
			},
		});
	};

	return (
		<main>
			<div class="">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					{user && (
						<div class="row justify-content-center align-items-center d-flex vh-80">
							<div class="col-lg-8">
								<div class="dlevitin-login py-4">
									<div class="col-md-30">
										<div class="shadow-sm rounded bg-white mb-3">
											<div class="box-body p-3" >
												<div class="text-center mb-4">
													<h5 class="font-weight-bold mt-3">Email Settings</h5>
													<p class="text-muted">Update your email settings, or unsubscribe here.</p>
												</div>
												<form id="target">
													<div class="form-group">
														<div class="custom-control custom-checkbox">
															<input type="checkbox" class="custom-control-input" id="onPlacedOrder" name="onPlacedOrder" checked={user.emailSettings.onPlacedOrder} value={user.emailSettings.onPlacedOrder} onChange={handleEmailSettingsChange} />
															<label class="custom-control-label" for="onPlacedOrder">
																When You Place An Order</label>
														</div>
													</div>
													<div class="form-group">
														<div class="custom-control custom-checkbox">
															<input type="checkbox" class="custom-control-input" id="onReceivedOrder" name="onReceivedOrder" checked={user.emailSettings.onReceivedOrder} value={user.emailSettings.onReceivedOrder} onChange={handleEmailSettingsChange} />
															<label class="custom-control-label" for="onReceivedOrder">
																When Your Service Is Booked</label>
														</div>
													</div>
													<button class="btn btn-success btn-block text-uppercase" type="submit"> Update Email Settings </button>
												</form>

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

				</div>
			</div>
		</main>
	);
}

export default EmailSettings;
