import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Order() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [serviceDetails, setServiceDetails] = useState();
	const [serviceAvailability, setServiceAvailability] = useState();
	const [totalCost, setTotalCost] = useState(0);
	const availabilityRef = useRef([]);
	const [serviceLocation, setServiceLocation] = useState('home');
	const [addressDetails, setAddressDetails] = useState({ address: '', city: '', state: '', zip: '' });


	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var serviceId = queryParams.get('serviceId');

	// Get service
	useEffect(() => {
		const apiUrl = `${baseUrl}/tasks/${serviceId}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setServiceDetails(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	// Get availability
	useEffect(() => {
		const apiUrl = `${baseUrl}/availability/task/${serviceId}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const sortedData = data.sort((a, b) => new Date(a.availableAt) - new Date(b.availableAt));
				setServiceAvailability(sortedData);
				if (!sortedData || (Array.isArray(sortedData) && sortedData.length === 0)) {
					$("#alertText").html('This service has no availability at this time. Please try back later.');
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	// Init availability calendar
	const formatTime = (dateTime) => {
		const hours = dateTime.getHours().toString().padStart(2, '0');
		const minutes = dateTime.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	};
	const getCurrentDate = () => {
		const currentDate = new Date();
		currentDate.setHours(0, 0, 0, 0);
		return currentDate;
	};
	const getEndDate = (startDate, days) => {
		const endDate = new Date(startDate.getTime());
		endDate.setDate(endDate.getDate() + days);
		return endDate;
	};
	const getAvailabilityForWeek = (startDateInit, serviceAvailability) => {
		const startDate = new Date(startDateInit.getTime());
		const endDate = getEndDate(startDate, 7);
		const transformedAvailability = [];

		while (startDate <= endDate) {
			const availableTimesForDay = serviceAvailability
				.filter((availability) => {
					const availableAt = new Date(availability.availableAt);
					return availableAt >= startDate && availableAt < getEndDate(startDate, 1);
				})
				.map((availability) => formatTime(new Date(availability.availableAt)));

			transformedAvailability.push(availableTimesForDay);
			startDate.setDate(startDate.getDate() + 1);
		}

		return transformedAvailability;
	};
	const getNextWeekStartDate = (currentStartDate) => {
		const nextWeekStartDate = new Date(currentStartDate.getTime());
		nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
		return nextWeekStartDate;
	};
	const getPreviousWeekStartDate = (currentStartDate) => {
		const previousWeekStartDate = new Date(currentStartDate);
		previousWeekStartDate.setDate(previousWeekStartDate.getDate() - 7);
		return previousWeekStartDate;
	};
	const initSchedulerRef = useRef(false);
	useEffect(() => {
		if (initSchedulerRef.current || !serviceAvailability || !serviceDetails) {
			return;
		}
		initSchedulerRef.current = true;
		const availabilityCalendar = $('#picker');
		let currentDate = getCurrentDate();

		availabilityCalendar.markyourcalendar({
			availability: getAvailabilityForWeek(currentDate, serviceAvailability),
			isMultiple: true,
			onClick: function(ev, data) {
				availabilityRef.current = data; // Update the ref with the new data
				setTotalCost(serviceDetails.cost * availabilityRef.current.length);
				console.log(availabilityRef.current);
			},
			onClickNavigator: function(ev, instance) {
				const isNextWeek = ev.currentTarget.id === 'myc-next-week';

				if (isNextWeek) {
					currentDate = getNextWeekStartDate(currentDate);
				} else {
					currentDate = getPreviousWeekStartDate(currentDate);
				}

				const weekAvailability = getAvailabilityForWeek(currentDate, serviceAvailability);
				instance.setAvailability(weekAvailability);
			}
		});
	}, [serviceAvailability, serviceDetails]);

	// Place Order
	const handleOrder = (event) => {
		event.preventDefault();
		if (captchaInput != captcha) {
			setCaptcha(generateCaptcha()); // regenerate captcha
			setCaptchaInput(''); // clear input
			$("#alertText").html("The captcha you typed didn't match the one provided. Please try again.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			return;
		}

		const indexed_array = {};
		var user_id = localStorage.getItem('user_id');
		var user = { "id": user_id };
		indexed_array["user"] = user;
		indexed_array["isPaid"] = false;
		indexed_array["totalCost"] = totalCost;
		var availIndex = [];
		const availability = availabilityRef.current;
		const minHours = serviceDetails.minimumHours;
		if (availability.length < minHours) {
			$("#alertText").html("For this service the minimum number of hours you can book per order is: " + minHours);
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			return;
		}

		for (var i = 0; i < availability.length; i++) {
			var avail = {};
			var task = { "id": serviceDetails.id };
			avail["task"] = task;
			var date = new Date(availability[i]);
			avail["availableAt"] = date.toISOString();
			avail["isReserved"] = true;
			availIndex.push(avail);
		}
		indexed_array["availability"] = availIndex;

		if (serviceLocation === 'custom') {
			indexed_array["address"] = addressDetails.address;
			indexed_array["city"] = addressDetails.city;
			indexed_array["state"] = addressDetails.state;
			indexed_array["zip"] = addressDetails.zip;
		} else if (serviceLocation === 'remote') {
			indexed_array["remote"] = true;
		}

		const apiUrl = `${baseUrl}/orders`;
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function() {
				navigate('/orders');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};


	// Captcha
	const [captcha, setCaptcha] = useState('');
	const [captchaInput, setCaptchaInput] = useState('');
	function generateCaptcha() {
		const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
		let result = '';
		for (let i = 0; i < 4; i++) {
			result += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return result;
	}
	useEffect(() => {
		setCaptcha(generateCaptcha());
	}, []);
	const handleCaptchaInputChange = (event) => {
		setCaptchaInput(event.target.value);
	};

	const handleLocationChange = (event) => {
		setServiceLocation(event.target.value);
	};

	const handleAddressChange = (event) => {
		const { name, value } = event.target;
		setAddressDetails(prevDetails => ({
			...prevDetails,
			[name]: value
		}));
	};

	return (
		<main>
			<br /><br />
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>

				<form id="target" onSubmit={handleOrder}>
					<div class="row">
						<aside class="col-md-4">
							<div
								class="box mb-3 shadow-sm rounded bg-white profile-box text-center">
								<div class="py-4 px-3 border-bottom">
									<h6 class="m-0">Book This Service</h6>
								</div>
							</div>
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-body">
									<div class="p-3">
										{serviceDetails && (
											<div class="col-lg-12 left">
												<div class="box-title p-3">
													<h6 class="m-0">Service Details: </h6>
												</div>
												<div class="py-4 px-3">
													<p> <b>Service</b><br /> {serviceDetails.title}</p>
													<p> <b>Service Provider</b><br /> {serviceDetails.ownerInfo.userInfo.name} </p>
													<p> <b>Cost Per Hour</b><br /> ${serviceDetails.cost} </p>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-body">
									<div class="p-3">
										<div class="col-lg-12 left">
											<br />
											<h6>Total Cost Of Service:</h6> ${totalCost}
											<br />
										</div>
									</div>
								</div>
							</div>
						</aside>

						<main class="col-md-8">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="col-lg-12 left">
									<div class="box-title p-3">
										<h6 class="m-0">Select From Available Times: </h6>
										<p class="mb-0 mt-0 small">
											Select multiple slots if the job needs more time.</p>
									</div>
									<div id="picker"></div><br />
								</div>
							</div>

							<div className="shadow-sm rounded bg-white mb-3">
								<div className="box-title border-bottom p-3">
									<h6 className="m-0">Service Job Location</h6>
									<p className="mb-0 mt-0 small">The address where the service is needed.</p>
								</div>
								<div className="box-body">
									<div className="p-3"><div className="js-form-message">
										<label className="form-label">Service Job Location:</label>
										<div className="btn-group btn-group-toggle col-md-12 p-0">
											<label className={`btn btn-outline-success btn-sm ${serviceLocation === 'home' ? 'active' : ''}`}>
												<input type="radio" name="serviceLocation" value="home" checked={serviceLocation === 'home'} onChange={handleLocationChange} /> <b>At My Home</b>
											</label>
											<label className={`btn btn-outline-success btn-sm ${serviceLocation === 'custom' ? 'active' : ''}`}>
												<input type="radio" name="serviceLocation" value="custom" checked={serviceLocation === 'custom'} onChange={handleLocationChange} /> <b>Custom Location</b>
											</label>
											<label className={`btn btn-outline-success btn-sm ${serviceLocation === 'remote' ? 'active' : ''}`}>
												<input type="radio" name="serviceLocation" value="remote" checked={serviceLocation === 'remote'} onChange={handleLocationChange} /> <b>Remote Work</b>
											</label>
										</div>

										{serviceLocation === 'custom' && (
											<div>
												<br /><br />
												<div className="form-group">
													<label className="mb-1">Address</label>
													<div className="input-group">
														<input required type="text" maxLength="250" name="address" className="form-control" onChange={handleAddressChange} />
													</div>
												</div>
												<div className="form-row">
													<div className="col">
														<div className="form-group">
															<label className="mb-1">City</label>
															<div className="position-relative">
																<input required type="text" maxLength="250" name="city" className="form-control" onChange={handleAddressChange} />
															</div>
														</div>
													</div>
													<div className="col">
														<div className="form-group">
															<label className="mb-1">State</label>
															<div className="position-relative">
																<input required type="text" maxLength="250" name="state" className="form-control" onChange={handleAddressChange} />
															</div>
														</div>
													</div>
													<div className="col">
														<div className="form-group">
															<label className="mb-1">Zip Code</label>
															<div className="position-relative">
																<input required type="text" maxLength="250" name="zip" className="form-control" onChange={handleAddressChange} />
															</div>
														</div>
													</div>
												</div>
											</div>
										)}
									</div>


									</div>
								</div>
							</div>

							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Captcha Check
									</h6>
									<p class="mb-0 mt-0 small">Type the characters you see in the image below to confirm that you're not a robot.</p>
								</div>
								<div class="box-body">
									<div class="p-3">
										Captcha:
										<svg width="200" height="50" style={{ marginLeft: '20px' }} xmlns="http://www.w3.org/2000/svg">
											<rect x="1" y="1" width="198" height="48" fill="white" stroke="black" strokeWidth="1" />
											{captcha.split('').map((char, index) => (
												<text
													key={index}
													x={20 + 30 * index}
													y="30"
													fontSize="20"
													fontFamily="monospace"
													fill="black"
													transform={`rotate(${Math.random() * 10 - 5} ${20 + 30 * index}, 30)`}
												>
													{char}
												</text>
											))}
										</svg><br /><br />
										<div class="js-form-message">
											<label id="titleOfService" class="form-label">
												Type The Captcha Above:
											</label>
											<div class="form-group">
												<input required class="form-control" type="text" maxlength="6" value={captchaInput} onChange={handleCaptchaInputChange} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</main>
					</div>

					<div class="mb-3 text-right">
						<button type="submit" className="btn btn-success" disabled={serviceAvailability && serviceAvailability.length === 0}>Book Service Now</button>
					</div>
				</form>
			</div >
		</main >
	);
}

export default Order;
