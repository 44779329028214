import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Privacy() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	return (
		<main>
			<br /> <br />
			<div class="container">
				<div class="box mb-3 shadow-sm rounded bg-white profile-box">
					<div class="box-body p-5">
						<br />
						<div style={{ textAlign: 'center' }}><h2>Task Beehive, LLC Privacy Policy <small>(updated April 13, 2024)</small></h2></div>
						<br />
						<h4>1. Introduction </h4>
						<p>This Global Privacy Policy <b>(“Privacy Policy”)</b> describes how Task Beehive, LLC. will gather, use, and maintain your Personal Information on the Task Beehive LLC Platform. It will also explain your legal rights with respect to that information.</p>
						<p>By using the Task Beehive LLC Platform, you confirm that you have read and understand this Privacy Policy, and our Global Terms of Service (together referred to herein as the “Agreement”). The Agreement governs the use of the Task Beehive LLC Platform. Task Beehive LLC will collect, use, and maintain information consistent with the Agreement.</p>
						<p>If you are a California resident please see the “Additional Disclosures for California Residents”. If you have any questions or wish to exercise your rights and choices, please contact us via <Link to="/contactme">Contact Me</Link> page.</p>
						<h4>2. Data we collect, use and disclose:</h4>
						<p>Below is a list of all the types of data we plan to collect in the next 12 months, where we will get it, why we will collect it and the categories of third parties to whom we disclosed it. We do not sell your data to third parties. Please note that disclosure to "Payment processors" applies when you pay for a Task Beehive LLC service.</p>
						<table cellspacing="0" cellpadding="3" border="1" class="pp-table">
							<tbody>
								<tr>
									<td valign="top">
										<b>Data type</b>
									</td>
									<td valign="top">
										<b>Where we got it</b>
									</td>
									<td valign="top">
										<b>Why collected</b>
									</td>
									<td valign="top">
										<b>Disclosed to</b>
									</td>
								</tr>
								<tr>
									<td valign="top">
										First and last name
									</td>
									<td valign="top">
										User entry
									</td>
									<td valign="top">
										Facilitating transactions and personalizing your use of Task Beehive LLC
									</td>
									<td valign="top">
										Payment processors
									</td>
								</tr>
								<tr>
									<td valign="top">
										Email address
									</td>
									<td valign="top">
										User entry
									</td>
									<td valign="top">
										Account creation, user-to-user and Task Beehive LLC-to-user communications and combatting fraud/abuse
									</td>
									<td valign="top">
										Amazon Webservices Simple Email Server to forward emails
									</td>
								</tr>
								<tr>
									<td valign="top">
										Phone number
									</td>
									<td valign="top">
										User entry
									</td>
									<td valign="top">
										User-to-user communications, combatting fraud/abuse, personalizing your use of Task Beehive LLC
									</td>
									<td valign="top">
										Payment processors and phone verification service providers
									</td>
								</tr>
								<tr>
									<td valign="top">
										Mailing or street address
									</td>
									<td valign="top">
										User entry
									</td>
									<td valign="top">
										Account and post creation, Task Beehive LLC communicating with corporate users, facilitating transactions and personalizing your use of Task Beehive LLC
									</td>
									<td valign="top">
										Payment processors
									</td>
								</tr>
								<tr>
									<td valign="top">
										Credit card data
									</td>
									<td valign="top">
										User entry
									</td>
									<td valign="top">
										Facilitating your transactions
									</td>
									<td valign="top">
										Payment processors
									</td>
								</tr>
								<tr>
									<td valign="top">
										Geographic location (latitude and longitude)
									</td>
									<td valign="top">
										User entry, IP/geolocation providers
									</td>
									<td valign="top">
										Personalizing your use of Task Beehive LLC and combatting fraud/abuse
									</td>
									<td valign="top">
										No one
									</td>
								</tr>
								<tr>
									<td valign="top">
										Photos and other data you voluntarily provide, post on or send via Task Beehive LLC
									</td>
									<td valign="top">
										User entry
									</td>
									<td valign="top">
										Facilitating and personalizing your use of Task Beehive LLC
									</td>
									<td valign="top">
										No one
									</td>
								</tr>
								<tr>
									<td valign="top">
										Saved searches, account preferences, favorite/hidden postings
									</td>
									<td valign="top">
										User entry
									</td>
									<td valign="top">
										Facilitating and personalizing your use of Task Beehive LLC
									</td>
									<td valign="top">
										No one
									</td>
								</tr>
								<tr>
									<td valign="top">
										HTTP browser cookie
									</td>
									<td valign="top">
										User's browser, Task Beehive LLC web server
									</td>
									<td valign="top">
										Facilitating and personalizing your use of Task Beehive LLC and combatting fraud/abuse
									</td>
									<td valign="top">
										No one
									</td>
								</tr>
								<tr>
									<td valign="top">
										Information about your device and browser such as device ID, browser version, operating system, plugins
									</td>
									<td valign="top">
										User's browser, mobile app
									</td>
									<td valign="top">
										Facilitating and personalizing your use of Task Beehive LLC and combatting fraud/abuse
									</td>
									<td valign="top">
										No one
									</td>
								</tr>
								<tr>
									<td valign="top">
										IP address
									</td>
									<td valign="top">
										User's browser, mobile app, IP/geolocation providers
									</td>
									<td valign="top">
										Combatting fraud/abuse
									</td>
									<td valign="top">
										Service providers that help us combat fraud/abuse
									</td>
								</tr>
								<tr>
									<td valign="top">
										Web page views, access times, HTTP headers
									</td>
									<td valign="top">
										User's browser, mobile app
									</td>
									<td valign="top">
										Combatting fraud/abuse
									</td>
									<td valign="top">
										No one
									</td>
								</tr>
							</tbody>
						</table>
						<br />
						<h4> 3. Security of Collected Information</h4>
						<p>Your Task Beehive account is password-protected so that only you and authorized Task Beehive staff have access to your account information. In order to maintain this protection, do not give your password to anyone. Also, if you share a computer, you should sign out of your Task Beehive account and close the browser window before someone else logs on. This will help protect your information entered on public terminals from disclosure to third parties.</p>
						<p>Task Beehive implements and maintains reasonable administrative, physical, and technical security safeguards to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration and destruction. Task Beehive has staff dedicated to maintaining this Privacy Policy and other privacy initiatives, periodically reviewing security, and making sure that every Task Beehive employee is aware of our security practices. Nevertheless, transmission via the internet is not completely secure and we cannot guarantee the security of information about you.</p>
						<br />
						<h4> 4. Information Rights </h4>
						<p><u>Right to Access.</u> You have the right to request access to and obtain a copy of any Personal Information that we may hold about you. </p>
						<p><u>Right to Correct.</u> You have the right to request that we correct inaccuracies in your Personal Information.</p>
						<p><u>Right to Delete.</u> You have the right to request that we delete Personal Information that we have collected from or obtained about you. </p>
						<p>Submit the request via <Link to="/contactme">Contact Me</Link> page</p>
						<br />
						<h4> 5. California Users:</h4>
						<p>To learn more about the California Consumer Privacy Act and how it applies to you, please visit the <a href="https://oag.ca.gov/privacy/ccpa">California Attorney-General's website</a>.</p>
						<p><b>Right to know:</b> You have the right to request that we disclose the data we collect, use and disclose, and other information relating to data we collect about you.</p>
						<p><b>Right to delete:</b> You have the right to request the deletion of data that we have collected from you, subject to certain exceptions. </p>
						<p><b>Right to non-discrimination:</b> You have the right not to receive discriminatory treatment for exercising the rights listed above.</p>
						<p>You may submit a request to know or delete via <Link to="/contactme">Contact Me</Link> page. </p>
						<p>Only you, or someone you authorize to act on your behalf, may make a request to know or delete your data. An authorized agent may make a request on your behalf by providing written permission signed by you. </p>
						<p>We will need to confirm your identity before processing your request by asking you to log into your existing account (if you are a registered user) or by asking you for additional information, such as a government issued ID, to confirm your identity against information we have already collected. </p>
						<br />
						<h4> 6. Changes to this Policy: </h4>
						<p>If we change this Policy, we will post those changes on this page and will modify the “Last Updated” date at the top of this Policy. If we materially change this Policy in a way that affects how we use or disclose your Personal Information, we will notify you by prominently posting a notice of such changes.</p>
						<br />
						<h4> 7. Contact</h4>
						<p>If you have any questions or concerns about TaskRabbit, LLC privacy policy and practices please contact us via <Link to="/contactme">Contact Me</Link> page</p>
					</div>
				</div>
			</div>
		</main >
	);
}

export default Privacy;
