import React, { useState, useEffect, useRef } from "react";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import $ from 'jquery';

import '../static/css/payment.css';

import CheckoutForm from "./CheckoutForm";

export default function Invoice() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable
	const [clientSecret, setClientSecret] = useState("");
	const [stripePromise, setStripePromise] = useState(null);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var invoiceId = queryParams.get('invoiceId');

	const [invoice, setInvoice] = useState();

	// Get service
	useEffect(() => {
		const apiUrl = `${baseUrl}/invoices/${invoiceId}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setInvoice(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	useEffect(() => {
		if (!invoice) {
			return;
		}

		// const stripePromise = loadStripe("pk_test_51Mz376GQeuHpTqFqvIhYt1KNlJpeB0g0V2BjZK4VHGfTqEFrCVTtzWM0ARBxdMQrMxVzuJmdKLO01XMiZCDxhRTQ00gKcMPnBh");
		const stripePromise = loadStripe("pk_live_51Mz376GQeuHpTqFq3EOXxJ3sOv8LwQiXqzGUx3aMlkjYhyOrAojTZXOVQgYQljzgTL4otX0WFAbdVESdpAxZxVPZ00eClfnKB9");
		setStripePromise(stripePromise);

		// Create PaymentIntent as soon as the page loads
		const apiUrl = `${baseUrl}/payment/createPayment`;
		const token = localStorage.getItem('token');
		fetch(apiUrl, {
			method: "POST",
			headers: {
				"Authorization": "Bearer " + token,
				"Content-Type": "application/json"
			},
			body: JSON.stringify({ amount: invoice.amount, invoiceId: invoice.id }),
		})
			.then((res) => res.json())
			.then((data) => setClientSecret(data.clientSecret));
	}, [invoice]);

	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	};

	const formatDate = (dateString) => {
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		return new Date(dateString).toLocaleDateString(undefined, options);
	}

	// Update invoice
	var invoiceIdParam = queryParams.get('invoiceId');
	var clientSecretParam = queryParams.get('payment_intent_client_secret');
	var paymentIntentParam = queryParams.get('payment_intent');
	const updateInvoiceRef = useRef(false);
	useEffect(() => {
		if (!updateInvoiceRef.current && invoiceIdParam && clientSecretParam && paymentIntentParam) {
			updateInvoiceRef.current = true;
			const indexed_array = {};
			indexed_array["id"] = invoiceId;
			indexed_array["hasPaid"] = true;
			const apiUrl = `${baseUrl}/invoices/${invoiceIdParam}?paymentIntent=${paymentIntentParam}&paymentIntentClientSecret=${clientSecretParam}`;
			$.ajax({
				url: apiUrl,
				type: 'PUT',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(invoice) {
					navigate(window.location.pathname + "?invoiceId=" + invoiceId, { replace: true });
					if (invoice.hasPaid) {
						navigate("/services?hasPaid=true");
					}
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		};
	}, [])

	return (
		<main>
			<section class="py-5 homepage-search-block position-relative">
				<div class="container">
					{invoice && (
						<div class="row py-lg-2">

							<div class="col-lg-6">
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3" id="benefits-for-service-providers">
										<h6 class="m-0"> Invoice Details
										</h6>
										<p class="mb-0 mt-0 small">Invoice details and how the fee structure works.
										</p>
									</div>
									<div class="box-body">
										<div class="p-3">
											<div>
												<div><b>Invoice Type</b>:<br /><br />
													{invoice.invoiceType === "Registration" ? (
														<span><b>Service Provider Registration - Advertising Fee</b><br /> For Service Providers there is a one-time $20 registration advertising fee. This fee is used to promote your services, and to support the site.</span>
													) : (
														<span><b>Monthly Service Provider Fee</b><br /> This fee is 5% of your earnings for the past month.  will be used to find new customers for your services and support the platform.</span>
													)}
												</div>

												<div><br /><b>Invoice Amount</b>:<br /> ${invoice.amount}</div>
												<div><br /><b>Invoice Id</b>:<br /> {invoice.id}</div>
												<div><br /><b>Date Of Invoice</b>:<br /> {formatDate(invoice.dateTime)} </div>
												{invoice.invoiceType === "Monthly" && (
													<div>
														<br /><b>Total Earned This Month</b>:<br /> ${invoice.totalEarnings}

														<br /><br /><b>How the monthly invoice works?</b>
														<br />1. Customers make direct payments to the service provider for services rendered.
														<br />2. TaskBeehive charges a 5% fee from the provider's earnings billed via a monthly invoice. In comparison, other sites may charge up to 35% along with additional fees.
														<br />3. To continue using TaskBeehive, please pay the invoice within 7 days. If payment is not received, your services will not be able to accept new orders until the invoice is settled.
													</div>
												)}
												{invoice.invoiceType === "Registration" && (
													<div>
														<br /><b>How the registration advertising fee works?</b>
														<br />1. A one-time fee to activate your services upon registration as a service provider.
														<br />2. This fee is used to promote your services, and support the site.
													</div>
												)}
											</div>
										</div>
									</div>
								</div>

							</div>

							<div class="col-lg-6">
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 className="m-0">
											Invoice Payment
										</h6>
										<p class="mb-0 mt-0 small">Please pay the invoice to {invoice.invoiceType === "Registration" ? (
											<span>activate</span>
										) : (
											<span>re-activate</span>
										)} your services.
										</p>
									</div>
									<div class="box-body">
										<div class="p-3">
											<b>*Secured And Powered By Stripe.com</b><br /><br />
											{clientSecret && (
												<Elements options={options} stripe={stripePromise}>
													<CheckoutForm invoiceId={invoiceId} />
												</Elements>
											)}
										</div>
									</div>
								</div>

							</div>

							<div class="col-lg-6">
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0"> Why We Charge A Fee?</h6>
										<p class="mb-0 mt-0 small">How we use the funds.
										</p>
									</div>
									<div class="box-body">
										<div class="p-3">
											<span>1. Promote your services to customers.</span><br />
											<span>2. Support the Task Beehive staff and platform.</span><br />
											<span>3. Work on new features for our customers and service providers.</span>
										</div>
									</div>
								</div>

								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3" id="benefits-for-service-providers">
										<h6 class="m-0"> Learn About Task Beehive
										</h6>
										<p class="mb-0 mt-0 small">Learn about the benefits of Task Behive.
										</p>
									</div>
									<div class="box-body">
										<div class="p-3">
											Join Task Beehive as a service provider. Manage your own schedule, communicate with customers, build a brand, and create a business page.<br /><br />
											<Link to="/about" target="_blank">
												<button class="btn btn-sm btn-success">Learn About Task Beehive</button>
											</Link>
											<br />
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-6">
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0"> Invoice Payment Benefits
										</h6>
									</div>
									<div class="box-body">
										<div class="p-0">
											<ul class="list-group modern">
												<li class="list-group-item">
													<i class="fa-light fa-check fa-xl fa-margin-right"></i>
													<span><b> Activate Your Services</b><div>
														{invoice.invoiceType === "Registration" ? (
															<span>Pay the one-time $20 service provider advertising fee to activate your services. It will be used to find new customers for your services and support the platform.</span>
														) : (
															<span>The monthly service provider fee is 5% of your earnings for the previous month. It will be used to find new customers for your services and support the platform.</span>
														)}
													</div></span>
												</li>
												<li class="list-group-item">
													<i class="fa-light fa-people-group fa-xl fa-margin-right"></i>
													<span><b> Find New Customers</b><div> Find new customers for your business and services.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-sack-dollar fa-xl fa-margin-right"></i>
													<span><b> Payment</b><div> Customers pay the service provider directly for the service cost. Accept cash, check, or credit card (using Square, Venmo, etc).</div></span>
												</li>
												<li class="list-group-item">
													<i class="fa-light fa-calendar-days fa-xl fa-margin-right"></i>
													<span><b> Schedule Management</b><div> Schedule your service availability, and have full control over your own schedule. Customers can then reserve a time.</div></span>
												</li>
												<li class="list-group-item">
													<i class="fa-light fa-messages fa-xl fa-margin-right"></i>
													<span><b> Messaging And Communication</b><div> Customers can contact you to request a custom quote or discuss the project.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-buildings fa-xl fa-margin-right"></i>
													<span><b> Business Page</b><div> Create a business page and link your service to it. The business information will be displayed on the service page as well.</div></span>
												</li>
												<li class="list-group-item">
													<i class="fa-light fa-dollar fa-xl fa-margin-right"></i>
													<span><b> Earnings</b><div> View service provider or business earnings for any range of time.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-gear fa-xl fa-margin-right"></i>
													<span><b> Customer Management</b><div> View and manage customer orders, daily work schedule, notifications, and other tools.</div></span>
												</li>
											</ul>
										</div>
									</div>
								</div>

							</div>
						</div>
					)}
				</div>
			</section >
		</main >
	);
}