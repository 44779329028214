import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import '../static/css/mark-your-calendar.css'
import '../static/js/mark-your-calendar.js';

function EditSchedule() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var taskId = queryParams.get('id');

	const [serviceAvailability, setServiceAvailability] = useState();
	const [availability, setAvailability] = useState();

	const [calendarRef, setCalendarRef] = useState();

	// Update shedule
	const handleUpdateSchedule = () => {
		var indexed_array = [];
		for (var i = 0; i < availability.length; i++) {
			const repeatWeekly = $("#repeatWeekly").is(":checked");
			var avail = {};
			var task = { "id": taskId };
			avail["task"] = task;
			var date = new Date(availability[i]);
			avail["availableAt"] = date.toISOString();
			avail["isReserved"] = false;

			var startOfToday = new Date();
			startOfToday.setHours(0, 0, 0, 0);
			startOfToday.setDate(startOfToday.getDate() + weekOffset * 7);
			var endOfWeek = new Date(startOfToday);
			endOfWeek.setDate(endOfWeek.getDate() + 6);
			endOfWeek.setHours(23, 59, 59, 999);
			if (date >= startOfToday && date <= endOfWeek) {
				avail["repeatWeekly"] = repeatWeekly;
			} else {
				avail["repeatWeekly"] = false;
			}
			if (repeatWeekly && date > endOfWeek) {
				continue;
			}
			indexed_array.push(avail);
		}
		if (!indexed_array) {
			return;
		}

		const apiUrl3 = `${baseUrl}/availability/task/` + taskId;
		$.ajax({
			url: apiUrl3,
			type: 'PUT',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(msg) {
				navigate('/services');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	// Get current availability
	useEffect(() => {
		$("#repeatWeekly").prop("checked", false);
		resetCurrentWeekRange();

		const apiUrl = `${baseUrl}/availability/task/` + taskId;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setServiceAvailability(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	const initSchedulerRef = useRef(false);
	useEffect(() => {
		if (!serviceAvailability || initSchedulerRef.current) {
			return;
		}
		initSchedulerRef.current = true;
		const calendarRef = $('#picker').markyourcalendar({
			availability: [
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
			],
			isMultiple: true,
			onClick: function(ev, data) {
				setAvailability(data);
				console.log(availability);
			},
			onClickNavigator: function(ev, instance) {
				var arr = [
					[
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
					]
				]
				instance.setAvailability(arr[0]);
				if (ev.target.id.includes("next")) {
					setWeekOffset(prevWeekOffset => {
						const newOffset = prevWeekOffset + 1;
						resetCurrentWeekRange(newOffset);
						return newOffset;
					});
				} else if (ev.target.id.includes("prev")) {
					setWeekOffset(prevWeekOffset => {
						const newOffset = prevWeekOffset - 1;
						resetCurrentWeekRange(newOffset);
						return newOffset;
					});
				}
			}
		});

		for (const dateTime of serviceAvailability) {
			calendarRef.selectDateTime(new Date(dateTime.availableAt));
		}
		setAvailability(calendarRef.getSelectedDates());

		setCalendarRef(calendarRef);
	}, [serviceAvailability]);

	const handleClearSchedule = () => {
		setAvailability([]);
		calendarRef.clearSelectedDates();
	};

	const [currentWeekRange, setCurrentWeekRange] = useState("");
	const [weekOffset, setWeekOffset] = useState(0);
	const resetCurrentWeekRange = (offset = weekOffset) => {
		const today = new Date();
		today.setDate(today.getDate() + offset * 7);
		const nextWeek = new Date(today);
		nextWeek.setDate(today.getDate() + 6);
		const formatDate = (date) => {
			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth() + 1).padStart(2, '0');
			return `${month}/${day}`;
		};
		setCurrentWeekRange(`${formatDate(today)} - ${formatDate(nextWeek)}`);
	};

	return (
		<main>
			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<form id="target">
						<div class="row">
							<main class="col-md-12">

								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0"> Edit Task Schedule Service Availability</h6>
										<p class="mb-0 mt-0 small">Select when you are available to provide this service. Can customize it later.</p>
									</div>

									<div class="box-body p-3">
										<div class="form-group mb-4">
											<label class="mb-1">If Checked, The Selected Current Week Will Repeat Weekly For 3 Months. Previous Custom Changes Will Be Overridden.</label>
											<div class="custom-control custom-checkbox">
												<input type="checkbox" class="custom-control-input" id="repeatWeekly" name="repeatWeekly" />
												<label class="custom-control-label" for="repeatWeekly">
													Repeat Current Week ({currentWeekRange}) Weekly
												</label><br />
											</div>
										</div>

										<div id="picker"></div>
									</div>
								</div>
								<div class="mb-3 text-right">
									<button type="button" class="btn btn-success" onClick={() => handleClearSchedule()} style={{ marginRight: '1em' }}>Clear Schedule</button>
									<button type="button" class="btn btn-success" onClick={() => handleUpdateSchedule()}>Update The Schedule</button>
								</div>
							</main>
						</div>
					</form>
				</div>
			</div>

		</main>
	);
}

export default EditSchedule;
