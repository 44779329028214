import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, Link } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

function EditSeller() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [profile, setProfile] = useState();
	var profile_id = localStorage.getItem('profile_id');

	// Get services effect
	useEffect(() => {
		var username = localStorage.getItem('user_name');
		$("#name").html(username)

		const apiUrl = `${baseUrl}/profiles/${profile_id}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setProfile(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	// Load in dates
	const options = [];
	const currentYear = new Date().getFullYear();
	for (let i = currentYear - 10; i >= 1940; i--) {
		options.push({ value: i, label: i });
	}

	const options2 = [];
	for (let i = 1; i <= 31; i++) {
		options2.push({ value: i, label: i });
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSubmitting(true);

		var unindexed_array = $('#target :input[name!="file"]').serializeArray();
		var indexed_array = {};
		$.map(unindexed_array, function(n, i) {
			indexed_array[n['name']] = n['value'];
		});
		var user_id = localStorage.getItem('user_id');
		var user = { "id": user_id };
		indexed_array["user"] = user;

		if (uploadedImages.length > 0) {
			indexed_array["images"] = uploadedImages.map((img) => ({
				fileName: img.fileName,
				contentType: img.contentType,
				imgType: 'profile',
				imgUrl: img.imgUrl,
			}));
		}

		const apiUrl2 = `${baseUrl}/profiles/${profile_id}`;
		$.ajax({
			url: apiUrl2,
			type: 'PUT',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(profile) {
				localStorage.setItem('profile_id', profile.id)
				navigate('/');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
				setIsSubmitting(false);
			}
		});

	};

	const handleChange = (event) => {
		setProfile({ ...profile, [event.target.name]: event.target.value });
	};

	/**
	 * Image Uploading
	 */
	const compressImage = async (file) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};

		try {
			const compressedBlob = await imageCompression(file, options);

			// Convert the compressed Blob back to a File object
			const compressedFile = new File(
				[compressedBlob],
				`${uuidv4()}.${file.type.split('/')[1]}`,
				{ type: file.type, lastModified: Date.now() }
			);

			return compressedFile;
		} catch (error) {
			console.error('Error compressing the image:', error);
			throw error;
		}
	};

	const getSignedUrl = async (fileName, contentType) => {
		const url = new URL(`${baseUrl}/image/generateSignedUrl`);
		url.searchParams.append('fileName', fileName);
		url.searchParams.append('contentType', contentType);
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
		const data = await response.text();
		return data;
	};

	const uploadToGCS = async (file, signedUrl) => {
		await fetch(signedUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': file.type,
			},
			body: file,
		});
	};

	const [uploadedImages, setUploadedImages] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleFileChange = async (event) => {
		setIsSubmitting(true);
		const files = Array.from(event.target.files);
		const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));

		const uploadedFiles = await Promise.all(
			compressedFiles.map(async (file) => {
				const contentType = file.type;
				const fileName = file.name;
				const signedUrl = await getSignedUrl(fileName, contentType);

				await uploadToGCS(file, signedUrl);

				return {
					fileName,
					contentType,
					imgUrl: signedUrl.split('?')[0],
				};
			})
		);
		setUploadedImages(uploadedFiles);
		setIsSubmitting(false);
	};

	return (
		<main>

			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					{profile && (
						<form id="target" onSubmit={handleSubmit}>
							<div class="row">

								<aside class="col-md-5">
									<div
										class="box mb-3 shadow-sm rounded bg-white profile-box text-center">
										<div class="py-4 px-3 border-bottom">
											<h6 class="m-0">Update Service Provider Info</h6><br />
											To Update User Info: <Link to="/editUser">Edit User</Link>
										</div>
										<div class="p-3">
											<h6 class="m-0">
												Upload Profile Picture To Replace Current One
											</h6>
											<br /> <input type="file" class="" name="file"
												id="file" accept="image/*" onChange={handleFileChange} />

										</div>
									</div>
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">About Your Profile</h6>
											<p class="mb-0 mt-0 small">Displayed on profile
												page. Ex. services you provide, experience or expertise, personal profile.</p>
										</div>
										<div class="box-body">
											<div class="p-3">
												<div class="form-group mb-4">
													<label class="mb-1">Basic Info</label> <span
														class="text-danger">*</span>
													<div class="position-relative">
														<textarea maxlength="2500" required name="about" value={profile.about} onChange={handleChange} class="form-control"
															rows="4" placeholder="Enter basic info for profile"></textarea>
													</div>
												</div>
											</div>
										</div>
									</div>
								</aside>
								<main class="col-md-7">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">Basic Info</h6>
											<p class="mb-0 mt-0 small">Basic information of service
												provider.</p>
										</div>
										<div class="box-body p-3">

											<label class="form-label"> Birth Date <span
												class="text-danger">*</span>
											</label>
											<div class="row">

												<div class="col-sm-4 col-md-4">
													<div class="js-form-message">
														<div class="form-group">
															<select name="birthdayMonth" value={profile.birthdayMonth} onChange={handleChange}
																class="form-control custom-select" required
																data-msg="Please select month."
																data-error-class="u-has-error"
																data-success-class="u-has-success">
																<option value="">Select month</option>
																<option value="1">January</option>
																<option value="2">February</option>
																<option value="3">March</option>
																<option value="4">April</option>
																<option value="5">May</option>
																<option value="6">June</option>
																<option value="7">July</option>
																<option value="8">August</option>
																<option value="9">September</option>
																<option value="10">October</option>
																<option value="11">November</option>
																<option value="12">December</option>
															</select>
														</div>
													</div>
												</div>


												<div class="col-sm-4 col-md-4">
													<div class="js-form-message">
														<div class="form-group">
															<select id="birthdayDay" name="birthdayDay" value={profile.birthdayDay} onChange={handleChange}
																class="form-control custom-select" required
																data-msg="Please select date."
																data-error-class="u-has-error"
																data-success-class="u-has-success">
																<option value="">Select date</option>
																{options2.map(option => (
																	<option key={option.value} value={option.value}>
																		{option.label}
																	</option>
																))}
															</select>
														</div>
													</div>
												</div>

												<div class="col-sm-4 col-md-4">
													<div class="js-form-message">
														<div class="form-group">
															<select id="birthdayYear" name="birthdayYear" value={profile.birthdayYear} onChange={handleChange}
																class="form-control custom-select" required
																data-msg="Please select year."
																data-error-class="u-has-error"
																data-success-class="u-has-success">
																<option value="">Select year</option>
																{options.map(option => (
																	<option value={option.value}>
																		{option.label}
																	</option>
																))}
															</select>
														</div>
													</div>
												</div>

											</div>

											<div class="row">

												<div class="col-sm-6 mb-2">
													<div class="js-form-message">
														<label id="phoneNumberLabel" class="form-label">
															Phone number <span class="text-danger">*</span>
														</label>
														<div class="form-group">
															<input required class="form-control" type="tel" maxlength="250"
																name="phoneNumber" value={profile.phoneNumber} onChange={handleChange}
																placeholder="Enter your phone number"
																aria-label="Enter your phone number"
																aria-describedby="phoneNumberLabel"
																data-msg="Please enter a valid phone number"
																data-error-class="u-has-error"
																data-success-class="u-has-success" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">Websites And Social Profiles</h6>
											<p class="mb-0 mt-0 small">Add links to your profile.</p>
										</div>
										<div class="box-body p-3">

											<div class="row">
												<div class="col-sm-6 mb-2">
													<div class="js-form-message">
														<label id="websiteLabel" class="form-label"> Website
														</label>
														<div class="form-group">
															<input class="form-control" type="text" maxlength="500" name="website" value={profile.website} onChange={handleChange}
																placeholder="Enter your website: http:www.example.com/myprofile"
																aria-label="Enter your website"
																aria-describedby="websiteLabel"
																data-msg="Enter a valid website"
																data-error-class="u-has-error"
																data-success-class="u-has-success" />
														</div>
													</div>
												</div>
												<div class="col-sm-6 mb-2">
													<div class="js-form-message">
														<label id="linkedInLabel" class="form-label"> Linked In Profile
														</label>
														<div class="form-group">
															<input name="linkedInProfile" value={profile.linkedInProfile} onChange={handleChange}
																aria-describedby="linkedInLabel"
																placeholder="Add LinkedIn profile link" type="text" maxlength="500"
																class="form-control" />
														</div>
													</div>
												</div>

											</div>
										</div>
									</div>

								</main>
							</div>

							<div class="mb-3 text-right">
								<button type="submit" disabled={isSubmitting} class="btn btn-success">Update Service Provider Profile</button>
							</div>
						</form>
					)}
				</div>
			</div>

		</main>
	);
}

export default EditSeller;
