import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Register({ userId, setUserId, profileId, setProfileId }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable
	const location = useLocation();

	useEffect(() => {

		const queryParams = new URLSearchParams(location.search);
		var isServiceProvider = queryParams.get('serviceProvider');
		if (isServiceProvider == "true") {
			$('#customCheck1').prop('checked', true);
		}

		$("#target").off('submit');
		$("#target").submit(function(event) {
			event.preventDefault();
			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});

			const email = $('#email').val();
			if (!validateEmail(email)) {
				$("#alertText").html("Incorrect email format. Please try again.");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
				return false;
			}

			if ($('#password').val() !== $('#password2').val()) {
				$("#alertText").html("Passwords didn't match.");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
				return false;
			}

			// Get the timezone and add it to the indexed_array
			const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			indexed_array['timezone'] = timezone;

			const apiUrl = `${baseUrl}/users`;
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(user) {
					const apiUrl = `${baseUrl}/users/login`;
					$.ajax({
						url: apiUrl,
						type: 'POST',
						data: JSON.stringify(indexed_array),
						contentType: 'application/json; charset=utf-8',
						dataType: 'json',
						async: false,
						success: function(jwtToken) {
							const token = jwtToken.jwtToken
							localStorage.setItem('token', token)

							const apiUrl = `${baseUrl}/users/byEmail`;
							$.ajax({
								url: apiUrl,
								type: 'GET',
								data: indexed_array,
								contentType: 'application/json; charset=utf-8',
								dataType: 'json',
								async: false,
								beforeSend: function(xhr) {
									xhr.setRequestHeader('Authorization', 'Bearer ' + token);
								},
								success: function(user) {
									localStorage.setItem('user_email', user.email)
									localStorage.setItem('user_name', user.name)
									localStorage.setItem('user_id', user.id)
									localStorage.setItem('login_time', new Date().toISOString());
									setUserId(user.id);

									$("#user_welcome").html("Hello " + user.name);

									var isChecked = $('#customCheck1').prop('checked');
									if (isChecked) {
										navigate(`/emailVerification?forward=/becomeSeller&userId=${user.id}`);
									} else {
										navigate(`/emailVerification?forward=/&userId=${user.id}`);
									}
								},
								error: function(msg) {
									if (msg.responseText) {
										$("#alertText").html(msg.responseText);
										$("#message").html($("#alert-template").clone().removeAttr("style"));
									}
								}
							});
						},
						error: function(msg) {
							if (msg.responseText) {
								$("#alertText").html(msg.responseText);
								$("#message").html($("#alert-template").clone().removeAttr("style"));
							}
						}
					});
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		});
	}, []);

	function validateEmail(email) {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(String(email).toLowerCase());
	}

	return (
		<main>
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>

				<form action="index" id="target">
					<div class="dlevitin-login py-4">

						<div class="row justify-content-center align-items-center">
							<div class="col-md-8">
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-body p-4" >
										<div class="text-center mb-4">
											<h5 class="font-weight-bold mt-4">Sign Up For TaskBeehive</h5>
											<p class="text-muted">Join our community to order services
												or become a service provider.</p>
										</div>
										<div class="form-row">
											<div class="col">
												<div class="form-group">
													<label class="mb-1">Full Name</label>
													<div class="input-group">
														<input required name="name" type="text" maxlength="250" class="form-control" />
													</div>
												</div>
											</div>
											<div class="col">
												<div class="form-group ">
													<label class="mb-1" for="email"> Email </label>
													<div class="input-group">
														<input required class="form-control" id="email" name="email" type="email" maxlength="250" />
													</div>
												</div>
											</div>
										</div>
										<div class="form-row">
											<div class="col">
												<div class="form-group">
													<label class="mb-1">Password</label>
													<div class="input-group">
														<input required type="password" minlength="4" maxlength="250" name="password" id="password"
															class="form-control" />
													</div>
												</div>
											</div>
											<div class="col">
												<div class="form-group">
													<label class="mb-1">Confirm Password</label>
													<div class="input-group">
														<input required type="password" minlength="4" maxlength="250" name="password2" id="password2"
															class="form-control" />
													</div>
												</div>
											</div>
										</div>
										<div class="form-row">
											<div class="col">
												<div class="form-group">
													<label class="mb-1">Address
													<br/>
													<span class="text-muted">Your location is used to find nearby services or customers, and will remain private per our privacy policy.</span>
													</label>
													<div class="input-group">
														<input required type="text" maxlength="250" name="address"
															class="form-control" />
													</div>
												</div>
											</div>
										</div>
										<div class="form-row">
											<div class="col">
												<div class="form-group">
													<label class="mb-1">City</label>
													<div class="position-relativel">
														<input required type="text" maxlength="250" name="city" class="form-control" />
													</div>
												</div>
											</div>
											<div class="col">
												<div class="form-group">
													<label class="mb-1">State</label>
													<div class="position-relative">
														<input required type="text" maxlength="250" name="state"
															class="form-control" />
													</div>
												</div>
											</div>
											<div class="col">
												<div class="form-group">
													<label class="mb-1">Zip Code</label>
													<div class="position-relative">
														<input required type="text" maxlength="250" name="zip" class="form-control" />
													</div>
												</div>
											</div>
										</div>
										<div class="custom-control custom-checkbox">
											<input type="checkbox" class="custom-control-input" id="customCheck1" />
											<label class="custom-control-label" for="customCheck1">
												Select To Become A Service Provider</label>
										</div><br />

										<div class="form-group">
											<label class="mb-1">
												By clicking below and creating an account, I agree to TaskBeehive’s <Link to="/userAgreement" target="_blank">Terms Of Service</Link>, and <Link to="/privacy" target="_blank">Privacy Policy</Link>.
											</label>
										</div>
										<button class="btn btn-success btn-block text-uppercase"
											type="submit">Sign Up</button> <br />
										<span class="ml-auto"> Already on Task Beehive? <Link
											to="/login">Sign in</Link></span><br /><br />

									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div >
		</main >
	);
}

export default Register;