import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';

function Header({ userId, setUserId, profileId, setProfileId, hasBusiness, setHasBusiness }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	useEffect(() => {
		var user_id = localStorage.getItem('user_id');
		setUserId(user_id);
		var profile_id = localStorage.getItem('profile_id');
		setProfileId(profile_id);

		// Check 2 hour time out
		var login_timeStr = localStorage.getItem('login_time');
		if (login_timeStr) {
			const login_time = new Date(login_timeStr);
			const twoHoursLater = new Date(login_time.getTime() + 2 * 60 * 60 * 1000);
			const currentTime = new Date();
			if (currentTime >= twoHoursLater) {
				logout();
			}
		}

		//	Check token is still working
		const token = localStorage.getItem('token');
		if (token) {
			const apiUrl = `${baseUrl}/users/checkToken`;
			$.ajax({
				url: apiUrl,
				type: 'GET',
				processData: false,
				contentType: false,
				beforeSend: function(xhr) {
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
				},
				error: function(msg) {
					if (!msg.responseText) {
						logout();
					};
				}
			});
		}

		// Other
		var x = localStorage.getItem('user_name');
		if (x) {
			$("#user_welcome").html("Hello " + x);
		}

		// Navigation close
		$('.navbar-nav>li>a').on('click', function(e) {
			if ($(this).next('.dropdown-menu').length) {
				e.preventDefault();
			} else {
				$('.navbar-collapse').collapse('hide');
			}
		});
		$('.dropdown-menu a').on('click', function() {
			$('.navbar-collapse').collapse('hide');
		});

	}, []);

	useEffect(() => {
		$("#logout").click(function(e) {
			e.preventDefault();
			logout()
		});
	}, [userId, profileId]);

	function logout() {
		localStorage.removeItem('token')
		localStorage.removeItem('user_email')
		localStorage.removeItem('user_name')
		localStorage.removeItem('user_id')
		localStorage.removeItem('profile_id')
		localStorage.removeItem('login_time')
		localStorage.removeItem('partner_id')
		localStorage.removeItem('has_business')
		setUserId();
		setProfileId();
		setHasBusiness();
		$("#user_welcome").html("Welcome");
		setKeywords('');
		setMessages([]);
		setNotifications([]);
		navigate('/login');
	}

	const [keywords, setKeywords] = useState('');
	const [keywordsLock, setKeywordsLock] = useState(false);
	const handleSearch = (event) => {
		event.preventDefault();
		navigate(`/search?keywords=${encodeURIComponent(keywords)}`);
	};
	const handleInputChange = (event) => {
		const filteredValue = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
		setKeywordsLock(true);
		setKeywords(filteredValue);
	};
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var keywordsQuery = queryParams.get('keywords');
	if (keywordsQuery && keywords != keywordsQuery && !keywordsLock) {
		setKeywords(keywordsQuery);
	}

	// Alerts
	const [notifications, setNotifications] = useState([]);
	const [newNotifications, setNewNotifications] = useState([]);
	useEffect(() => {
		function method() {
			const user_id = localStorage.getItem('user_id');
			if (user_id) {
				const apiUrl = `${baseUrl}/alerts/user/${user_id}?limit=5`;
				$.ajax({
					url: apiUrl,
					type: 'GET',
					processData: false,
					contentType: false,
					beforeSend: function(xhr) {
						const token = localStorage.getItem('token');
						xhr.setRequestHeader('Authorization', 'Bearer ' + token);
					},
					success: function(data) {
						for (let i = 0; i < data.length; i++) {
							const currentObject = data[i];
							currentObject.dateTime = formatDate(currentObject.dateTime);
						}

						const now = new Date();
						const newAlerts = data.filter(alert => {
							const alertTime = new Date(alert.dateTime);
							return (now - alertTime) / 1000 < 600; // Last 10 minutes
						});
						setNewNotifications(newAlerts);

						setNotifications(data);
					},
					error: function(msg) {
						//if (msg.responseText) {
						//$("#alertText").html(msg.responseText);
						//$("#message").html($("#alert-template").clone().removeAttr("style"));
						//}
					}
				});
			}
		}
		method();
		const intervalId = setInterval(method, 60000);
		return () => clearInterval(intervalId);
	}, [userId]);

	// Messages
	const [messages, setMessages] = useState([]);
	const [newMessages, setNewMessages] = useState([]);
	useEffect(() => {
		function method() {
			const user_id = localStorage.getItem('user_id');
			if (user_id) {
				const apiUrl = `${baseUrl}/messages/user/${user_id}?limit=5`;
				$.ajax({
					url: apiUrl,
					type: 'GET',
					processData: false,
					contentType: false,
					beforeSend: function(xhr) {
						const token = localStorage.getItem('token');
						xhr.setRequestHeader('Authorization', 'Bearer ' + token);
					},
					success: function(data) {
						for (let i = 0; i < data.length; i++) {
							const currentObject = data[i];
							currentObject.timestamp = formatDate(currentObject.timestamp);
						}

						const now = new Date();
						const newMsgs = data.filter(msg => {
							const msgTime = new Date(msg.timestamp);
							return (now - msgTime) / 1000 < 600; // Last 10 minutes
						});
						setNewMessages(newMsgs);

						setMessages(data);
					},
					error: function(msg) {
						//if (msg.responseText) {
						//$("#alertText").html(msg.responseText);
						//$("#message").html($("#alert-template").clone().removeAttr("style"));
						//}
					}
				});
			}
		}
		method();
		const intervalId = setInterval(method, 60000);
		return () => clearInterval(intervalId);
	}, [userId]);

	function formatDate(timestamp) {
		const dateObj = new Date(timestamp);
		const year = dateObj.getFullYear();
		const month = String(dateObj.getMonth() + 1).padStart(2, '0');
		const day = String(dateObj.getDate()).padStart(2, '0');
		const hours = String(dateObj.getHours()).padStart(2, '0');
		const minutes = String(dateObj.getMinutes()).padStart(2, '0');
		return `${year}/${month}/${day} ${hours}:${minutes}`;
	}

	return (
		<header>
			<nav
				class="navbar navbar-expand-lg navbar-light topbar static-top shadow-sm bg-white dlevitin-nav-top px-0">

				<div class="container">

					<Link className="navbar-brand" to="/"><img
						src="images/logo7.png" alt="" /></Link>

					<form onSubmit={handleSearch} className="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
						<div className="input-group">
							<input id="searchBar" type="text" maxlength="50" className="form-control bg-white small" placeholder="Find Services..." aria-label="Search" aria-describedby="basic-addon2" value={keywords} onChange={handleInputChange}  style={{ minWidth: '400px' }} />
							<div className="input-group-append">
								<button className="btn btn-success" type="submit">
									<i className="fa fa-search fa-sm"></i>
								</button>
							</div>
						</div>
					</form>

					<ul class="navbar-align-straight navbar-nav align-items-center ml-auto">
						<li
							class="nav-item dropdown no-arrow no-caret mr-3 dropdown-notifications d-sm-none">
							<Link className="btn btn-icon btn-transparent-dark dropdown-toggle"
								to="#" role="button" data-toggle="dropdown"
								aria-haspopup="true" aria-expanded="false"> <i
									class="fa fa-search fa-fw"></i>
							</Link>

							<div
								class="dropdown-menu dropdown-menu-right p-3 shadow-sm animated--grow-in"
								aria-labelledby="searchDropdown">
								<form onSubmit={handleSearch} class="form-inline mr-auto w-100 navbar-search">
									<div className="input-group">
										<input id="searchBar" type="text" maxlength="50" className="form-control bg-white small" placeholder="Find Services..." aria-label="Search" aria-describedby="basic-addon2" value={keywords} onChange={handleInputChange} />
										<div className="input-group-append">
											<button className="btn btn-success" type="submit">
												<i className="fa fa-search fa-sm"></i>
											</button>
										</div>
									</div>
								</form>
							</div>
						</li>
						<li class="nav-item dropdown no-arrow no-caret mr-3 dropdown-notifications">

							<Link className="btn btn-icon btn-transparent-dark dropdown-toggle"
								id="navbarDropdownAlerts" to="/" role="button"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<div style={{ position: 'relative' }}>
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
										viewBox="0 0 24 24" fill="none" stroke="currentColor"
										strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
										class="feather feather-bell">
										<path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
										<path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
									</svg>
									{newNotifications.length > 0 && <span className="blue-dot"></span>}
								</div>
							</Link>
							<div
								class="dropdown-menu dropdown-menu-absolute dropdown-menu-right border-0 shadow animated--fade-in-up"
								aria-labelledby="navbarDropdownAlerts">
								<h6 class="dropdown-header dropdown-notifications-header">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
										viewBox="0 0 24 24" fill="none" stroke="currentColor"
										strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
										class="feather feather-bell mr-2">
										<path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
										<path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
									</svg>
									Notifications
								</h6>
								{notifications && notifications.map(notification => (
									<Link className="dropdown-item dropdown-notifications-item" to="/alerts">
										<div class="dropdown-notifications-item-icon bg-warning">
											<i
												class="fa-light fa-bell" style={{ color: 'white' }}></i>
										</div>
										<div class="dropdown-notifications-item-content">
											<div class="dropdown-notifications-item-content-details">
												{notification.dateTime} {newNotifications.length > 0 && newNotifications.some(newNotification => newNotification.id === notification.id) && <span className="blue-dot2"></span>}
											</div>
											<div class="dropdown-notifications-item-content-text">
												{notification.title}</div>
										</div>
									</Link>
								))}
								{!notifications && (
									<Link className="dropdown-item dropdown-notifications-item" to="#!">
										No recent notifications<br />
									</Link>
								)}
								<Link className="dropdown-item dropdown-notifications-footer"
									to="/alerts">View All Notifications</Link>
							</div>
						</li>
						<li class="nav-item dropdown no-arrow no-caret mr-3 dropdown-notifications">

							<Link className="btn btn-icon btn-transparent-dark dropdown-toggle"
								id="navbarDropdownMessages" to="/" role="button"
								data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<div style={{ position: 'relative' }}>
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
										viewBox="0 0 24 24" fill="none" stroke="currentColor"
										strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
										class="feather feather-mail">
										<path
											d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
										<polyline points="22,6 12,13 2,6"></polyline>
									</svg>
									{newMessages.length > 0 && <span className="blue-dot"></span>}
								</div>
							</Link>
							<div
								class="dropdown-menu dropdown-menu-absolute dropdown-menu-right border-0 shadow animated--fade-in-up"
								aria-labelledby="navbarDropdownMessages">
								<h6 class="dropdown-header dropdown-notifications-header">
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
										viewBox="0 0 24 24" fill="none" stroke="currentColor"
										strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
										class="feather feather-mail mr-2">
										<path
											d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
										<polyline points="22,6 12,13 2,6"></polyline>
									</svg>
									Messages
								</h6>

								{messages && messages.map(message => (
									<Link className="dropdown-item dropdown-notifications-item" to="/messages">
										<div class="dropdown-notifications-item-content">
											<div class="dropdown-notifications-item-content-details">
												{message.fromUserName} - {message.timestamp} {newMessages.length > 0 && newMessages.some(newMessage => newMessage.id === message.id) && <span className="blue-dot2"></span>}
											</div>
											<div class="dropdown-notifications-item-content-text">
												{message.message}
											</div>
										</div>
									</Link>
								))}
								{!messages &&
									<Link className="dropdown-item dropdown-notifications-item" to="#!">
										No recent messages<br />
									</Link>
								}
								<Link className="dropdown-item dropdown-notifications-footer"
									to="/messages">View All Messages</Link>
							</div>
						</li>
						<li class="nav-item dropdown no-arrow no-caret dropdown-user">
							<Link
								class="btn btn-icon btn-transparent-dark dropdown-toggle"
								id="navbarDropdownUserImage" to="/"
								role="button" data-toggle="dropdown" aria-haspopup="true"
								aria-expanded="false"> <svg xmlns="http://www.w3.org/2000/svg"
									width="20" height="20" fill="currentColor"
									class="bi bi-person-gear" viewBox="0 0 16 16">
									<path
										d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
								</svg>

							</Link>
							<div
								class="dropdown-menu dropdown-menu-absolute dropdown-menu-right border-0 shadow animated--fade-in-up"
								aria-labelledby="navbarDropdownUserImage">
								<h6
									class="dropdown-header dropdown-notifications-header d-flex align-items-center">
									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
										fill="currentColor" class="bi bi-person-gear mr-2"
										viewBox="0 0 16 16">
										<path
											d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
									</svg>
									<div class="dropdown-user-details">
										<div class="dropdown-user-details-name" id="user_welcome">Welcome</div>
									</div>
								</h6>
								<div class="dropdown-divider"></div>
								{userId && (
									<div>
										<Link className="dropdown-item" to="/account">
											<div class="dropdown-item-icon">
												<i class="fa-regular fa-gear fa-sm"></i>
											</div> Account
										</Link>
										<Link className="dropdown-item" to="editUser">
											<div class="dropdown-item-icon">
												<i class="fa-solid fa-user-pen fa-sm"></i>
											</div> Edit User Profile
										</Link>
										<Link className="dropdown-item" to={`emailSettings?id=${userId}`}>
											<div class="dropdown-item-icon">
												<i class="fa-sharp fa-thin fa-envelope-open-text"></i>
											</div> Update Email Settings
										</Link>
									</div>
								)}
								{profileId && (
									<div>
										<Link className="dropdown-item" to="editSeller">
											<div class="dropdown-item-icon">
												<i class="fa-solid fa-user-pen fa-sm"></i>
											</div> Edit Service Provider Profile
										</Link>
									</div>
								)}
								{userId && (
									<div>
										<Link className="dropdown-item" to="favorites">
											<div class="dropdown-item-icon">
												<i class="fa-duotone fa-heart"></i>
											</div> Favorites
										</Link>
										<Link className="dropdown-item" to="#" id="logout">
											<div class="dropdown-item-icon">
												<i class="fa-regular fa-arrow-right-from-bracket fa-sm"></i>
											</div> Logout
										</Link>
									</div>
								)}
								{!userId && (
									<div>
										<Link className="dropdown-item" to="login">
											<div class="dropdown-item-icon">
												<i class="fa-regular fa-arrow-right-to-bracket fa-sm"></i>
											</div> Login
										</Link>
										<Link className="dropdown-item" to="register">
											<div class="dropdown-item-icon">
												<i class="fa-regular fa-user-plus fa-sm"></i>
											</div> Sign Up
										</Link>
									</div>
								)}
							</div></li>
					</ul>
				</div>
			</nav>

			<nav
				class="navbar navbar-expand-lg navbar-light bg-white dlevitin-nav-mid px-0 border-top shadow-sm">
				<div class="container">
					<button class="navbar-toggler navbar-toggler-right" type="button"
						data-toggle="collapse" data-target="#navbarResponsive"
						aria-controls="navbarResponsive" aria-expanded="false"
						aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>
					<div class="collapse navbar-collapse" id="navbarResponsive">
						<ul class="navbar-nav">
							<li class="nav-item">
							</li>
							<li class="nav-item"><Link className="nav-link" to="/">Home</Link>
							</li>
							<li class="nav-item"><Link className="nav-link" to="categories">Categories</Link>
							</li>
							<li class="nav-item"><Link className="nav-link" to="contact">Contact</Link>
							</li>
							<li class="nav-item dropdown"><Link
								class="nav-link dropdown-toggle width100" to="#"
								id="navbarDropdownPortfolio" data-toggle="dropdown"
								aria-haspopup="true" aria-expanded="false"> Go To </Link>
								<div class="dropdown-menu"
									aria-labelledby="navbarDropdownPortfolio">
									<Link className="dropdown-item" to="orders">Orders</Link>
									<Link className="dropdown-item" to="/alerts">Notifications</Link>
									<Link class="dropdown-item" to="/messages">Messages</Link>
									<Link className="dropdown-item" to="/search?keywords=">Search</Link>
									{userId && (
										<Link class="dropdown-item" to="/favorites">Favorites</Link>
									)}
								</div></li>

							{profileId && (
								<li class="nav-item dropdown"><Link
									class="nav-link dropdown-toggle width100" to="#" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false"> Service Provider </Link>
									<div class="dropdown-menu">
										<Link className="dropdown-item" to="createService">Create Service</Link>
										<Link class="dropdown-item" to="services">View Your Services</Link>
										<Link class="dropdown-item" to="ordersServiceProvider">View Customer Orders</Link>
										<Link class="dropdown-item" to="dailySchedule">Daily Work Schedule</Link>
										<Link className="dropdown-item" to="editSeller">Edit Service Provider Profile</Link>
										<Link class="dropdown-item" to="earnings">Earnings</Link>
									</div></li>
							)}

							{hasBusiness && (
								<li class="nav-item dropdown"><Link
									class="nav-link dropdown-toggle width100" to="#" data-toggle="dropdown"
									aria-haspopup="true" aria-expanded="false"> Businesses </Link>
									<div class="dropdown-menu">
										<Link class="dropdown-item" to="businesses">View Your Businesses</Link>
										<Link class="dropdown-item" to="createBusiness">Create Business</Link>
									</div></li>
							)}
						</ul>
					</div>
					<ul class="navbar-align-straight navbar-nav ml-auto">
						{!hasBusiness && (
							<li class="nav-item"><Link className="nav-link"
								to="createBusiness"><i
									class="fa-light fa-handshake"></i>
								<span> Create Business</span></Link></li>
						)}
						{!profileId && (
							<li class="nav-item"><Link className="nav-link"
								to="becomeSeller"> <i class="fa-light fa-fw fa-dollar"></i>
								<span> Sign Up As Provider </span></Link></li>
						)}
					</ul>
				</div>
			</nav>
		</header>
	);
}

export default Header;
