import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

function Services() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [tasks, setTasks] = useState([]);

	useEffect(() => {
		var user_id = localStorage.getItem('user_id');
		const apiUrl = `${baseUrl}/tasks/user/` + user_id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const tasks = data.map(task => ({
					id: task.id,
					images: task.images,
					title: task.title,
					cancelled: task.cancelled,
					ownerInfo: task.ownerInfo
				}));
				setTasks(tasks);

				if (tasks.length > 0 && !tasks[0].ownerInfo.hasPaid) {
					const apiUrl2 = `${baseUrl}/invoices/lastInvoice/` + tasks[0].ownerInfo.id;
					$.ajax({
						url: apiUrl2,
						type: 'GET',
						processData: false,
						contentType: false,
						beforeSend: function(xhr) {
							const token = localStorage.getItem('token');
							xhr.setRequestHeader('Authorization', 'Bearer ' + token);
						},
						success: function(invoice) {
							var invoiceUrl = "/invoice?invoiceId=" + invoice.id;
							var message;
							if (invoice.invoiceType === "Monthly") {
								message = "Your services are inactive. Please settle your invoice to reactivate.";
							} else if (invoice.invoiceType === "Registration") {
								message = "To activate your services, please pay the one-time service provider registration advertising fee.";
							}
							$("#alertText").html(`
						            <span style="font-weight: bold; margin-right: 20px;">${message}</span>
						            <a href="${invoiceUrl}">
						                <button style="" class="btn btn-sm btn-warning">View Invoice</button>
						            </a>
						        `);
							$("#message").html($("#alert-template").clone().removeAttr("style"));
						},
						error: function(msg) {
							if (msg.responseText) {
								$("#alertText").html(msg.responseText);
								$("#message").html($("#alert-template").clone().removeAttr("style"));
							}
						}
					});
				}
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleDeleteTask = () => {
		const apiUrl2 = `${baseUrl}/tasks/${currentModalId}`;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const handleRefreshTask = () => {
		var indexed_array = {};
		indexed_array["id"] = currentModalId2;
		indexed_array["dateTime"] = new Date().toISOString();
		indexed_array["imagesProcessed"] = true;

		const apiUrl2 = `${baseUrl}/tasks/${currentModalId2}`;
		$.ajax({
			url: apiUrl2,
			type: 'PUT',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(task) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const [currentModalId, setCurrentModalId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = (id) => {
		setCurrentModalId(id);
		setIsModalOpen(true)
	};
	const handleCloseModal = () => setIsModalOpen(false);

	const [currentModalId2, setCurrentModalId2] = useState(null);
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const handleOpenModal2 = (id) => {
		setCurrentModalId2(id);
		setIsModalOpen2(true)
	};
	const handleCloseModal2 = () => setIsModalOpen2(false);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var hasPaid = queryParams.get('hasPaid');
	useEffect(() => {
		if (hasPaid) {
			$("#alertText").html("Invoice Payment Successful");
			$(".alert").removeClass("alert-warning").addClass("alert-success");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
		}
	}, [])

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<div class="row">
						<div class="col-md-12">
							<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 class="mb-0 p-0">Services</h2>
								<Link class="btn btn-sm btn-success ml-auto" to="/createService">Create A New Service</Link>
							</div>
							<ul class="nav dlevitin-tabs nav-tabs flex-column flex-sm-row ">
								<li class="nav-item">
									<a href="#active" data-toggle="tab" class="nav-link make-black active">
										ALL SERVICES
									</a>
								</li>
							</ul>
							<div class="tab-content dlevitin-table bg-white rounded shadow-sm px-3 pt-1">
								<div class="tab-pane active" id="active">
									<div class="table-responsive box-table mt-3">
										<table class="table table-bordered">
											<thead>
												<tr>
													<th style={{ width: '50%' }}>SERVICE TITLE</th>
													<th style={{ width: '50%' }}>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{tasks.length > 0 ? (
													tasks.map(task => (
														<tr>
															<td style={{ minWidth: '350px' }}>
																<Link to={`/serviceDetails?id=${task.id}`} class="make-black">
																	{task.images[0].imgUrl &&
																		<img class="order-proposal-image" src={`${task.images[0].imgUrl}`} />
																	}
																	<p class="order-proposal-title">{task.title}</p>

																	{!task.ownerInfo.hasPaid &&
																		<span style={{ color: 'rgb(198, 149, 0)', fontWeight: 'bold' }}>Service Is Inactive.</span>
																	}
																</Link>
															</td>
															<td style={{ minWidth: '300px' }}>
																{task.cancelled ? (
																	<button class="btn btn-sm btn-success">Deleted</button>
																) : (
																	<div>
																		<Link to={`/serviceDetails?id=${task.id}`}>
																			<button style={{ margin: '3px' }} class="btn btn-sm btn-success">View Service</button>
																		</Link>
																		<Link to={`/editService?id=${task.id}`}>
																			<button style={{ margin: '3px' }} class="btn btn-sm btn-success">Edit Service</button>
																		</Link>
																		<Link to={`/editSchedule?id=${task.id}`}>
																			<button style={{ margin: '3px' }} class="btn btn-sm btn-success">Edit Schedule</button>
																		</Link>
																		<button style={{ margin: '3px' }} onClick={() => handleOpenModal(task.id)} class="btn btn-sm btn-success">Delete Service</button>
																		<button style={{ margin: '3px' }} onClick={() => handleOpenModal2(task.id)} class="btn btn-sm btn-success">Renew Listing</button>
																	</div>
																)}
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="5">No Services</td>
													</tr>
												)}
												<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
													<div style={{ textAlign: 'center' }}>
														<br /><h5>Are you sure you want to delete your service?</h5><br />
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleDeleteTask()}>Yes, Continue</button>
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal()}>No, Go Back</button>
													</div>
												</Modal>

												<Modal isOpen={isModalOpen2} class="cancelModal" onRequestClose={handleCloseModal2} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
													<div style={{ textAlign: 'center' }}>
														<br /><h5>Renew the service to move it to the top of the search results?</h5><br />
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleRefreshTask()}>Yes, Continue</button>
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal2()}>No, Go Back</button>
													</div>
												</Modal>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br /><br />
					<div class="row">
						<div class="col-md-12 ">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Creating A Business Page</h6>
									<p class="mb-0 mt-0 small">(Optional) Create a business page and link yourself or other service providers to it.
									</p>
								</div>
								<div class="box-body px-3 pt-3 pb-0">
									<div class="row">
										<div class="col-sm-8 mb-4">
											<span class="ml-auto">
												<b>To Create A Business Page</b>:
												<br />Click below and fill out the form. Add service providers to the business using their email.
												<Link to={`/createBusiness`}><br /><br />
													<button class="btn btn-sm btn-success">Create Business Page</button>
												</Link>
											</span>
											<br /><br />
											<span class="ml-auto">
												<b>To Join Existing Business:</b>
												<br /> Provide your email and the title of your service to the business owner and they can add you to their business.
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>

	);
}

export default Services;
