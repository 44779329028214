import $ from 'jquery';

function recentSlider() {
	$('.recent-slider').slick({
		slidesToShow: 9,
		slidesToScroll: 3,
		arrows: true,
		fade: false,
		responsive: [{
			breakpoint: 2950,
			settings: {
				slidesToShow: 8,
				slidesToScroll: 3,
			}
		},{
			breakpoint: 2550,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 3,
			}
		},{
			breakpoint: 2200,
			settings: {
				slidesToShow: 6,
				slidesToScroll: 3,
			}
		},{
			breakpoint: 1850,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 3,
			}
		},{
			breakpoint: 1500,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 3,
			}
		},{
			breakpoint: 1200,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
			}
		}, {
			breakpoint: 900,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			}
		}]
	});
};

function serviceSlider() {
	$('select').select2();

	$('.service-slider').slick({
		slidesToShow: 15,
		slidesToScroll: 3,
		arrows: true,
		fade: false,
		responsive: [{
			breakpoint: 1875,
			settings: {
				slidesToShow: 14,
			}
		},{
			breakpoint: 1700,
			settings: {
				slidesToShow: 13,
			}
		},{
			breakpoint: 1525,
			settings: {
				slidesToShow: 12,
			}
		},{
			breakpoint: 1375,
			settings: {
				slidesToShow: 11,
			}
		},{
			breakpoint: 1225,
			settings: {
				slidesToShow: 10,
			}
		},{
			breakpoint: 1075,
			settings: {
				slidesToShow: 9,
			}
		}, {
			breakpoint: 950,
			settings: {
				slidesToShow: 8,
			}
		}, {
			breakpoint: 825,
			settings: {
				slidesToShow: 7,
			}
		}, {
			breakpoint: 700,
			settings: {
				slidesToShow: 6,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 5,
			}
		}, {
			breakpoint: 500,
			settings: {
				slidesToShow: 4,
			}
		}, {
			breakpoint: 400,
			settings: {
				slidesToShow: 3,
			}
		}]
	});
};

function loadSelect() {
	$('#categorySelect').select2();
};

export default {
	recentSlider,
	serviceSlider,
	loadSelect
};