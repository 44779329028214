import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function EarningsBusiness() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [earningsData, setEarningsData] = useState([]);
	const [startDate, setStartDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().slice(0, 10) + 'T00:00:00Z');
	const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10) + 'T23:59:59Z');

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var businessId = queryParams.get('id');

	useEffect(() => {
		const apiUrl = `${baseUrl}/tasks/business/${businessId}?startDate=${startDate}&endDate=${endDate}`;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const earningsData = data.map(task => ({
					title: task.title,
					ownerEmail: task.ownerInfo.userInfo.email,
					totalOrders: task.orders.length,
					totalEarned: task.orders.reduce((total, order) => total + order.totalCost, 0),
					averageReview: task.reviews.length > 0 ? (task.reviews.reduce((sum, review) => sum + review.rating, 0) / task.reviews.length).toFixed(1) : "No Reviews",
					numReviews: task.reviews.length
				}));

				setEarningsData(earningsData);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [startDate, endDate])

	const [business, setBusiness] = useState();

	useEffect(() => {
		const apiUrl = `${baseUrl}/businesses/` + businessId;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(business) {
				setBusiness(business);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleStartDateChange = (e) => {
		try {
			setStartDate(new Date(e.target.value).toISOString().slice(0, 10) + 'T00:00:00Z');
		} catch (error) { }
	};

	const handleEndDateChange = (e) => {
		try {
			setEndDate(new Date(e.target.value).toISOString().slice(0, 10) + 'T23:59:59Z');
		} catch (error) { }
	};

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div class="row  justify-content-center align-items-center">
						<div class="col-md-8">
							<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 class="mb-0 p-0">Business Earnings And Reviews</h2>
							</div>

							{business &&
								<div class="profile_info">
									<div class="seller-card">
										<div>
											<div class="user-profile-info">
												<div>
													<div class="user-profile-image">
														<img src={`${business.images[0].imgUrl}`} class="img-fluid user-pict-img" alt="Askbootstrap" style={{ width: "35%" }} />
													</div>
												</div>
												<div class="user-profile-label">
													<div class="username-line">
														<a href="#" class="seller-link">{business.name}</a>
													</div>
													<div class="oneliner-wrapper">
														<small class="oneliner">Business Information</small>
													</div>
												</div>
											</div>

											<div class="user-stats-desc"><br />
												<div class="row justify-content-center">
													<div class="col-sm-5 mb-2">
														<label htmlFor="start-date" className="form-label">Start Date</label>
														<input
															type="date"
															id="start-date"
															className="form-control"
															value={startDate.slice(0, 10)}
															onChange={handleStartDateChange}
														/>
													</div>
													<div class="col-sm-5 mb-2">
														<label htmlFor="end-date" className="form-label">End Date</label>
														<input
															type="date"
															id="end-date"
															className="form-control"
															value={endDate.slice(0, 10)}
															onChange={handleEndDateChange}
														/>
													</div>
												</div>
											</div>
											<ul className="user-stats">
												Earnings And Reviews Per Service Provider:
												{earningsData.map((task, index) => (
													<li key={index}>
														<hr />
														<ul>
															<li>Owner Email: <strong>{task.ownerEmail}</strong></li>
															<li>Title: <strong>{task.title}</strong></li>
															<li>Total Orders: <strong>{task.totalOrders}</strong></li>
															<li>Total Earned: <strong>${task.totalEarned}</strong></li>
															<li>Average Review: <strong>{task.averageReview} ({task.numReviews})</strong></li>
														</ul>
													</li>
												))}
											</ul>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</main >
	);
}

export default EarningsBusiness;
