import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Login({ userId, setUserId, profileId, setProfileId, hasBusiness, setHasBusiness }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const redirectPath = searchParams.get('redirect') || "/";

	useEffect(() => {
		$("#target").off('submit');
		$("#target").submit(function(event) {
			event.preventDefault();
			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});

			const apiUrl = `${baseUrl}/users/login`;
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				success: function(jwtToken) {
					const token = jwtToken.jwtToken
					localStorage.setItem('token', token)

					const apiUrl = `${baseUrl}/users/byEmail`;
					$.ajax({
						url: apiUrl,
						type: 'GET',
						data: indexed_array,
						contentType: 'application/json; charset=utf-8',
						dataType: 'json',
						async: false,
						beforeSend: function(xhr) {
							xhr.setRequestHeader('Authorization', 'Bearer ' + token);
						},
						success: function(user) {
							localStorage.setItem('user_email', user.email)
							localStorage.setItem('user_name', user.name)
							localStorage.setItem('user_id', user.id)
							localStorage.setItem('login_time', new Date().toISOString());
							var hasBusiness = user.businesses && user.businesses.length > 0 ? true : false;
							localStorage.setItem('has_business', hasBusiness);
							setUserId(user.id);
							setHasBusiness(hasBusiness);
							if (user.profile) {
								localStorage.setItem('profile_id', user.profile.id)
								setProfileId(user.profile.id);
							}

							$("#user_welcome").html("Hello " + user.name);
							navigate(redirectPath, { replace: true });
						},
						error: function(msg) {
							if (msg.responseText) {
								$("#alertText").html(msg.responseText);
								$("#message2").html($("#alert-template").clone().removeAttr("style"));
							}
						}
					});
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message2").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		});
	}, []);

	return (
		<main>
			<div class="">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message2"></div>

					<div class="row justify-content-center align-items-center d-flex vh-80">
						<div class="col-lg-4">
							<div class="dlevitin-login py-4">
								<div class="col-md-30">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-body p-4" >
											<div class="text-center mb-4">
												<h5 class="font-weight-bold mt-3">Welcome</h5>
												<p class="text-muted">Login to to hire or offer a professional service.</p>
											</div>
											<form action="index.html" id="target">
												<div class="form-group">
													<label class="mb-1">Email</label>
													<div class="position-relative">
														<input required type="email" name="email" class="form-control" />
													</div>
												</div>
												<div class="form-group">
													<label class="mb-1">Password</label>
													<div class="position-relative">
														<input required type="password" name="password" class="form-control" />
													</div>
												</div>
												<button class="btn btn-success btn-block text-uppercase" type="submit"> Login </button>

												<div class="py-3 d-flex align-item-center">
													<Link to="/resetPassword">Forgot Password</Link>
												</div>
											</form>
											<div>

												<div class="py-3 d-flex align-item-center">
													<span> New to Task Beehive: </span>
												</div>
												<Link to="/register">
													<button class="btn btn-success btn-block text-uppercase" type="submit"> Sign Up </button>
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Login;
