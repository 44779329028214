import React, { useEffect } from "react";
import $ from 'jquery';
import { Link, useLocation } from 'react-router-dom';

function About() {
	const baseUrl = process.env.REACT_APP_REST_API_URL;

	const youtubeVideoIds = [
		'3wWNVWbyL8g'
	];
	const getRandomVideoId = () => {
		const randomIndex = Math.floor(Math.random() * youtubeVideoIds.length);
		return youtubeVideoIds[randomIndex];
	};

	// Update tracking
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var fbclid = queryParams.get('fbclid');
	var gclid = queryParams.get('gclid');
	useEffect(() => {
		if (fbclid || gclid) {
			const indexed_array = {};
			if (fbclid) {
				indexed_array["trackingType"] = "Facebook";
				indexed_array["trackingId"] = fbclid;
			}
			if (gclid) {
				indexed_array["trackingType"] = "Google";
				indexed_array["trackingId"] = gclid;
			}
			const apiUrl2 = `${baseUrl}/tracking`;
			$.ajax({
				url: apiUrl2,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(profile) {
				},
				error: function(msg) {
				}
			});
		};
	}, [])

	return (
		<main>
			<section className="py-5 homepage-search-block position-relative">
				<div className="container">
					<div className="row">
						<div className="col-lg-6" >
							<div className="shadow-sm rounded bg-white" style={{ marginBottom: '2.0rem' }}>
								<div className="box-title border-bottom p-3">
									<h6 className="m-0"> Welcome To TaskBeehive </h6>
								</div>
								<div className="box-body">
									<div className="p-3">
										<p style={{ fontSize: "13px" }}>
											<b>Customers</b><br /> Get help with your to-do list, learn a new skill, or enjoy a personalized service. Find help for any task.
											<br /><br />
											<b>Service Providers</b><br /> Work for yourself, connect with customers, and turn your skills or passion into a business.
											<br /><br />
											<b>Businesses</b><br /> Find new customers, create a business page, manage providers, and track their earnings and reviews.
										</p>

										<div style={{ textAlign: 'center' }}>
											<Link to="/register">
												<button className="btn btn-sm btn-success btn-stack" style={{ height: '33px', marginRight: '0.5rem' }}>Sign Up</button>
											</Link>
											<Link to="/register?serviceProvider=true">
												<button className="btn btn-sm btn-success" style={{ height: '33px' }}>Sign Up As A Service Provider</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-6" >
							<div className="shadow-sm rounded bg-white" style={{ maxWidth: '560px', marginBottom: '2.0rem' }} >
								<div className="box-body">
									<div className="p-3">
										<div className="video-responsive">
											<iframe
												width="560"
												height="315"
												src={`https://www.youtube.com/embed/${getRandomVideoId()}?rel=0&showinfo=0&autoplay=0&controls=0`}
												frameBorder="0"
												allowFullScreen
												title="Embedded YouTube"
											></iframe>
										</div>
									</div>
								</div>
							</div>

						</div>
						<div className="col-lg-6" >
							<div className="shadow-sm rounded bg-white" style={{ marginBottom: '2.0rem' }}>
								<div className="box-title border-bottom p-3" id="benefits-for-service-providers">
									<h6 className="m-0"> Sign Up For Free</h6>
								</div>
								<div className="box-body">
									<div className="p-3">
										<b>Customers, Business Owners, and Referral Partners</b><br /> Join Task Beehive for free, with no fees ever.
										<br /><br />
										<b>Service Providers</b><br /> Try out the platform for free. Preview the service and business pages. When you are ready go live pay a one-time advertising fee, used to find customers for your business.
										<br /><br />
										We are excited to have you join our Beehive
										<br />
									</div>
								</div>
							</div>

							<div className="shadow-sm rounded bg-white" style={{ marginBottom: '2.0rem' }}>
								<div className="box-body">
									<div className="p-3">
										<div style={{ textAlign: 'center' }}>

											<div style={{ textAlign: 'center' }}>
												<Link to="/register?serviceProvider=true">
													<img style={{ maxWidth: '100%' }} src="images/ad4.jpg" />
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-6">
							<div className="shadow-sm rounded bg-white" style={{ marginBottom: '2.0rem' }}>
								<div className="box-body">
									<div className="box-title border-bottom p-3" id="benefits-for-businesses" style={{ marginBottom: '0.5rem' }}>
										<h6 className="m-0"> Benefits</h6>
									</div>
									<ul className="nav nav-tabs" role="tablist">
										<li className="nav-item nav-tabs-large">
											<a className="nav-link active" id="customer-tab" data-toggle="tab" href="#customer" role="tab">Customer</a>
										</li>
										<li className="nav-item">
											<a className="nav-link  nav-tabs-large" id="service-provider-tab" data-toggle="tab" href="#service-provider" role="tab">Service Provider</a>
										</li>
										<li className="nav-item nav-tabs-large">
											<a className="nav-link" id="business-tab" data-toggle="tab" href="#business" role="tab">Business</a>
										</li>
									</ul>
									<div className="tab-content">

										<div className="tab-pane fade show active" id="customer" role="tabpanel">
											<ul className="list-group modern" style={{ paddingTop: '0.25rem' }}>
												<li className="list-group-item">
													<i className="fa-light fa-list-check fa-xl fa-margin-right"></i>
													<span><b> Find The Perfect Provider</b><div> Pick the service provider that best fits your needs. Ensure they have the right availability, experience, and reviews.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-messages fa-xl fa-margin-right"></i>
													<span><b> Messaging And Communication</b><div> Message service providers directly to request a custom quote or discuss the project.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-star-sharp fa-xl fa-margin-right"></i>
													<span><b> Reviews</b><div> Leave reviews for service providers. Get only the best service providers with good reputations.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-calendar-days fa-xl fa-margin-right"></i>
													<span><b> Schedule Management</b><div> Schedule the service when you need it.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-sack-dollar fa-xl fa-margin-right"></i>
													<span><b> Payment</b><div> Pay the service provider directly. Pay with cash, check, or credit card (using Square, Venmo, etc).</div></span>
												</li>
											</ul>
										</div>

										<div className="tab-pane fade" id="service-provider" role="tabpanel">
											<ul className="list-group modern" style={{ paddingTop: '0.25rem' }}>
												<li className="list-group-item">
													<i className="fa-light fa-people-group fa-xl fa-margin-right"></i>
													<span><b> Find New Customers</b><div> Find new customers for your service or business.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-calendar-days fa-xl fa-margin-right"></i>
													<span><b> Schedule Management</b><div> Schedule your service availability, and have full control over your own schedule.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-messages fa-xl fa-margin-right"></i>
													<span><b> Messaging And Communication</b><div> Customers can contact you to request a custom quote or discuss the project.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-sack-dollar fa-xl fa-margin-right"></i>
													<span><b> Payment</b><div> Customers pay the service provider directly for the service cost. Accept any payment type.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-buildings fa-xl fa-margin-right"></i>
													<span><b> Business Page</b><div> Create a business page with your contact info. Displayed on the service page as well.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-dollar fa-xl fa-margin-right"></i>
													<span><b> Earnings</b><div> View your earnings for any range of time.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-gear fa-xl fa-margin-right"></i>
													<span><b> Customer Management</b><div> View and manage customer orders, daily work schedule, notifications, and other tools.</div></span>
												</li>
											</ul>
										</div>


										<div className="tab-pane fade" id="business" role="tabpanel">
											<ul className="list-group modern" style={{ paddingTop: '0.25rem' }}>
												<li className="list-group-item">
													<i className="fa-light fa-buildings fa-xl fa-margin-right"></i>
													<span><b> Business Page</b><div> Create a business page with contact information. Link providers to it.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-people-group fa-xl fa-margin-right"></i>
													<span><b> Find New Customers</b><div> Find new customers for your service or business.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-calendar-days fa-xl fa-margin-right"></i>
													<span><b> Schedule Management</b><div> Service providers can manage their own schedules.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-messages fa-xl fa-margin-right"></i>
													<span><b> Messaging And Communication</b><div> Customers can contact business or provider for a custom quote or to discuss the project.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-sack-dollar fa-xl fa-margin-right"></i>
													<span><b> Payment</b><div> Customers pay the service providers directly. Track how much each provider earns.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-star-sharp fa-xl fa-margin-right"></i>
													<span><b> Reviews</b><div> Customers can leave reviews for service providers. View their ratings and reviews.</div></span>
												</li>
												<li className="list-group-item">
													<i className="fa-light fa-dollar fa-xl fa-margin-right"></i>
													<span><b> Earnings</b><div> Break down the business earnings. View each provider's earnings for any range of time.</div></span>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="col-lg-6" >
							<div className="shadow-sm rounded bg-white" style={{ marginBottom: '2.0rem' }}>
								<div className="box-title border-bottom p-3" id="benefits-for-businesses">
									<h6 className="m-0"> What Service Categories Do We Have?</h6>
								</div>
								<div className="box-body">
									<div className="p-3">
										<p>Most comprehensive range of services of any platform. Featuring <b>over 100 categories</b> around the house, custom location, or fully remote.</p>

										<p class="mt-4"><b>The Categories Encompass</b></p>
										<div className="d-flex flex-wrap mt-2">
											<ul className="list-group" style={{ flex: 1, marginRight: '0.0rem' }}>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Home Or Yard Tasks</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Tutoring And Lessons</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Pet Care</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Events And Entertainment</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Fitness, Yoga, And Meditation</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Health And Well Being</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Legal Help</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Shopper Or Delivery</li>
											</ul>
											<ul className="list-group" style={{ flex: 1 }}>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Cooking</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Personal Care And Beauty</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Errands And Personal Assistance</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Handyman And Home Improvement</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Car Care</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Consulting</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>Home Tech Help</li>
												<li className="list-group-item" style={{ paddingBottom: '0.65rem', paddingTop: '0.65rem', minWidth: '250px' }}>And Many Other Services</li>
											</ul>
										</div>

										<br />
										<div style={{ textAlign: 'center' }}>
											<Link to="/categories" target="_blank">
												<button className="btn btn-sm btn-success" style={{ height: '33px' }}>View All The Service Categories</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>


						<div className="col-lg-6" >
							<div className="shadow-sm rounded bg-white" style={{ marginBottom: '2.0rem' }}>
								<div className="box-title border-bottom p-3" id="benefits-for-businesses">
									<h6 className="m-0"> Why TaskBeehive?</h6>
								</div>
								<div className="box-body">
									<div className="p-3">
										<b>Essential Tools</b><br/>
										TaskBeehive connects customers with service providers, empowering both sides with essential tools such as scheduling, messaging, reviews, business page with contact info, and many other features.
										<br /><br />
										<b>Free</b><br/>
										Unlike other platforms, customers pay service providers directly. The platform is entirely free for customers, and the fee for service providers is the lowest of any platform.
										<br /><br />
										<b>Compare With Other Platforms</b><br/>
										Competitors charge the customer and provider up to 30% of cost from every job, and other fees.
										<br /><br />
										<div style={{ textAlign: 'center' }}>
											<Link to="/register">
												<button className="btn btn-sm btn-success btn-stack" style={{ height: '33px', marginRight: '0.5rem' }}>Sign Up</button>
											</Link>
											<Link to="/register?serviceProvider=true">
												<button className="btn btn-sm btn-success" style={{ height: '33px' }}>Sign Up As A Service Provider</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="shadow-sm rounded bg-white">
								<div className="box-body">
									<div className="p-3">
										<div style={{ textAlign: 'center' }}>

											<div style={{ textAlign: 'center' }}>
												<Link to="/register?serviceProvider=true">
													<img style={{ maxWidth: '100%' }} src="images/ad1.jpg" />
												</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main >
	);
}

export default About;
