import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Login({ userId, setUserId, profileId, setProfileId }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var id = queryParams.get('id');

	const handleChangePassword = (event) => {
		event.preventDefault();
		$("#target").off('submit');
		$("#target").submit(function(event) {
			event.preventDefault();
			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});
			indexed_array["email"] = id;

			if ($('#password').val() !== $('#password2').val()) {
				$("#alertText").html("Passwords didn't match.");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
				return false;
			}

			const apiUrl = `${baseUrl}/users/changePassword`;
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				success: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
						$(".alert").removeClass("alert-warning").addClass("alert-success");
					}
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
						$(".alert").removeClass("alert-success").addClass("alert-warning");
					}
				}
			});
		});
	};

	return (
		<main>
			<div class="">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<div class="row justify-content-center align-items-center d-flex vh-80">
						<div class="col-lg-4">
							<div class="dlevitin-login py-4">
								<div class="col-md-30">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-body p-3" >
											<div class="text-center mb-4">
												<h5 class="font-weight-bold mt-3">Update Your Password</h5>
												<p class="text-muted">Type your new password and confirm it to update.</p>
											</div>
											<form onSubmit={handleChangePassword} id="target">
												<div class="form-group">
													<label class="mb-1">Password</label>
													<div class="input-group">
														<input required type="password" maxlength="250" name="password" id="password"
															class="form-control" />
													</div>
												</div>
												<div class="form-group">
													<label class="mb-1">Confirm Password</label>
													<div class="input-group">
														<input required type="password" maxlength="250" name="password2" id="password2"
															class="form-control" />
													</div>
												</div>

												<br />
												<button class="btn btn-success btn-block text-uppercase" type="submit"> Change Password </button>

											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Login;
