import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

function PageNotFound() {

	useEffect(() => {

	}, []);

	return (
		<main>
			<section class="py-5 bg-white border-top border-bottom">
				<div class="container">
					<div class="row py-lg-5">
						<div class="col-lg-8 col-md-8 mx-auto text-center">
							<h1>Sorry! Page not found.</h1>
							<p class="land">Unfortunately the page you are looking for has been moved or deleted.</p>
							<div class="mt-5">
								<Link to="/" class="btn btn-success"> GO TO HOME PAGE</Link>
							</div>
						</div>
					</div>
				</div>
			</section>
		</main>
	);
}

export default PageNotFound;
