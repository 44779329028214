import React from 'react';
import ReactDOM from 'react-dom/client';
import './static/vendor/bootstrap/css/bootstrap.min.css';
import './static/vendor/fontawesome/css/all.min.css';
import './static/vendor/slick-master/slick/slick.css';
import './static/vendor/select2/css/select2.min.css';
import './static/css/style.css';

import $ from 'jquery';
import './static/vendor/bootstrap/js/bootstrap.bundle.min.js';
import './static/vendor/slick-master/slick/slick.js';
import './static/vendor/lightgallery-master/dist/js/lightgallery-all.min.js';
import './static/vendor/select2/js/select2.min.js';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
