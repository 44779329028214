import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

function OrderDetails() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [order, setOrder] = useState();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var id = queryParams.get('id');

	useEffect(() => {
		const apiUrl = `${baseUrl}/orders/${id}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				data.availability.sort((a, b) => new Date(a.availableAt) - new Date(b.availableAt));
				setOrder(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	const handleContactSeller = (event) => {
		event.preventDefault();
		const indexed_array = {};
		var user_id = localStorage.getItem('user_id');
		if (!user_id) {
			navigate('/messages');
		}
		var user = { "id": user_id };
		indexed_array["fromUser"] = user;
		var to_user = { "id": order.taskInfo.ownerInfo.userInfo.id };
		indexed_array["toUser"] = to_user;

		indexed_array["message"] = "Intro: Hello, I have a question about the order for '" + order.taskInfo.title + "' with order id " + order.id + ".";

		const apiUrl = `${baseUrl}/messages`;
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function() {
				navigate('/messages');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const handleContactUser = (event) => {
		event.preventDefault();
		const indexed_array = {};
		var user_id = localStorage.getItem('user_id');
		if (!user_id) {
			navigate('/messages');
		}
		var user = { "id": user_id };
		indexed_array["fromUser"] = user;
		var to_user = { "id": order.userInfo.id };
		indexed_array["toUser"] = to_user;

		indexed_array["message"] = "Intro: Hello, I have an update on your order for '" + order.taskInfo.title + "' with order id " + order.id + ".";

		const apiUrl = `${baseUrl}/messages`;
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function() {
				navigate('/messages');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const handleCancelOrder = (orderId) => {
		const apiUrl2 = `${baseUrl}/orders/${orderId}`;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate('/orders');
			},
			error: function(msg) {
				if (!msg.responseText) { msg.responseText = 'Please login first to cancel order.'; }
				handleCloseModal();
				$("#alertText").html(msg.responseText);
				$("#message").html($("#alert-template").clone().removeAttr("style"));
			}
		});
	};

	function formatDate(isoDateString) {
		const date = new Date(isoDateString);
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true
		};
		return date.toLocaleTimeString('en-US', options);
	}

	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);

	return (
		<div class="main-page py-5">
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>

				{order && (
					<div class="row">

						<div class="col-lg-6 left">
							<div>
								<div class="profile_info">
									<div class="seller-card">
										<div>
											<div><a href="#" class="ambassadors-badge">Order And Customer Information</a></div>
											<br /><br />
											<div class="user-profile-info">
												<div class="user-profile-label">
													<div class="username-line">
														<a href="#" class="seller-link">{order.userInfo.name}</a>
													</div>
													<div class="oneliner-wrapper">
														<small class="oneliner">Customer</small>
													</div>
												</div>
											</div><br />
											<Link class="fit-button" to="#" onClick={handleContactUser}>
												<button style={{ margin: '5px' }} class="btn btn-sm btn-success"><b>Contact Customer</b></button>
											</Link>
											<div class="user-stats-desc">
												<ul class="user-stats">
													<li>Status<strong>{order.cancelled ? "Cancelled" : "Active"}</strong></li>
													<li>Order Id<strong>{order.id}</strong></li>
													<li>Job Location Address<strong>
														{order.remote ? "Remote Job" : `${order.address} ${order.city}, ${order.state}, ${order.zip}`}
													</strong>
													</li>

													<li>Total Cost<strong>
														${order.totalCost}
													</strong></li>
													<li>Date Ordered<strong>
														{formatDate(order.dateTime)}
													</strong></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
								<div class="seller-profile">
									<div class="description">
										<h6>Order Scheduled Dates</h6>

										<div class="seller-card">
											<div>
												<div class="user-stats-desc">
													<ul class="user-stats">
														<li>Service Date And Time<strong>
															{order.availability.map((available) => (
																<div><span>{formatDate(available.availableAt)}</span></div>
															))}
														</strong></li>
													</ul>
													{order.availability.map((available) => (
														<br />
													))}
												</div>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6 right">
							<div>
								<div class="profile_info">
									<div class="seller-card">
										<div>
											<div><a href="#" class="ambassadors-badge">Service Information</a></div><br />
											<div class="user-profile-info"><br />
												<div>
													<div class="user-profile-image" style={{ width: '50%', margin: '0 auto' }}>
														<img src={`${order.taskInfo.images[0].imgUrl}`} class="img-fluid user-pict-img" alt="Askbootstrap" />

													</div>
												</div><br />
												<div class="user-profile-label">
													<div class="username-line">
														<h6><b>{order.taskInfo.title}</b></h6>
													</div>
													<div class="oneliner-wrapper">
														<small class="oneliner">Service Title</small>
													</div>
												</div>
											</div><br />


											<Link to={`/serviceDetails?id=${order.taskInfo.id}`}>
												<button style={{ margin: '5px' }} class="btn btn-sm btn-success"><b>View Service</b></button>
											</Link>
											{!order.cancelled && (
												<button style={{ margin: '5px' }} onClick={() => handleOpenModal()} class="btn btn-sm btn-success">Cancel Order</button>
											)}
											<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
												<div style={{ textAlign: 'center' }}>
													<br /><h5>Are you sure you want to cancel order?</h5><br />
													<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCancelOrder(order.id)}>Yes, Continue</button>
													<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal()}>No, Go Back</button>
												</div>
											</Modal>
										</div>
									</div>
								</div>
								<div class="profile_info">
									<div class="seller-card">
										<div>
											<div><a href="#" class="ambassadors-badge">Service Provider Information</a></div><br />
											<div class="user-profile-info"><br />
												<div>
													<div class="user-profile-image" style={{ width: '50%', margin: '0 auto' }}>
														<img src={`${order.taskInfo.ownerInfo.images[0].imgUrl}`} class="img-fluid user-pict-img" alt="Askbootstrap" />
													</div>
												</div><br />
												<div class="user-profile-label">
													<div class="username-line">
														<h6><b>{order.taskInfo.ownerInfo.userInfo.name}</b></h6>
													</div>
													<div class="oneliner-wrapper">
														<small class="oneliner">Professional Service Provider</small>
													</div>
												</div>
											</div><br />
											<Link to={`/userDetails?id=${order.taskInfo.ownerInfo.id}`}>
												<button style={{ margin: '5px' }} class="btn btn-sm btn-success"><b>View Service Provider</b></button>
											</Link>
											<Link class="fit-button" to="#" onClick={handleContactSeller}>
												<button style={{ margin: '5px' }} class="btn btn-sm btn-success"><b>Contact Service Provider</b></button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				)}
			</div>
		</div>
	);
}

export default OrderDetails;