import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function DailySchedule() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [availabilities, setAvailabilities] = useState([]);
	const [startDate, setStartDate] = useState(new Date().toISOString().slice(0, 10) + 'T00:00:00Z');
	const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10) + 'T23:59:59Z');

	useEffect(() => {
		var profile_id = localStorage.getItem('profile_id');
		const apiUrl = `${baseUrl}/availability/byUser/${profile_id}?startDate=${startDate}&endDate=${endDate}`;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const availabilities = data.map(avail => ({
					...avail,
					orderInfo: {
						orderId: avail.orderInfo.id,
						dateTime: new Date(avail.orderInfo.dateTime),
						totalCost: avail.orderInfo.totalCost,
						userInfo: avail.orderInfo.userInfo
					},
					taskInfo: {
						taskId: avail.taskInfo.id,
						taskTitle: avail.taskInfo.title,
						images: avail.taskInfo.images
					},
					availabilityTime: new Date(avail.availableAt)
				}));
				availabilities.sort((a, b) => a.availabilityTime - b.availabilityTime); // Sort in descending order
				setAvailabilities(availabilities);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [startDate, endDate]);

	function formatDate(date) {
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true
		};
		return date.toLocaleTimeString('en-US', options);
	}

	const handleStartDateChange = (e) => {
		try {
			setStartDate(new Date(e.target.value).toISOString().slice(0, 10) + 'T00:00:00Z');
		} catch (error) { }
	};

	const handleEndDateChange = (e) => {
		try {
			setEndDate(new Date(e.target.value).toISOString().slice(0, 10) + 'T23:59:59Z');
		} catch (error) { }
	};

	return (
		<main>
			<div className="main-page second py-5">
				<div className="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div className="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" className="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<div className="row">
						<div className="col-md-12">
							<div className="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 className="mb-0 p-0">Daily Work Schedule</h2>
								<Link className="btn btn-sm btn-success ml-auto" to="/ordersServiceProvider">View Customer Orders </Link>
							</div>

							<div className="row justify-content-center">
								<div className="col-sm-5 mb-2">
									<label htmlFor="start-date" className="form-label">Start Date</label>
									<input
										type="date"
										id="start-date"
										className="form-control"
										value={startDate.slice(0, 10)}
										onChange={handleStartDateChange}
									/>
								</div>
								<div className="col-sm-5 mb-2">
									<label htmlFor="end-date" className="form-label">End Date</label>
									<input
										type="date"
										id="end-date"
										className="form-control"
										value={endDate.slice(0, 10)}
										onChange={handleEndDateChange}
									/>
								</div>
							</div><br />

							<ul className="nav dlevitin-tabs nav-tabs flex-column flex-sm-row ">
								<li className="nav-item">
									<a href="#active" data-toggle="tab" className="nav-link make-black active">
										DAILY WORK SCHEDULE SORTED BY TIME
									</a>
								</li>
							</ul>
							<div className="tab-content dlevitin-table bg-white rounded shadow-sm px-3 pt-1">
								<div className="tab-pane active" id="active">
									<div className="table-responsive box-table mt-3">
										<table className="table table-bordered">
											<div style={{ overflowY: 'auto', maxHeight: '600px' }} >
												<thead>
													<tr>
														<th style={{ width: '10%' }}>SERVICE TIME</th>
														<th style={{ width: '20%' }}>ADDRESS</th>
														<th style={{ width: '5%' }}>TOTAL</th>
														<th style={{ width: '45%' }}>SERVICE TITLE</th>
														<th style={{ width: '20%' }}>ACTIONS</th>
													</tr>
												</thead>
												<tbody>
													{availabilities.length > 0 ? (
														availabilities.map(avail => (
															<tr key={`${avail.orderInfo.orderId}-${avail.availabilityTime}`}>
																<td style={{ minWidth: '225px' }}>
																	<div><span>{formatDate(avail.availabilityTime)}</span></div>
																</td>
																<td style={{ minWidth: '250px' }}>
																	<ul className="user-stats">
																		<li>
																			<div><span>{avail.orderInfo.userInfo.address} <br /> {avail.orderInfo.userInfo.city} {avail.orderInfo.userInfo.state} {avail.orderInfo.userInfo.zip}</span></div>
																		</li>
																	</ul>
																</td>
																<td>${avail.orderInfo.totalCost}</td>
																<td style={{ minWidth: '350px' }}>
																	<Link to={`/serviceDetails?id=${avail.taskInfo.taskId}`} className="make-black">
																		<img className="order-proposal-image" src={`${avail.taskInfo.images[0].imgUrl}`} alt="Service" />
																		<p className="order-proposal-title">{avail.taskInfo.taskTitle}</p>
																	</Link>
																</td>
																<td style={{ minWidth: '150px' }}>
																	<Link to={`/orderDetails?id=${avail.orderInfo.orderId}`} className="make-black"  target="_blank">
																		<button style={{ margin: '3px' }} class="btn btn-sm btn-success">Order Details</button>
																	</Link>
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="5" >No Customer Orders For The Selected Dates</td>
														</tr>
													)}
												</tbody>
											</div>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main >
	);
}

export default DailySchedule;
