import React, { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import '../static/vendor/slick-master/slick/slick.js';
import sliders from '../static/js/custom2.js';
import $ from 'jquery';

function Home() {
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [categories, setCategories] = useState([]);
	const [genCategories, setGenCategories] = useState([]);
	const [services, setServices] = useState([]);
	const [recentSliderState, setRecentSliderState] = useState(false);
	const [serviceSliderState, setServiceSliderState] = useState(false);

	// Get services effect
	useEffect(() => {
		const apiUrl = `${baseUrl}/tasks?limit=15`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setServices(data);
				setRecentSliderState(true);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	useEffect(() => {
		const apiUrl3 = `${baseUrl}/categories`;
		$.ajax({
			url: apiUrl3,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const groupedCategories = data.reduce((acc, entry) => {
					if (acc[entry.genCategoryName]) {
						acc[entry.genCategoryName].subCategories.push({
							value: entry.id,
							subCategoryName: entry.subCategoryName
						});
					} else {
						acc[entry.genCategoryName] = {
							genCategoryName: entry.genCategoryName,
							subCategories: [{ value: entry.id, subCategoryName: entry.subCategoryName }]
						};
					}
					return acc;
				}, {});
				const categories = Object.values(groupedCategories);
				categories.forEach(category => {
					category.subCategories.sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName));
				});
				categories.sort((a, b) => a.genCategoryName.localeCompare(b.genCategoryName));
				setCategories(categories);

				const uniqueGenCategoryNames = new Set();
				const uniqueGenCategories = [];
				data.forEach(entry => {
					if (!uniqueGenCategoryNames.has(entry.genCategoryName)) {
						uniqueGenCategoryNames.add(entry.genCategoryName);
						uniqueGenCategories.push({
							genCategoryName: entry.genCategoryName
						});
					}
				});
				setGenCategories(uniqueGenCategories);
				setServiceSliderState(true);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});

	}, []);


	const { recentSlider, serviceSlider } = sliders;
	const [hasNotRun, setHasNotRun] = useState(true);
	useLayoutEffect(() => {
		if (recentSliderState && hasNotRun) {
			setHasNotRun(false);
			recentSlider();
		}
	}, [services])
	const [hasNotRun2, setHasNotRun2] = useState(true);
	useLayoutEffect(() => {
		if (serviceSliderState && hasNotRun2) {
			setHasNotRun2(false);
			serviceSlider();
		}
	}, [genCategories])

	const [keywords, setKeywords] = useState('');
	const navigate = useNavigate();
	const handleSearch = (event) => {
		event.preventDefault();
		const category = $('#categorySelect').val();
		navigate(`/search?keywords=${encodeURIComponent(keywords)}&category=${category}`);
	};
	const handleInputChange = (event) => {
		const filteredValue = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
		setKeywords(filteredValue);
	};

	function truncateString(str, maxLength = 100) {
		return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
	}

	function calculateAverageRating(reviews) {
		if (reviews.length > 0) {
			const sumOfRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
			const averageRating = sumOfRatings / reviews.length;
			return averageRating.toFixed(1); // Formatted average
		} else {
			return '';
		}
	}

	const youtubeVideoIds = [
		'3wWNVWbyL8g'
	];
	const getRandomVideoId = () => {
		const randomIndex = Math.floor(Math.random() * youtubeVideoIds.length);
		return youtubeVideoIds[randomIndex];
	};

	return (
		<main>

			<section class="py-5 homepage-search-block position-relative" >
				<div class="container" >
					<div class="row py-lg-2" >
						<div class="col-lg-12" >
							<div class="homepage-search-title">
								<h5 class="mb-3 text-shadow text-gray-900 font-weight-normal">
									Find Worker Bees</h5>
								<h6 class="mb-4 text-shadow text-gray-800 font-weight-normal">Use
									Task Beehive to hire service professionals and make your life easier.</h6>
							</div>
							<div class="homepage-search-form">
								<form class="form-noborder" onSubmit={handleSearch}>
									<div class="form-row">
										<div class="col-lg-3 col-md-3 col-sm-12 form-group">
											<div class="location-dropdown">
												<i class="icofont-location-arrow"></i>
												<select id="categorySelect" name="categorySelect" className="custom-select form-control border-0 shadow-sm form-control-lg">
													<option key="" value="">All</option>
													{categories.map(category => (
														<optgroup key={category.genCategoryName} label={category.genCategoryName}>
															{category.subCategories.map(subCategory => (
																<option key={subCategory.value} value={subCategory.value}>
																	{subCategory.subCategoryName}
																</option>
															))}
														</optgroup>
													))}
												</select>
											</div>
										</div>
										<div class="col-lg-5 col-md-5 col-sm-12 form-group">
											<input type="text" value={keywords} onChange={handleInputChange} placeholder="Find Services..."
												class="form-control border-0 form-control-lg shadow-sm" />
										</div>
										<div class="col-lg-1 col-md-1 col-sm-1 form-group">
											<button type="submit"
												class="btn btn-success btn-block btn-lg btn-gradient shadow-sm">
												<i class="fa fa-search"></i>
											</button>
										</div>
									</div>
								</form>
							</div>

							<div class="popular">
								<span class="text-body-2" style={{ width: '100px' }}>Quick Links</span>
								<ul>
									<li>
										<Link to="/about" class="text-body-2" >
											Sign Up For Free</Link>
									</li>
									<li>
										<Link to="#aboutSection"
											onClick={() => {
												const targetElement = document.getElementById('aboutSection');
												targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
											}} class="text-body-2" >About Task Beehive</Link>
									</li>
									<li>
										<Link to="/referral" class="text-body-2" >
											Referral Program</Link>
									</li>
								</ul>
							</div>
						</div>
						{/*
						<div class="col-lg-4" style={{ textAlign: 'center' }}>
							<img class="img-fluid bees-picture" src="images/bees.png" />
						</div>
						*/}
					</div>
				</div>
			</section>

			{genCategories.length > 0 && (
				<div class="services-wrapper bg-white py-4"><br />
					<div class="container">
						<h6>Popular Professional Services</h6><br />
						<div class="row service-slider">
							{genCategories.map(option => {
								// Modify the genCategoryName
								const modifiedName = option.genCategoryName.replace(/\s/g, '') + '.png';
								return (
									<div class="col">
										<div class="service">
											<Link to={`/categories?genCategory=${option.genCategoryName}`}>
												<img src={`images/categories/icons/icons2/${modifiedName}`}
													style={{ opacity: 1.0 }} />
												<h5>
													{option.genCategoryName}
												</h5>
											</Link>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			)}

			{services.length > 0 && (
				<section class="py-5">
					<div class="view_slider recommended">
						<div class="container">
							<div class="row">
								<div class="col-lg-12">
									<h6>Recently Added Services</h6><br />
									<div class="view recent-slider recommended-slider">
										{services.map(service => (
											<div>
												<Link to={`/serviceDetails?id=${service.id}`}>
													<img class="img-fluid" src={`${service.images[0].imgUrl}`} />
												</Link>
												<div class="inner-slider">
													<div class="inner-wrapper">
														<div class="d-flex align-items-center">
															<span class="seller-image">
																<img class="img-fluid" src={`${service.ownerInfo.images[0].imgUrl}`} />
															</span>
															<span class="seller-name" style={{ height: '52px' }}>
																<Link to={`/serviceDetails?id=${service.id}`}>{truncateString(service.title, 70)}</Link>
																{/*
																<span class="level hint--top level-one-seller">
																	Level 1 Seller
																</span>
																*/}
															</span>
														</div>
														{/*
														<h3>
															<span>{truncateString(service.detail, 100)}</span>
														</h3>
														*/}
														<div class="content-info">
															<div class="rating-wrapper">
																<span class="gig-rating text-body-2">
																	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15" height="15">
																		<path fill="currentColor" d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
																		</path>
																	</svg>
																	{calculateAverageRating(service.reviews)}
																	<span>({service.reviews.length})</span>
																</span>
															</div>
														</div>
														<div class="footer">
															<i class="" aria-hidden="true"></i>
															<div class="price">
																<a href="#">
																	Cost Per Hour <span> ${service.cost}</span>
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)
			}

			<div class="services-wrapper bg-white py-5" id="aboutSection">
				<div class="container">
					<div class="row justify-content-center align-items-center">
						<div class="col-lg-6">
							<div class="text-content justify-content-center align-items-center">
								<h6> About TaskBeehive </h6><br />
								<p style={{ fontSize: "13px" }}>
									<b>Customers</b><br /> Make your everyday life easier by finding people to help you with any task. Learn a new skill, get your work done, or achieve your life goals.
									<br /><br />
									<b>Service Providers</b><br /> Work for yourself, manage your own time, and connect with customers who need your help. Turn your skills or passion into a thriving business.
									<br /><br />
									<b>Businesses</b><br /> Find new customers and help your brand. Create a business page and link providers. View each provider’s earnings and reviews.
								</p>
								<Link to="/about">
									<button class="btn btn-sm btn-success">Learn More About Task Beehive</button>
								</Link>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="p-3" style={{ maxWidth: '560px' }}>
								<div class="video-responsive">
									<iframe
										width="560"
										height="315"
										src={`https://www.youtube.com/embed/${getRandomVideoId()}?rel=0&showinfo=0&autoplay=0&controls=0`}
										frameborder="0"
										allowfullscreen
										title="Embedded YouTube"
									></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div><br /><br /><br />

		</main >
	);
}

export default Home;
