import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

function Orders() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [orders, setOrders] = useState([]);

	useEffect(() => {

		var user_id = localStorage.getItem('user_id');
		const apiUrl = `${baseUrl}/orders/user/` + user_id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const orders = data.map(order => {
					order.availability.sort((a, b) => new Date(a.availableAt) - new Date(b.availableAt));
					return {
						id: order.id,
						dateTime: `${new Date(order.dateTime).toLocaleDateString()} ${new Date(order.dateTime).toLocaleTimeString('en-US', {
							hour: 'numeric',
							minute: 'numeric',
						})}`,
						totalCost: order.totalCost, images: order.taskInfo.images,
						taskId: order.taskInfo.id, taskTitle: order.taskInfo.title,
						cancelled: order.cancelled
					};
				});
				setOrders(orders);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleCancelOrder = () => {
		const apiUrl2 = `${baseUrl}/orders/${currentModalId}`;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const [currentModalId, setCurrentModalId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = (id) => {
		setCurrentModalId(id);
		setIsModalOpen(true)
	};
	const handleCloseModal = () => setIsModalOpen(false);

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<div class="row">
						<div class="col-md-12">
							<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 class="mb-0 p-0">Orders</h2>
								<Link class="btn btn-sm btn-success ml-auto" to="/search?keywords=">Find Services</Link>
							</div>
							<ul class="nav dlevitin-tabs nav-tabs flex-column flex-sm-row ">
								<li class="nav-item">
									<a href="#active" data-toggle="tab" class="nav-link make-black active">
										SERVICE ORDERS
									</a>
								</li>
							</ul>
							<div class="tab-content dlevitin-table bg-white rounded shadow-sm px-3 pt-1">
								<div class="tab-pane active" id="active">
									<div class="table-responsive box-table mt-3">
										<table class="table table-bordered">
											<div style={{ overflowY: 'auto', maxHeight: '600px' }}>
												<thead>
													<tr>
														<th style={{ width: '50%' }}>SERVICE TITLE</th>
														<th style={{ width: '10%' }}>ORDER DATE</th>
														<th style={{ width: '5%' }}>TOTAL</th>
														<th style={{ width: '35%' }}>ACTIONS</th>
													</tr>
												</thead>
												<tbody>
													{orders.length > 0 ? (
														orders.map(order => (
															<tr>
																<td style={{ minWidth: '350px' }}>
																	<Link to={`/serviceDetails?id=${order.taskId}`} class="make-black">
																		<img class="order-proposal-image" src={`${order.images[0].imgUrl}`} />
																		<p class="order-proposal-title">{order.taskTitle}</p>
																	</Link>
																</td>
																<td style={{ minWidth: '100px' }}>{order.dateTime}</td>
																<td>${order.totalCost}</td>
																<td style={{ minWidth: '350px' }}>
																	{order.cancelled ? (
																		<button class="btn btn-sm btn-success">Cancelled</button>
																	) : (
																		<div>
																			<Link to={`/orderDetails?id=${order.id}`}>
																				<button style={{ margin: '3px' }} class="btn btn-sm btn-success">Order Details</button>
																			</Link>
																			<Link to={`/review?orderId=${order.id}`}>
																				<button style={{ margin: '3px' }} class="btn btn-sm btn-success">Review Service</button>
																			</Link>
																			<button style={{ margin: '3px' }} onClick={() => handleOpenModal(order.id)} class="btn btn-sm btn-success">Cancel Order</button>
																		</div>
																	)}
																</td>
															</tr>
														))
													) : (
														<tr>
															<td colSpan="5" >No Services Ordered</td>
															<div></div>
														</tr>
													)}
													<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
														<div style={{ textAlign: 'center' }}>
															<br /><h5>Are you sure you want to cancel order?</h5><br />
															<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCancelOrder()}>Yes, Continue</button>
															<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal()}>No, Go Back</button>
														</div>
													</Modal>
												</tbody>
											</div>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Orders;
