import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import '../static/vendor/slick-master/slick/slick.js';
import customCode from '../static/js/custom.js';
import $ from 'jquery';
import Modal from 'react-modal';

function ServiceDetails() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [serviceDetails, setServiceDetails] = useState();
	const [averageRating, setAverageRating] = useState();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var serviceId = queryParams.get('id');
	var user_id = localStorage.getItem('user_id');
	if (!user_id) { user_id = '' }

	const hasRunRef = useRef(false);
	useEffect(() => {
		if (!hasRunRef.current) {
			const firstImage = $("#serviceImages img").first();
			if (firstImage.length) {
				if (firstImage[0].complete) {
					if (!hasRunRef.current) {
						hasRunRef.current = true;
						customCode();
					}
				} else {
					firstImage.on('load', () => {
						if (!hasRunRef.current) {
							hasRunRef.current = true;
							customCode();
						}
					});
				}
			}
		}
	}, [serviceDetails]);

	// Get services effect
	useEffect(() => {
		var apiUrl = `${baseUrl}/tasks/${serviceId}`;
		if (user_id) {
			apiUrl = apiUrl + `?&userId=${user_id}`;
		}
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setServiceDetails(data);
				const avgRating = data.reviews.length > 0
					? data.reviews.reduce((sum, review) => sum + review.rating, 0) / data.reviews.length
					: undefined;
				const averageRating = avgRating?.toFixed(1) ?? '';
				setAverageRating(averageRating);

				var profile_id = localStorage.getItem('profile_id');
				if (!data.ownerInfo.hasPaid && profile_id && profile_id == data.ownerInfo.id) {
					const apiUrl2 = `${baseUrl}/invoices/lastInvoice/` + data.ownerInfo.id;
					$.ajax({
						url: apiUrl2,
						type: 'GET',
						processData: false,
						contentType: false,
						beforeSend: function(xhr) {
							const token = localStorage.getItem('token');
							xhr.setRequestHeader('Authorization', 'Bearer ' + token);
						},
						success: function(invoice) {
							var invoiceUrl = "/invoice?invoiceId=" + invoice.id;
							var message;
							if (invoice.invoiceType === "Monthly") {
								message = "Your services are inactive. Please settle your invoice to reactivate.";
							} else if (invoice.invoiceType === "Registration") {
								message = "To activate your services, please pay the one-time service provider registration advertising fee.";
							}
							$("#alertText").html(`
						            <span style="font-weight: bold; margin-right: 20px;">${message}</span>
						            <a href="${invoiceUrl}">
						                <button style="" class="btn btn-sm btn-warning">View Invoice</button>
						            </a>
						        `);
							$("#message").html($("#alert-template").clone().removeAttr("style"));
						},
						error: function(msg) {
							if (msg.responseText) {
								$("#alertText").html(msg.responseText);
								$("#message").html($("#alert-template").clone().removeAttr("style"));
							}
						}
					});
				}
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	const handleContactSeller = (event) => {
		event.preventDefault();
		const indexed_array = {};
		var user_id = localStorage.getItem('user_id');
		if (!user_id) {
			navigate('/messages');
		}

		var user = { "id": user_id };
		indexed_array["fromUser"] = user;
		var to_user = { "id": serviceDetails.ownerInfo.userInfo.id };
		indexed_array["toUser"] = to_user;
		indexed_array["message"] = "Intro: Hello, I have a question about your service.";

		const apiUrl = `${baseUrl}/messages`;
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function() {
				navigate('/messages');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	// Favorites
	const handleFavoriteClick = (taskId, event) => {
		event.preventDefault();
		var user_id = localStorage.getItem('user_id');

		const element = $(`#heart-icon-${taskId}`);
		const isFavorite = element.hasClass('fa-solid');
		if (!isFavorite) {
			element.removeClass('fa-duotone').addClass('fa-solid');
			var indexed_array = {};
			var user = { "id": user_id };
			indexed_array["user"] = user;

			var task_id = taskId;
			var task = { "id": task_id };
			indexed_array["task"] = task;

			const apiUrl = `${baseUrl}/favorites`
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
				},
				error: function(msg) {
				}
			})
		} else {
			element.removeClass('fa-solid').addClass('fa-duotone');
			const apiUrl = `${baseUrl}/favorites/${user_id}/${taskId}`
			$.ajax({
				url: apiUrl,
				type: 'DELETE',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
				},
				error: function(msg) {
				}
			})
		}
	};

	// Report
	const handleReportClick = (taskId, event) => {
		event.preventDefault();

		var indexed_array = {};
		var user_id = localStorage.getItem('user_id');
		indexed_array["userId"] = user_id;
		indexed_array["entityId"] = taskId;
		indexed_array["reason"] = $('#reason').val();
		indexed_array["type"] = "SERVICE";

		const apiUrl = `${baseUrl}/reports`
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(msg) {
				setIsModalOpen(false);
				$("#alertText").html("Your report has been succesfully sent!");
				$(".alert").removeClass("alert-warning").addClass("alert-success");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
			},
			error: function(msg) {
				setIsModalOpen(false);
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$(".alert").removeClass("alert-success").addClass("alert-warning");
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	};

	function formatDate(isoDateString) {
		const date = new Date(isoDateString);
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true
		};
		return date.toLocaleTimeString('en-US', options);
	}

	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = (event) => {
		event.preventDefault(); // Prevent the default event action
		setIsModalOpen(true); // Then set the state
	};
	const handleCloseModal = (event) => {
		event.preventDefault(); // Prevent the default event action
		setIsModalOpen(false); // Then set the state
	};

	return (
		<div class="main-page py-5">

			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>

				{serviceDetails && (
					<div class="row">
						<div class="col-lg-8 left mb-5 details-section">
							<h3>{serviceDetails.title}
								<Link onClick={(event) => handleFavoriteClick(serviceDetails.id, event)}
									style={{ marginLeft: '10px' }}>
									<i className={serviceDetails.favorite ? "fa-solid fa-heart" : "fa-duotone fa-heart"} id={`heart-icon-${serviceDetails.id}`}></i>
								</Link>
							</h3>
							<div id="overview" style={{ marginLeft: '20px' }} class="seller-overview d-flex align-items-center">
								<div class="user-profile-image d-flex">
									<div class="profile-name">
										<span class="user-status" style={{ fontSize: '14px' }}>
											<b>Service Provider</b>
										</span>
										<div class="seller-level" style={{ marginLeft: '7px' }}> {serviceDetails.ownerInfo.userInfo.name.length > 25
											? serviceDetails.ownerInfo.userInfo.name.substring(0, 25) + '..'
											: serviceDetails.ownerInfo.userInfo.name}
										</div>
									</div>
									<div class='right-grey-divider' />
								</div>

								<div class="user-info d-flex">
									<span class="d-flex">
										<span class="">
											<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15" height="15">
												<path fill="#ffc100" d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
												</path>
											</svg> {averageRating}
											<Link to="#"
												onClick={(event) => {
													event.preventDefault();
													const targetElement = document.getElementById('reviews');
													targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
													const newUrl = `${window.location.pathname}${window.location.search}`;
													window.history.pushState({}, '', newUrl);
												}} class="text-body-2" > <span class="user-info-rating orders-in-queue"> ({serviceDetails.reviews.length} User Reviews)</span></Link>
										</span>
									</span>
									{user_id &&
										<div>
											<span class='right-grey-divider' />
											<span>
												<Link class="orders-in-queue" onClick={(event) => handleOpenModal(event)}>Report</Link>
											</span>
										</div>
									}
									<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '700px', height: '350px', margin: 'auto' } }}>
										<div style={{ textAlign: 'center' }}>
											<form>
												<br /><h5>Report This Service:</h5>
												<br /><span> Task Beehive will review the service and remove it if we find that the service violates our policy. Please do not file false reports as it can lead to suspension of your account.</span><br />
												<br /><h6>Reason For Reporting:</h6>
												<textarea maxLength="2500" required name="reason" id="reason" class="form-control"
													rows="4"
													placeholder="Provide details about the reported service issue"></textarea><br />
												<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={(event) => handleReportClick(serviceDetails.id, event)}>Send Report</button>
												<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={(event) => handleCloseModal(event)}>Cancel</button>
											</form>
										</div>
									</Modal>
								</div>
							</div>

							<div class="slider mt-4" id="serviceImages">
								<div id="service-images" class="slider-for slick-slider-single">
									{!serviceDetails.images[0].imgUrl &&
										<a style={{ maxHeight: "440px", objectFit: "contain", width: "auto" }} >
											<img class="img-fluid" src="images/processing.png" />
										</a>
									}
									{serviceDetails.images[0].imgUrl &&
										<a style={{ maxHeight: "440px", objectFit: "contain", width: "auto" }} >
											<img class="img-fluid" src={`${serviceDetails.images[0].imgUrl}`} />
										</a>
									}
									{serviceDetails.images[1] && serviceDetails.images[1].imgUrl &&
										<a style={{ maxHeight: "440px", objectFit: "contain", width: "auto" }} >
											<img class="img-fluid" src={`${serviceDetails.images[1].imgUrl}`} />
										</a>
									}
									{serviceDetails.images[2] && serviceDetails.images[2].imgUrl &&
										<a style={{ maxHeight: "440px", objectFit: "contain", width: "auto" }} >
											<img class="img-fluid" src={`${serviceDetails.images[2].imgUrl}`} />
										</a>
									}
								</div>
							</div>

							<div id="description" class="profile-card">
								<h5 style={{ fontSize: '1.15rem' }}>Service Details</h5><br />
								<div class="user-profile-image d-flex">
									<p>{serviceDetails.detail}</p>
								</div>
							</div>
						</div>

						<div class="col-lg-4 mb-5 right order-section">
							<div class="profile-card">
								<div class="sticky">
									<div class="tab-content">
										<div id="basic" class="tab-pane fade show active">
											<div class="header">
												<h3><b class="title">Cost</b><span class="price">${serviceDetails.cost} / Hour</span></h3>
												<p>{serviceDetails.about}
												</p>
											</div>

											<Link class="fit-button" to={`/order?serviceId=${serviceDetails.id}`}>
												<button className="btn btn-success">Order Now</button>
											</Link>
										</div>
									</div>
									<div class="contact-seller-wrapper">
										<Link class="fit-button" to="#" onClick={handleContactSeller}>Contact Service Provider</Link>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-8 left mb-5 service-provider-info">
							<div class="profile-card">
								<h5 style={{ fontSize: '1.15rem' }} id="aboutSeller">Service Provider Info</h5><br />
								<div class="user-profile-image d-flex">
									<label class="profile-pict" for="profile_image">
										<img class="img-fluid" src={`${serviceDetails.ownerInfo.images[0].imgUrl}`} />
									</label>
									<div class="right">
										<div class="profile-name">
											<span class="user-status">
												<b>Service Provider</b>
											</span>
											<div class="seller-level">{serviceDetails.ownerInfo.userInfo.name} </div>
										</div>
										<div class="user-info">
											<span class="total-rating-out-five">{averageRating} </span>
											<span class="total-rating"> ({serviceDetails.reviews.length} reviews)</span>
										</div>
										<br />
										<Link to={`/userDetails?id=${serviceDetails.ownerInfo.id}`}>
											<button class="btn btn-sm btn-success">View Service Provider</button>
										</Link>
									</div>
								</div>
							</div>

							<br />
							<div class="profile-card">
								<div class="user-profile-image">
									<h6>About</h6>
									{serviceDetails.ownerInfo.about}
								</div>
							</div>
						</div>
						{serviceDetails.businessInfo &&
							<div class="col-lg-8 left mb-5 business-info">

								<div class="profile-card">
									<h5 style={{ fontSize: '1.15rem' }} id="aboutSeller">Business Info</h5><br />
									<div class="user-profile-image d-flex">
										<label class="profile-pict" for="profile_image">
											<img class="img-fluid" src={`${serviceDetails.businessInfo.images[0].imgUrl}`} />
										</label>
										<div class="right">
											<div class="profile-name">
												<span class="user-status">
													<b>Business</b>
												</span>
												<div class="seller-level">{serviceDetails.businessInfo.name} <br />
													<span><strong>Email</strong> {serviceDetails.businessInfo.email ? serviceDetails.businessInfo.email : 'Not Available'}</span>
													<span class='right-grey-divider' />
													<span ><strong>Phone</strong> {serviceDetails.businessInfo.phone ? serviceDetails.businessInfo.phone : 'Not Available'}</span>
												</div>
											</div>
											<div class="user-info">
												<span>{serviceDetails.businessInfo.overview}</span>
											</div><br />
											<Link to={`/businessDetails?id=${serviceDetails.businessInfo.id}`}>
												<button class="btn btn-sm btn-success">View Business</button>
											</Link>
										</div>
									</div>
								</div>
							</div>
						}

						<div class="col-lg-8 left mb-5 reviews-section">
							<div class="profile-card">
								<label id="reviews" class="profile-pict" for="profile_image">
									<h5 style={{ fontSize: '1.15rem' }} class="m-0"> {serviceDetails.reviews.length} Reviews </h5>
								</label><br /><br />

								<div class="user-profile-image d-flex">
									<div class="review-list" style={{ width: '100%' }}>
										<ul>
											{serviceDetails.reviews.slice(0, 8).map((review, index) => (
												<li>
													{index > 0 && <hr />}
													<div class="d-flex">
														<div class="right">
															<h4>
																{review.reviewerName}
																<span class="gig-rating text-body-2">
																	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15" height="15">
																		<path fill="currentColor" d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
																		</path>
																	</svg>
																	{review.rating}.0
																</span>
															</h4>
															<div class="review-description">
																<p>
																	{review.comment}
																</p>
															</div>
															<span class="publish py-3 d-inline-block w-100">{formatDate(review.dateTime)}</span>
														</div>
													</div>
												</li>
											))}
										</ul>
										{serviceDetails.reviews.length === 0 && <p>No reviews for this service yet.</p>}
									</div>
								</div>
							</div>
						</div>

					</div>
				)}
			</div>
		</div>
	);
}

export default ServiceDetails;