import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function RequestPayout() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	useEffect(() => {
		$("#target").off('submit');
		$("#target").submit(function(event) {
			event.preventDefault();

			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});

			const apiUrl = `${baseUrl}/affiliates`;
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(response) {
					if (response.message) {
						$("#alertText").html(response.message);
						$(".alert").removeClass("alert-warning").addClass("alert-success");
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$(".alert").removeClass("alert-success").addClass("alert-warning");
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		});
	}, []);

	return (
		<main>
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>
				<div class="row justify-content-center align-items-center py-4">
					<div class="col-md-10">
						<div class="shadow-sm rounded bg-white mb-3">
							<div class="box-body p-3" >
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 section-title text-center mb-7">
										<h5 class="font-weight-bold mt-4">Request A Payout</h5>
										<p class="text-muted">Payout for the affiliate partner program.</p>

									</div>
								</div>
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 section-title text-center mb-7">
										<b>Note</b>: Your check will be sent to the name and address in your TaskBeehive account. If you need to edit either go to the top right user icon and click 'Edit User Profile'.
										<br/><br/><br/>Please confirm your email and password to request a payout!
									</div>
								</div><br />
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 mb-7">
										<form action="index" id="target">
											<div class="form-group">
												<div class="controls">
													<label>Email Address <small class="text-danger">*</small></label>
													<input type="email" maxlength="250" placeholder="youremail@gmail.com"
														class="form-control" id="email" name="email" required
														data-validation-required-message="Please enter your email address." />
												</div>
											</div>
											<div class="form-group">
												<label>Password <small class="text-danger">*</small></label> <input
													type="password" maxlength="250" placeholder="password" class="form-control"
													id="password" name="password" required
													data-validation-required-message="Please enter your password." />
												<p class="help-block"></p>
											</div>
											<div class="text-right">
												<button type="submit" class="btn btn-success"
													id="sendMessageButton">Submit Payout Request</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main >
	);
}

export default RequestPayout;
