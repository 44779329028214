import React, { useEffect } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function UserAgreement() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	return (
		<main>
			<br /> <br />
			<div class="container">
				<div class="box mb-3 shadow-sm rounded bg-white profile-box">
					<div class="box-body p-5">
						<br />
						<div style={{ textAlign: 'center' }}><h2>Task Beehive LLC User Agreement <small>(updated May 19, 2024)</small></h2></div><br />
						<br />
						<p>This User Agreement constitutes a legally binding agreement between you and Task Beehive LLC. ( “Task Beehive” ) governing your use of TaskBeehive Website, and related services, information and communications, here in after referred to as Task Beehive Platform</p>
						<p><b>IF YOU DO NOT AGREE TO BE BOUND BY THIS AGREEMENT AND ABIDE BY ITS TERMS, YOU MAY NOT USE OR ACCESS TASK BEEHIVE PLATFORM</b></p>
						<br />
						<h4>1. Introduction </h4>
						<p> Task Beehive Platform provides a way for Users to find Service Providers to complete a service for them. </p>
						<p> Upon your agreement to hire a Service Provider to perform a service, you form a binding contract between yourself and a Service Provider</p>
						<br />
						<h4>2. User/Clients/Service Providers </h4>
						<p> User or Client - An individual or an entity registered through Task Beehive LLC to obtain services from a Service Provider.</p>
						<p> Service Providers - Independent contractors of their clients and are not employees, business partners, representatives, or agents of Task Beehive LLC. </p>
						<p> Users employ Service Providers and upon the agreement have a binding contract with Service Providers only. There is no binding contract created between the User and Task Beehive LLC or Service Provider and Task Beehive LLC. </p>
						<p> The relationship between Users and Service Providers on the Task Beehive Platform is that of an independent contractor. This means that Service Providers are not employees, agents, or representatives of Task Beehive, LLC. As such, Service Providers are responsible for their own work schedules, tax withholdings, insurance, and adherence to local, state, and federal regulations pertinent to their services. Users, in turn, engage with Service Providers at their own risk, acknowledging that Task Beehive, LLC does not supervise, direct, control, or monitor a Service Provider's work and is not responsible for the work performed or the process in which it is completed.</p>
						<br />
						<h4>3. Requirements </h4>
						<p>a. Users must be at least 16 years of age and be able to enter into binding contracts. A "binding contract" refers to an agreement between two or more parties that is enforceable by law. To form a binding contract, there must be an offer, acceptance, consideration, and mutual intent to be bound. </p>
						<p>b. Users must honor their commitments to Service Providers on the websites including by promptly responding, being at the job site as necessary, and providing timely payment to the Service Providers as agreed upon. </p>
						<p>c. Users must honor their availability and scheduled appointments. </p>
						<p>d. Users must honor their payment or fee requirements to Task Beehive LLC. There is currently no registration fee for Users, but that is liable to change at anytime at Task Beehive LLC's discretion.  </p>
						<p>e. Users must provide their own payments to the Service Provider through the means agreed upon beforehand. Through the messaging platform or direct communication the Users and Service Providers can agree for payment before or after the job at their own discretion. </p>
						<p>f. Users are responsible for any content that they post or upload through Task Beehive LLC. Any User content is the property of the User. </p>
						<p>g. The ability of Task Beehive LLC to enforce binding contracts may vary by jurisdiction. Users are responsible for ensuring that their use of the Task Beehive Platform complies with local laws and regulations. The platform is designed to be compliant with the laws of the State of Massachusetts, USA, but it is the User's responsibility to ensure that entering into a contract via Task Beehive is legally binding in their jurisdiction.</p>
						<br />
						<h4>4. Consent </h4>
						<p>By creating an account you give Task Beehive authorization to send you emails relating to your Task Beehive activity </p>
						<br />
						<h4>5. Code of Conduct </h4>
						<p>a. Users may not use Offensive, harmful, and/or abusive language, including without limitation: expletives, profanities, obscenities, harassment, vulgarities, sexually explicit language and hate speech (e.g., racist/discriminatory speech.) in their description page or in any communication with Users, Service Providers or Task Beehive LLC employees. This includes but is not limited to any communication in-person, via Task Beehive LLC chat system, or email </p>
						<p>b. Users may not upload Offensive, harmful, and/or abusive content including anything sexually explicit or any nudity </p>
						<p>c. Users may not engage in any activity that would be against the local, state, or federal law </p>
						<p>d. Users may not use Task Beehive Platform for the purchase or delivery of alcohol, tobacco, or any other controlled or illegal substances or services </p>
						<p>e. Users may not use Task Beehive Platform for the purchase or delivery of weapons </p>
						<p>f. Users may not use Task Beehive Platform for any reason other than to solicit Service Providers for the completion of their task and for communication with the Service Providers about their task. That includes that Users may not use Task Beehive platform to harrass or threaten.</p>
						<p>g. Users are fully responsible and retain full ownership of any content that is uploaded to Task Beehive LLC website database or servers. </p>
						<p>h. Task Beehive LLC will have the final authority on what is and isn't appropriate on the site. Task Beehive LLC may remove or modify any content at it's discretion. </p>
						<br />
						<h4>6. Limit on Liability </h4>
						<p>a. Task Beehive LLC does not endorse any Users and provides no warranty about the worksite conditions, scope of work required, or the Client's ability to pay. </p>
						<p>b. Task Beehive LLC does not endorse any Service Providers and provides no warranty about their knowledge, skills, and time to perform the required task. </p>
						<p>c. Users are solely responsible for determining and checking whether the Service Provider they hired has the knowledge, skills, and time to perform the required task. </p>
						<p>d. Users agree to indemnify Task Beehive LLC from any and all type of damages caused by the Service Provider's work. This includes but is not limited to indemnify Task Beehive LLC against all claims, liabilities, damages, losses, costs, expenses, and fees that may arise as a result of or in connection with the Service Provider's work or actions.</p>
						<p>e. Users are solely responsible for determining whether the Service Providers have the necessary licenses and insurance as necessary </p>
						<p>f. Upon agreement the binding contract exists between the Service Provider and the User and it's up to the User to make sure it contains all the relevant terms. It is also up to the User to enforce it.</p>
						<p>g. Task Beehive LLC provides no vetting of its users, hence Task Beehive LLC is not liable for any erroneous information contained in the User's profile, including the description of the jobsite, scope of work, and any other information not mentioned. </p>
						<p>h. Users agree to indemnify Task Beehive LLC from any damage caused by it's review system including negative impact on reputation and negative image. Task Beehive LLC will consider all available information and is a final authority on the legitimacy of the review in it's review section. </p>
						<p>i. Users are responsible for their services, actions, and any liabilities arising from their solicitation of work, and agree to indemnify Task Beehive LLC from any claims regarding this. </p>
						<p>j. Task Beehive LLC, can help at their discretion, but is in no way responsible for collecting refunds from the Service Providers if the need arises. Users agree to indemnify Task Beehive LLC. from any payment dispute with the Service Provider. </p>
						<p>k. Task Beehive LLC is not responsible for any stage of the Client/Service Provider negotiation </p>
						<br />
						<h4>7. EULA </h4>
						<p>1. The code, layout, and other elements of Task Beehive website/platform are copyrighted.</p>
						<p>2. Users cannot use REST API without permission.</p>
						<p>3. Task Beehive Platform can only be used as intended, any other use not specifically allowed is prohibited.</p>
						<br />
						<h4>8. Legal Disputes </h4>
						<p> Governing Law: This Agreement shall be governed by and construed in accordance with the laws of the State of Massachusetts, without giving effect to any choice or conflict of law provision or rule (either of the State of Massachussets or any other jurisdiction) that would cause the application of the laws of any jurisdiction other than the State of Massachussets. </p>
						<p> Arbitration: All disputes arising out of or in connection with this User Agreement or the use of the Task Beehive Platform shall be resolved through arbitration. Task Beehive LLC retains sole discretion to determine the specifics of the arbitration process, including but not limited to the selection of the arbitrator, the rules governing the arbitration, and any other procedural aspects. The arbitration will be binding, and the decision rendered by the arbitrator shall be final and enforceable in any court of competent jurisdiction. </p>
						<br />
						<h4>9. Severability </h4>
						<p> If any clause, or portion of a clause, in this Agreement is considered invalid under the rule of law, it shall be regarded as stricken while the remainder of this Agreement shall continue to be in full effect. </p>
						<br />
						<h4>10. Demonstration Data Disclaimer </h4>
						<p>TaskBeehive may use test data such as, but not limited to, service providers, user profiles, and reviews for demonstration, test, and any other purposes it chooses. These test profiles and services are created to illustrate how the platform functions and appear to users, service providers, potential investors, and other parties that may want to learn about the platform. These demonstration profiles are non-functional, with no availability in their calendars, and cannot be ordered. By agreeing to this User Agreement, you acknowledge the presence of this test data and agree to indemnify, defend, and hold harmless TaskBeehive and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses arising out of or in any way connected with these test profiles. You agree that TaskBeehive is not engaging in any deceptive practices or malfeasance through the use of these test profiles. The sole purpose of these demonstrations is to provide a visual and functional representation of how the platform operates. You hereby waive any and all rights to bring any form of legal claim against TaskBeehive related to the use of these test profiles and demonstration data.</p>
						<br />
						<h4>11. Promotional Terms </h4>
						<p>During our free registration promotional period, you can join TaskBeehive as a service provider without incurring any registration fees. This promotional offer allows you to experience our platform at no cost. However, please note that TaskBeehive reserves the right to modify or terminate this and any other promotional offers at any time, at our sole discretion. TaskBeehive retains full discretion over any alterations, extensions, or discontinuations of such offers without prior notice. By agreeing to these terms, you acknowledge and accept that promotions are subject to change at any time and at the sole discretion of TaskBeehive. After the promotional period, standard registration fees may apply. All promotions are subject to change, and TaskBeehive retains full discretion over any alterations or discontinuations of such offers. You hereby waive any right to challenge, dispute, or claim any damages arising from the modification or termination of any promotional offers.</p>
						<br />
					</div>
				</div>
			</div>
		</main >
	);
}

export default UserAgreement;
