import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';

function Messages() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [newMessage, setNewMessage] = useState();
	const [selectedUserId, setSelectedUserId] = useState();
	const [selectedUserName, setSelectedUserName] = useState();
	const [userConversations, setUserConversations] = useState([]);

	useEffect(() => {
		function method() {
			const user_id = localStorage.getItem('user_id');
			const apiUrl = `${baseUrl}/messages/conversations/${user_id}`;
			$.ajax({
				url: apiUrl,
				type: 'GET',
				processData: false,
				contentType: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
					if (data && data.length > 0) {
						const users = data.map(entry => ({ name: entry.name, id: entry.id }));
						setUserConversations(users);

						if (!selectedUserId) {
							setSelectedUserId(users[0].id);
							setSelectedUserName(users[0].name);
						} else {
							let selectedUserFound = false;
							for (let user of users) {
								if (user.id === selectedUserId) {
									selectedUserFound = true;
									break;
								}
							}

							if (!selectedUserFound) {
								setSelectedUserId(users[0].id);
								setSelectedUserName(users[0].name);
							}
						}
					}
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		}
		method();
		const intervalId = setInterval(method, 30000);
		return () => clearInterval(intervalId);
	}, [selectedUserId]);

	const [messages, setMessages] = useState([]);
	useEffect(() => {
		if (!selectedUserId) {
			return;
		}
		function method() {
			const user_id = localStorage.getItem('user_id');
			const apiUrl = `${baseUrl}/messages/conversation/${user_id}/${selectedUserId}`;
			$.ajax({
				url: apiUrl,
				type: 'GET',
				processData: false,
				contentType: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
					const messages = data.map(entry => {
						const [sender, message] = entry.message.split('|');
						return { sender: sender.trim(), message: message.trim(), timestamp: formatDate(entry.timestamp) };
					});
					setMessages(messages);
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		}
		method();
		const intervalId = setInterval(method, 30000);
		return () => clearInterval(intervalId);
	}, [selectedUserId, newMessage]);

	function formatDate(timestamp) {
		const dateObj = new Date(timestamp);
		const year = dateObj.getFullYear();
		const month = String(dateObj.getMonth() + 1).padStart(2, '0');
		const day = String(dateObj.getDate()).padStart(2, '0');
		const hours = String(dateObj.getHours()).padStart(2, '0');
		const minutes = String(dateObj.getMinutes()).padStart(2, '0');
		return `${year}/${month}/${day} ${hours}:${minutes}`;
	}

	useEffect(() => {
		const $target = $("#target");
		$target.off('submit');
		$target.submit(function(event) {
			event.preventDefault();

			if (!selectedUserId) {
				return;
			}

			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});

			var message = indexed_array["message"];
			if (!message) {
				return;
			}

			var user_id = localStorage.getItem('user_id');
			var user = { "id": user_id };
			indexed_array["fromUser"] = user;

			var to_user = { "id": selectedUserId };
			indexed_array["toUser"] = to_user;

			const apiUrl = `${baseUrl}/messages`;
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function() {
					$('textarea#message').val('');
					setNewMessage(message);
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		});
	}, [selectedUserId]);

	useEffect(() => {
		var chatBox = $('.dlevitin-chat-box');
		chatBox.scrollTop(chatBox.prop('scrollHeight'));
	}, [messages]);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = () => setIsModalOpen(true);
	const handleCloseModal = () => setIsModalOpen(false);

	const handleDeleteConversation = () => {
		if (!selectedUserId) {
			return;
		}
		const user_id = localStorage.getItem('user_id');
		const apiUrl2 = `${baseUrl}/messages/conversation?` + 'from=' + selectedUserId + "&to=" + user_id;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	return (
		<div class="py-5">
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>

				<div class="row">
					<main class="col col-xl-12 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
						<div class="box shadow-sm rounded bg-white mb-3 dlevitin-chat">
							<h5 class="pl-3 pt-3 pr-3 border-bottom mb-0 pb-3">Messaging</h5>
							<div class="row m-0">
								<div class="border-right col-lg-5 col-xl-4 px-0">
									<div class="p-3 d-flex align-items-center border-bottom dlevitin-post-header overflow-hidden">
										<div class="font-weight-bold mr-1 overflow-hidden">Contacts:</div>
									</div>
									<div class="dlevitin-chat-left">
										<div class="dlevitin-chat-list">
											{userConversations.map(u => (
												<div class="p-3 d-flex align-items-center border-bottom dlevitin-post-header overflow-hidden"
													onClick={() => {
														setSelectedUserId(u.id);
														setSelectedUserName(u.name);
													}}>
													<div class="mr-3"><i class="fa-thin fa-user fa-lg"></i></div>
													<div class="font-weight-bold mr-1 overflow-hidden">
														<div class="text-truncate">{u.name}</div>
														<div class="small text-truncate overflow-hidden text-black-50"><i class="mdi mdi-check text-primary"></i> Click to see messages</div>
													</div>
													<span class="ml-auto mb-auto">
														<div class="text-right text-muted pt-1 small"></div>
													</span>
												</div>
											))}
										</div>
									</div>
								</div>
								<div class="col-lg-7 col-xl-8 px-0">
									<div class="p-3 d-flex align-items-center  border-bottom -post-header">
										{selectedUserName &&
											<div class="font-weight-bold mr-1 overflow-hidden">
												<div class="text-truncate">
													{selectedUserName}
												</div>
												<div class="small text-truncate overflow-hidden text-black-50">User Name</div>
											</div>
										}
										<span class="ml-auto">
											{/*
											<button type="button" class="btn btn-light btn-sm rounded">
												<i class="mdi mdi-phone"></i>
											</button>
											*/}
											<div class="btn-group">
												<button type="button" class="btn rounded"
													data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													<i class="fa-solid fa-ellipsis-vertical fa-margin-right"></i> Menu
												</button>
												<div class="dropdown-menu dropdown-menu-right">
													<button class="dropdown-item" onClick={handleOpenModal} type="button">
														<i class="fa-sharp fa-thin fa-trash fa-margin-right"></i> Delete Conversation
													</button>
													<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
														<div style={{ textAlign: 'center' }}>
															<br /><h5>Are you sure you want to delete the conversation permanently?</h5><br />
															<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleDeleteConversation()}>Yes, Continue</button>
															<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal()}>No, Go Back</button>
														</div>
													</Modal>
												</div>
											</div>
										</span>
									</div>
									<div class="dlevitin-chat-box p-3 border-top border-bottom bg-light">
										{messages.map(m => (
											<div class="d-flex align-items-center dlevitin-post-header">
												<div class="mr-1">
													<div class="text-truncate h6 mb-3">{m.sender}
													</div>
													<p> {m.message}</p>
												</div>
												<span class="ml-auto mb-auto">
													<div class="text-right text-muted pt-1 small">{m.timestamp}</div>
												</span>
											</div>
										))}
									</div>
									<form id="target">
										<div class="w-100 border-top border-bottom">
											<textarea maxlength="2500" id="message" name="message" placeholder="Write a message…" class="form-control border-0 p-3 shadow-none" rows="2" />
										</div>
										<div class="p-3 d-flex align-items-center">
											<span class="ml-auto">
												<button style={{ margin: '5px' }} type="submit" class="btn btn-success">
													Send Message
												</button>
											</span>
										</div>
									</form>
								</div>
							</div>
						</div>
					</main>
				</div>
			</div>
		</div>
	);
}

export default Messages;
