import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function EmailConfirmed({ userId, setUserId, profileId, setProfileId }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var userId = queryParams.get('id');

	const [user, setUser] = useState();

	useEffect(() => {
		if (!userId) {
			$("#alertText").html("User ID not found in query parameters.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			return;
		}

		const apiUrl = `${baseUrl}/users/` + userId;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(user) {
				user.emailConfirmed = true;
				const apiUrl = `${baseUrl}/users/${userId}`;
				$.ajax({
					url: apiUrl,
					type: 'PUT',
					data: JSON.stringify(user),
					contentType: 'application/json; charset=utf-8',
					dataType: 'json',
					async: false,
					beforeSend: function(xhr) {
						const token = localStorage.getItem('token');
						xhr.setRequestHeader('Authorization', 'Bearer ' + token);
					},
					success: function(msg) {
						setUser(user);
					},
					error: function(msg) {
						if (msg.responseText) {
							$("#alertText").html(msg.responseText);
							$("#message").html($("#alert-template").clone().removeAttr("style"));
						}
					}
				});
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	return (
		<main>
			<div class="">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					{user && (
						<div class="row justify-content-center align-items-center d-flex vh-80">
							<div class="col-lg-8">
								<div class="dlevitin-login py-4">
									<div class="col-md-30">
										<div class="shadow-sm rounded bg-white mb-3">
											<div class="box-body p-5" >
												<div class="text-center mb-4">
													<h5 class="font-weight-bold mt-3">Email Confirmed</h5>
													<p class="text-muted">Thank you for verifying your email, {user.name}.</p>
												</div>
												<div>
													<div class="text-center">
														<p>
															You have successfully confirmed your email. You can close this page and continue your registration.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

				</div>
			</div>
		</main>
	);
}

export default EmailConfirmed;
