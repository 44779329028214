import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import '../static/css/mark-your-calendar.css'
import '../static/js/mark-your-calendar.js';
import sliders from '../static/js/custom2.js'

function EditService() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [categories, setCategories] = useState([]);
	const [service, setService] = useState();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var service_id = queryParams.get('id');

	// Load in costs
	const hoursPerService = [];
	for (let i = 2; i <= 168; i = i + 1) {
		hoursPerService.push({ value: i, label: i });
	}

	// Get services effect
	useEffect(() => {
		const apiUrl = `${baseUrl}/tasks/${service_id}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setService(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [categories])

	useEffect(() => {
		$("#repeatWeekly").prop("checked", true);
		const apiUrl3 = `${baseUrl}/categories`;
		$.ajax({
			url: apiUrl3,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const groupedCategories = data.reduce((acc, entry) => {
					if (acc[entry.genCategoryName]) {
						acc[entry.genCategoryName].subCategories.push({
							value: entry.id,
							subCategoryName: entry.subCategoryName
						});
					} else {
						acc[entry.genCategoryName] = {
							genCategoryName: entry.genCategoryName,
							subCategories: [{ value: entry.id, subCategoryName: entry.subCategoryName }]
						};
					}
					return acc;
				}, {});
				const categories = Object.values(groupedCategories);
				categories.forEach(category => {
					category.subCategories.sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName));
				});
				categories.sort((a, b) => a.genCategoryName.localeCompare(b.genCategoryName));
				setCategories(categories);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, []);

	const [hasNotRun, setHasNotRun] = useState(true);
	useLayoutEffect(() => {
		if (categories.length > 0 && hasNotRun) {
			setHasNotRun(false);
			sliders.loadSelect();
			$('#categorySelect').on('change', handleChange);
		}
	}, [service])

	// Load in costs
	const costs = [];
	for (let i = 15; i <= 1000; i = i + 5) {
		costs.push({ value: i, label: i });
	}

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSubmitting(true);

		var unindexed_array = $('#target :input[name!="file"]').serializeArray();
		var indexed_array = {};
		$.map(unindexed_array, function(n, i) {
			indexed_array[n['name']] = n['value'];
		});

		var profile_id = localStorage.getItem('profile_id');
		var profile = { "id": profile_id };
		indexed_array["profile"] = profile;

		var category_id = $('#categorySelect').val();
		var category = { "id": category_id };
		indexed_array["category"] = category;

		if (uploadedImages.length > 0) {
			indexed_array["images"] = uploadedImages.map((img) => ({
				fileName: img.fileName,
				contentType: img.contentType,
				imgType: 'service',
				imgUrl: img.imgUrl,
			}));
		}

		const apiUrl2 = `${baseUrl}/tasks/${service_id}`;
		$.ajax({
			url: apiUrl2,
			type: 'PUT',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(task) {
				navigate("/services");
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
				setIsSubmitting(false);
			}
		});
	};

	const handleChange = (event) => {
		if (event.target.name == 'categorySelect') {
			service.categoryInfo.id = event.target.value;
		} else {
			setService({ ...service, [event.target.name]: event.target.value });
		}
	};

	/**
	 * Image Uploading
	 */
	const compressImage = async (file) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};

		try {
			const compressedBlob = await imageCompression(file, options);

			// Convert the compressed Blob back to a File object
			const compressedFile = new File(
				[compressedBlob],
				`${uuidv4()}.${file.type.split('/')[1]}`,
				{ type: file.type, lastModified: Date.now() }
			);

			return compressedFile;
		} catch (error) {
			console.error('Error compressing the image:', error);
			throw error;
		}
	};

	const getSignedUrl = async (fileName, contentType) => {
		const url = new URL(`${baseUrl}/image/generateSignedUrl`);
		url.searchParams.append('fileName', fileName);
		url.searchParams.append('contentType', contentType);
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
		const data = await response.text();
		return data;
	};

	const uploadToGCS = async (file, signedUrl) => {
		await fetch(signedUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': file.type,
			},
			body: file,
		});
	};

	const [uploadedImages, setUploadedImages] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleFileChange = async (event) => {
		setIsSubmitting(true);
		const files = Array.from(event.target.files);
		if (files.length > 3) {
			$("#alertText").html("Maximum of 3 images is allowed. Please re-upload the images.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			return;
		}
		const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));

		const uploadedFiles = await Promise.all(
			compressedFiles.map(async (file) => {
				const contentType = file.type;
				const fileName = file.name;
				const signedUrl = await getSignedUrl(fileName, contentType);

				await uploadToGCS(file, signedUrl);

				return {
					fileName,
					contentType,
					imgUrl: signedUrl.split('?')[0],
				};
			})
		);
		setUploadedImages(uploadedFiles);
		setIsSubmitting(false);
	};

	return (
		<main>
			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					{service && (
						<form id="target" onSubmit={handleSubmit}>
							<div class="row">

								<aside class="col-md-5">
									<div
										class="box mb-3 shadow-sm rounded bg-white profile-box text-center">
										<div class="py-4 px-3 border-bottom">
											<h6 class="m-0">Update Service</h6>
										</div>
										<div class="p-3">
											<h6 class="m-0">
												Update Service Pictures
											</h6>
											<p class="mb-0 mt-0 small">Multi-select up to 3 images</p>
											<br /> <input type="file" class="" name="file"
												id="file" accept="image/*" multiple="multiple" onChange={handleFileChange} />
										</div>
									</div>
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">Service Offer Details</h6>
											<p class="mb-0 mt-0 small">What the customer expects to recieve, and payment options.</p>
										</div>
										<div class="box-body">
											<div class="p-3">
												<div class="form-group mb-4">
													<label class="mb-1">Offer Details</label> <span
														class="text-danger">*</span>
													<div class="position-relative">
														<textarea maxlength="2500" required name="about" value={service.about} onChange={handleChange} class="form-control"
															rows="4"
															placeholder="What the customer expects to recieve, and payment options"></textarea>
													</div>
												</div>
											</div>
										</div>
									</div>
								</aside>
								<main class="col-md-7">
									<div class="shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">Offered Service Basic Info</h6>
											<p class="mb-0 mt-0 small">Basic information for service
												you are providing.</p>
										</div>
										<div class="box-body p-3">
											<div class="js-form-message">
												<label id="titleOfService" class="form-label"> Title
													Of Service <span class="text-danger">*</span>
												</label>
												<div class="form-group">
													<input required class="form-control" type="text" maxlength="500"
														name="title" value={service.title} onChange={handleChange}
														placeholder="Enter the title of service"
														aria-label="Enter the title of service"
														aria-describedby="titleOfService"
														data-msg="Please enter a valid title of service"
														data-error-class="u-has-error"
														data-success-class="u-has-success" />
												</div>
											</div>
											<div class="form-group mb-4">
												<label class="mb-1">Details Of Service</label> <span
													class="text-danger">*</span>
												<div class="position-relative">
													<textarea maxlength="2500" required name="detail" value={service.detail} onChange={handleChange} class="form-control"
														rows="4"
														placeholder="Enter the details of the service provided"></textarea>
												</div>
											</div>
											<div class="row">
												<div class="col-sm-6 mb-2">
													<div class="form-group mb-4">
														<label class="mb-1">Cost Per Hour Of Work</label> <span
															class="text-danger">*</span>
														<select id="cost" name="cost" value={service.cost} onChange={handleChange}
															class="form-control custom-select" required
															data-msg="Select cost per hour of work."
															data-error-class="u-has-error"
															data-success-class="u-has-success">
															<option value="">Select cost per hour</option>
															{costs.map(option => (
																<option key={option.value} value={option.value}>
																	${option.label}
																</option>
															))}
														</select>
													</div>
												</div>

												<div class="col-sm-6 mb-2">
													<div class="form-group mb-4">
														<div class="form-group">
															<label class="mb-1">Service Category</label> <span
																class="text-danger">*</span>
															<select id="categorySelect" name="categorySelect" value={service.categoryInfo.id} onChange={handleChange}
																class="form-control custom-select" required
																data-msg="Please select category."
																data-error-class="u-has-error"
																data-success-class="u-has-success">
																<option value="">Select Category</option>
																{categories.map(category => (
																	<optgroup key={category.genCategoryName} label={category.genCategoryName}>
																		{category.subCategories.map(subCategory => (
																			<option key={subCategory.value} value={subCategory.value}>
																				{subCategory.subCategoryName}
																			</option>
																		))}
																	</optgroup>
																))}
															</select>
														</div>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-sm-12 mb-2">
													<div class="form-group mb-4">
														<label class="mb-1">Minimum Hours Customer Can Book Per Order</label> <span
															class="text-danger">*</span><br/>
														<select style={{ maxWidth: '48%' }} id="minimumHours" name="minimumHours" value={service.minimumHours} onChange={handleChange}
															class="form-control custom-select" required
															data-msg="Select cost per hour of work."
															data-error-class="u-has-error"
															data-success-class="u-has-success">
															<option value="1">1 hour</option>
															{hoursPerService.map(option => (
																<option key={option.value} value={option.value}>
																	{option.label} hours
																</option>
															))}
														</select><br />
													</div>
												</div>

											</div>
										</div>
									</div>

									<div class="mb-3 text-right">
										<button type="submit" disabled={isSubmitting} class="btn btn-success">Update Service</button>
									</div>
								</main>
							</div>
						</form>
					)}
				</div>
			</div>

		</main>
	);
}

export default EditService;
