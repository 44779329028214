import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

function AdminReportedServices() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [tasks, setTasks] = useState([]);

	useEffect(() => {

		const apiUrl = `${baseUrl}/tasks/reported`;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const tasks = data.map(task => ({
					id: task.id,
					images: task.images,
					title: task.title,
					cancelled: task.cancelled,
					reports: task.reports,
					ownerInfo: task.ownerInfo
				}));
				setTasks(tasks);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleDeleteTask = () => {
		const apiUrl2 = `${baseUrl}/tasks/${currentModalId}`;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const handleDeleteUser = () => {
		const apiUrl2 = `${baseUrl}/users/updateEnabled/${currentModalId2}?enabled=false`;
		$.ajax({
			url: apiUrl2,
			type: 'PUT',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const handleDeleteReport = () => {
		const apiUrl2 = `${baseUrl}/reports/${currentModalId3}`;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const [currentModalId, setCurrentModalId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = (id) => {
		setCurrentModalId(id);
		setIsModalOpen(true)
	};
	const handleCloseModal = () => setIsModalOpen(false);

	const [currentModalId2, setCurrentModalId2] = useState(null);
	const [isModalOpen2, setIsModalOpen2] = useState(false);
	const handleOpenModal2 = (id) => {
		setCurrentModalId2(id);
		setIsModalOpen2(true)
	};
	const handleCloseModal2 = () => setIsModalOpen2(false);

	const [currentModalId3, setCurrentModalId3] = useState(null);
	const [isModalOpen3, setIsModalOpen3] = useState(false);
	const handleOpenModal3 = (id) => {
		setCurrentModalId3(id);
		setIsModalOpen3(true)
	};
	const handleCloseModal3 = () => setIsModalOpen3(false);

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 class="mb-0 p-0">Reported Services</h2>
							</div>
							<ul class="nav dlevitin-tabs nav-tabs flex-column flex-sm-row ">
								<li class="nav-item">
									<a href="#active" data-toggle="tab" class="nav-link make-black active">
										ALL SERVICES
									</a>
								</li>
							</ul>
							<div class="tab-content dlevitin-table bg-white rounded shadow-sm px-3 pt-1">
								<div class="tab-pane active" id="active">
									<div class="table-responsive box-table mt-3">
										<table class="table table-bordered">
											<thead>
												<tr>
													<th>SERVICE TITLE AND REPORTS</th>
													<th>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{tasks.length > 0 ? (
													tasks.map(task => (
														<tr>
															<td style={{ minWidth: '200px' }}>
																<Link to={`/serviceDetails?id=${task.id}`} class="make-black">
																	{task.images[0].imgUrl &&
																		<img class="order-proposal-image" src={`${task.images[0].imgUrl}`} />
																	}
																	<p class="order-proposal-title">{task.title}</p>
																</Link>
																{task.reports.map(report => (
																	<div>
																		<br />Report Id: {report.id}
																		<br />Report Reason: {report.reason}
																		<br />Reporter Id: {report.userId}
																		<br /><button onClick={() => handleOpenModal3(report.id)} class="btn btn-sm btn-success">Delete Report</button>
																	</div>
																))}
															</td>
															<td style={{ minWidth: '200px' }}>
																<div>
																	<Link to={`/serviceDetails?id=${task.id}`}>
																		<button style={{ margin: '3px' }} class="btn btn-sm btn-success">View Service</button>
																	</Link>
																	<button style={{ margin: '3px' }} onClick={() => handleOpenModal(task.id)} class="btn btn-sm btn-success">Disable Service</button>
																	<button style={{ margin: '3px' }} onClick={() => handleOpenModal2(task.ownerInfo.userInfo.id)} class="btn btn-sm btn-success">Disable Service User</button>
																</div>
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="5">No Services Reported</td>
													</tr>
												)}
												<Modal isOpen={isModalOpen2} class="cancelModal" onRequestClose={handleCloseModal2} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
													<div style={{ textAlign: 'center' }}>
														<br /><h5>Are you sure you want to disable service user?</h5><br />
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleDeleteUser()}>Yes, Continue</button>
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal2()}>No, Go Back</button>
													</div>
												</Modal>
												<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
													<div style={{ textAlign: 'center' }}>
														<br /><h5>Are you sure you want to disable service?</h5><br />
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleDeleteTask()}>Yes, Continue</button>
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal()}>No, Go Back</button>
													</div>
												</Modal>
												<Modal isOpen={isModalOpen3} class="cancelModal" onRequestClose={handleCloseModal3} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
													<div style={{ textAlign: 'center' }}>
														<br /><h5>Are you sure you want to delete this report?</h5><br />
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleDeleteReport()}>Yes, Continue</button>
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal3()}>No, Go Back</button>
													</div>
												</Modal>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<br />
				</div>
			</div>
		</main>

	);
}

export default AdminReportedServices;
