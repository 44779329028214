import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Review() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var orderId = queryParams.get('orderId');

	const [order, setOrder] = useState();

	// Get services effect
	useEffect(() => {
		const apiUrl = `${baseUrl}/orders/${orderId}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setOrder(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])

	useEffect(() => {
		if (!order) {
			return;
		}

		$("#target").off('submit');
		$("#target").submit(function(event) {
			event.preventDefault();

			var unindexed_array = $('#target').serializeArray();
			var indexed_array = {};
			$.map(unindexed_array, function(n, i) {
				indexed_array[n['name']] = n['value'];
			});

			var user_id = localStorage.getItem('user_id');
			var user = { "id": user_id };
			indexed_array["user"] = user;

			var task = { "id": order.taskInfo.id };
			indexed_array["task"] = task;

			indexed_array["orderId"] = order.id;

			const apiUrl = `${baseUrl}/reviews`;
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(msg) {
					navigate('/orders');
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		});
	}, [order])

	return (
		<main>
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>

				<div class="row justify-content-center align-items-center py-4">
					<div class="col-md-10">
						<div class="shadow-sm rounded bg-white mb-3">
							<div class="box-body p-3" >
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 section-title text-center mb-7">
										<h5 class="font-weight-bold mt-4">Review Service</h5>
										<p class="text-muted">Review order you received from service provider.</p>
									</div>
								</div>
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 mb-7">
										<form action="index" id="target">
											{order && (
												<div>
													<div class="form-group">
														<label><b>Order #</b>: </label><br />
														<label>{order.id}</label>
													</div>
													<div class="form-group">
														<label><b>Service</b>: </label><br />
														<label>{order.taskInfo.title}</label>
													</div>
												</div>
											)}
											<div class="form-group">
												<label><b>Rating</b> <small class="text-danger">*</small></label>
												<br />
												<div className="custom-control custom-radio custom-control-inline">
													{[5, 4, 3, 2, 1].map((star) => (
														<div key={star} className="custom-control custom-radio custom-control-inline">
															<input type="radio" id={`radio${star}`} name="rating" className="custom-control-input" value={star} />
															<label className="custom-control-label" htmlFor={`radio${star}`} style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
																{Array(star).fill().map((_, i) => (
																	<i key={i} className="fa-thin fa-star" style={{ marginLeft: '5px', fontSize: '1.2em' }}></i>
																))}
															</label>
														</div>
													))}
												</div>
											</div>
											<div class="form-group">
												<label>Message <small class="text-danger">*</small></label>
												<textarea placeholder="Write review for order you received from service provider ..." rows="4"
													cols="100" class="form-control" id="comment" name="comment" required
													data-validation-required-message="Please enter your comment"
													maxlength="500"></textarea>
											</div>

											<div class="text-right">
												<button type="submit" class="btn btn-success"
													id="sendMessageButton">Review Service</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		</main >
	);
}

export default Review;
