import $ from 'jquery';
function customCode() {
	"use strict";
	$("body").on("contextmenu", function(e) {
		return false;
	});

	$('select').select2();
	$('[data-toggle="tooltip"]').tooltip();

	$('.recent-slider').slick({
		slidesToShow: 4,
		slidesToScroll: 3,
		arrows: true,
		fade: false,
		responsive: [{
			breakpoint: 1200,
			settings: {
				slidesToShow: 3,
			}
		}, {
			breakpoint: 960,
			settings: {
				slidesToShow: 2,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
			}
		}]
	});

	$('.freelance-slider').slick({
		slidesToShow: 4,
		slidesToScroll: 3,
		arrows: true,
		fade: false,
		responsive: [{
			breakpoint: 1099,
			settings: {
				slidesToShow: 4,
			}
		}, {
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
			}
		}]
	});

	$('.service-slider').slick({
		slidesToShow: 11,
		slidesToScroll: 3,
		arrows: true,
		fade: false,
		responsive: [{
			breakpoint: 1200,
			settings: {
				slidesToShow: 9,
			}
		}, {
			breakpoint: 1025,
			settings: {
				slidesToShow: 8,
			}
		}, {
			breakpoint: 825,
			settings: {
				slidesToShow: 7,
			}
		}, {
			breakpoint: 700,
			settings: {
				slidesToShow: 6,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 5,
			}
		}, {
			breakpoint: 500,
			settings: {
				slidesToShow: 4,
			}
		}, {
			breakpoint: 400,
			settings: {
				slidesToShow: 3,
			}
		}]
	});

	$('.slider-for').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		adaptiveHeight: true
	});

	$('.recommend').slick({
		slidesToShow: 2,
		slidesToScroll: 3,
		arrows: true,
		fade: false,
		responsive: [{
			breakpoint: 767,
			settings: {
				slidesToShow: 1,
			}
		}]
	});
	$(".view").not('.slick-initialized').slick({
		slidesToShow: 4,
		slidesToScroll: 3,
		arrows: true,
		fade: false,
		responsive: [{
			breakpoint: 1099,
			settings: {
				slidesToShow: 4,
			}
		}, {
			breakpoint: 1024,
			settings: {
				slidesToShow: 2,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
			}
		}]
	});
	$('.slider-nav').slick({
		slidesToShow: 4,
		slidesToScroll: 3,
		asNavFor: '.slider-for',
		dots: false,
		arrows: true,
		focusOnSelect: true,
		variableWidth: true,
		responsive: [{
			breakpoint: 1099,
			settings: {
				slidesToShow: 4,
			}
		}, {
			breakpoint: 1024,
			settings: {
				slidesToShow: 3,
			}
		}, {
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
			}
		}]
	});
};
export default customCode;