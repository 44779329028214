import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Earnings() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [orders, setOrders] = useState([]);
	const [totalCash, setTotalCash] = useState([0]);
	const [startDate, setStartDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().slice(0, 10) + 'T00:00:00Z');
	const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10) + 'T23:59:59Z');

	var profile_id = localStorage.getItem('profile_id');
	var user_id = localStorage.getItem('user_id');

	useEffect(() => {
		const apiUrl = `${baseUrl}/orders/serviceProvider/${profile_id}?startDate=${startDate}&endDate=${endDate}`;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const orders = data.map(order => ({
					id: order.id,
					dateTime: `${new Date(order.dateTime).toLocaleDateString()} ${new Date(order.dateTime).toLocaleTimeString('en-US', {
						hour: 'numeric',
						minute: 'numeric',
					})}`,
					totalCost: order.totalCost, images: order.taskInfo.images,
					taskId: order.taskInfo.id, taskTitle: order.taskInfo.title,
					cancelled: order.cancelled
				}));
				let totalCash = 0;
				orders.forEach(order => {
					totalCash += order.totalCost;
				});
				setTotalCash(totalCash);
				setOrders(orders);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [startDate, endDate])

	const [services, setServices] = useState([]);
	const [reviews, setReviews] = useState([]);
	const [averageRating, setAverageRating] = useState();
	const [profile, setProfile] = useState();

	useEffect(() => {
		const apiUrl = `${baseUrl}/tasks/user/` + user_id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(services) {
				setServices(services);

				var allReviews = [];
				$.each(services, function(index, service) {
					allReviews.push(...service.reviews);
				});
				setReviews(allReviews);

				const avgRating = allReviews.length > 0
					? allReviews.reduce((sum, review) => sum + review.rating, 0) / allReviews.length
					: undefined;
				const averageRating = avgRating?.toFixed(1) ?? 'No Reviews';
				setAverageRating(averageRating);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	useEffect(() => {
		const apiUrl = `${baseUrl}/profiles/` + profile_id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(profile) {
				setProfile(profile);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleStartDateChange = (e) => {
		try {
		setStartDate(new Date(e.target.value).toISOString().slice(0, 10) + 'T00:00:00Z');
		} catch (error) {}
	};

	const handleEndDateChange = (e) => {
		try {
		setEndDate(new Date(e.target.value).toISOString().slice(0, 10) + 'T23:59:59Z');
		} catch (error) {}
	};

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div class="row  justify-content-center align-items-center">
						<div class="col-md-8">
							<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 class="mb-0 p-0">Service Provider Earnings</h2>
							</div>

							{profile &&
								<div class="profile_info">
									<div class="seller-card">
										<div>
											<div class="user-profile-info">
												<div>
													<div class="user-profile-image">
														<img src={`${profile.images[0].imgUrl}`} class="img-fluid user-pict-img" alt="Askbootstrap" style={{ width: "35%" }} />
													</div>
												</div>
												<div class="user-profile-label">
													<div class="username-line">
														<a href="#" class="seller-link">{profile.userInfo.name}</a>
													</div>
													<div class="oneliner-wrapper">
														<small class="oneliner">Professional Service Provider</small>
														<div class="ratings-wrapper">
															<p class="rating-text"><strong>{averageRating}</strong> ({reviews.length} reviews)</p>
														</div>
													</div>
												</div>
											</div>
											<div class="user-stats-desc"><br />
												<div class="row justify-content-center">
													<div class="col-sm-5 mb-2">
														<label htmlFor="start-date" className="form-label">Start Date</label>
														<input
															type="date"
															id="start-date"
															className="form-control"
															value={startDate.slice(0, 10)}
															onChange={handleStartDateChange}
														/>
													</div>
													<div class="col-sm-5 mb-2">
														<label htmlFor="end-date" className="form-label">End Date</label>
														<input
															type="date"
															id="end-date"
															className="form-control"
															value={endDate.slice(0, 10)}
															onChange={handleEndDateChange}
														/>
													</div>
												</div>
											</div>
											<div class="user-stats-desc">
												<ul class="user-stats">
													<li>Number Of Customer Orders
														<strong>{orders.length}</strong></li>
													<li>Total Earned
														<strong>${totalCash}</strong></li>
												</ul>
											</div>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Earnings;
