import React, { useState, useEffect, useLayoutEffect } from 'react'
import $ from 'jquery'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import sliders from '../static/js/custom2.js'

function Search() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	// Paging state
	const [services, setServices] = useState([]);
	const initialPage = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
	const [currentPage, setCurrentPage] = useState(initialPage);

	const [servicesPerPage, setServicesPerPage] = useState(12);
	const [totalCount, setTotalCount] = useState(0);

	// Categories state
	const [categories, setCategories] = useState([]);

	// Search filters
	const [category, setCategory] = useState('');
	const [categoryLocked, setCategoryLocked] = useState(false);
	const [distance, setDistance] = useState(50);
	const [distanceLocked, setDistanceLocked] = useState(false);
	const [keywords, setKeywords] = useState('');

	//Handle query params
	var categoryQuery = queryParams.get('category');
	if (categoryQuery && category != categoryQuery && !categoryLocked) {
		setCategory(categoryQuery);
	}
	var distanceQuery = queryParams.get('distance');
	if (distanceQuery && distance != distanceQuery && !distanceLocked) {
		setDistance(distanceQuery);
	}
	var keywordsQuery = queryParams.get('keywords');
	if (keywords != keywordsQuery) {
		setKeywords(keywordsQuery);
	}
	var user_id = localStorage.getItem('user_id');
	if (!user_id) { user_id = '' }

	// Categories effect
	useEffect(() => {
		const apiUrl3 = `${baseUrl}/categories`;
		$.ajax({
			url: apiUrl3,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const groupedCategories = data.reduce((acc, entry) => {
					if (acc[entry.genCategoryName]) {
						acc[entry.genCategoryName].subCategories.push({
							value: entry.id,
							subCategoryName: entry.subCategoryName
						});
					} else {
						acc[entry.genCategoryName] = {
							genCategoryName: entry.genCategoryName,
							subCategories: [{ value: entry.id, subCategoryName: entry.subCategoryName }]
						};
					}
					return acc;
				}, {});
				const categories = Object.values(groupedCategories);
				categories.forEach(category => {
					category.subCategories.sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName));
				});
				categories.sort((a, b) => a.genCategoryName.localeCompare(b.genCategoryName));
				setCategories(categories);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const [hasNotRun, setHasNotRun] = useState(true);
	useLayoutEffect(() => {
		if (categories.length > 0 && hasNotRun) {
			setHasNotRun(false);
			sliders.loadSelect();
			$('#categorySelect').on('change', handleCategoryChange);
		}
	}, [categories])

	// Get services effect
	useEffect(() => {
		const apiUrl = `${baseUrl}/tasks?keywords=${keywords}&category=${category}&distance=${distance}&userId=${user_id}&page=${currentPage - 1}&limit=${servicesPerPage}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setServices(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [keywords, distance, category, currentPage, servicesPerPage])

	// Services count
	useEffect(() => {
		const apiUrl = `${baseUrl}/tasks/count?keywords=${keywords}&category=${category}&distance=${distance}&userId=${user_id}`;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				if (totalCount != data && totalCount != 0) {
					setCurrentPage(1);
				}
				setTotalCount(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [keywords, distance, category]);

	// Pagination
	const [pagesToShow, setPagesToShow] = useState(4);
	useEffect(() => {
		const handleResize = () => {
			if ($(window).width() < 576) {
				setPagesToShow(2);
			} else {
				setPagesToShow(3);
			}
		};
		$(window).on('resize', handleResize);
		handleResize();
		return () => $(window).off('resize', handleResize);
	}, []);

	const totalPages = Math.ceil(totalCount / servicesPerPage);
	const pageNumbers = [];
	const startPage = Math.max(1, currentPage - pagesToShow);
	const endPage = Math.min(totalPages, currentPage + pagesToShow);
	for (let i = startPage; i <= endPage; i++) {
		pageNumbers.push(i);
	}
	const paginate = (pageNumber) => {
		setCurrentPage(pageNumber);
		window.scrollTo({ top: 0, behavior: 'smooth' });
		const url = new URL(window.location);
		url.searchParams.set('page', pageNumber);
		window.history.pushState({}, '', url);

		clearFavoriteIcons();
	};

	const clearFavoriteIcons = () => {
		$('[id^=heart-icon-]').each(function() {
			const element = $(this);
			if (element.hasClass('fa-solid')) {
				element.removeClass('fa-solid').addClass('fa-duotone');
			}
		});
	};

	// Filter Change
	const handleCategoryChange = (event) => {
		setCategoryLocked(true);
		setCategory(event.target.value);

		const url = new URL(window.location);
		url.searchParams.set('category', event.target.value);
		window.history.pushState({}, '', url);
	}
	const distances = [];
	for (let i = 5; i <= 100; i = i + 5) {
		distances.push({ value: i, label: i });
	}
	const handleDistanceChange = (event) => {
		setDistanceLocked(true);
		setDistance(event.target.value);

		const url = new URL(window.location);
		url.searchParams.set('distance', event.target.value);
		window.history.pushState({}, '', url);
	}

	// Favorites
	const handleFavoriteClick = (taskId, event) => {
		event.preventDefault();
		var user_id = localStorage.getItem('user_id');

		const element = $(`#heart-icon-${taskId}`);
		if (element.hasClass('fa-duotone')) {
			element.removeClass('fa-duotone').addClass('fa-solid');

			var indexed_array = {};
			var user = { "id": user_id };
			indexed_array["user"] = user;

			var task_id = taskId;
			var task = { "id": task_id };
			indexed_array["task"] = task;

			const apiUrl = `${baseUrl}/favorites`
			$.ajax({
				url: apiUrl,
				type: 'POST',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
				},
				error: function(msg) {
				}
			})
		} else {
			element.removeClass('fa-solid').addClass('fa-duotone');
			const apiUrl = `${baseUrl}/favorites/${user_id}/${taskId}`
			$.ajax({
				url: apiUrl,
				type: 'DELETE',
				data: JSON.stringify(indexed_array),
				contentType: 'application/json; charset=utf-8',
				dataType: 'json',
				async: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
				},
				error: function(msg) {
				}
			})
		}
	};

	function truncateString(str, maxLength = 100) {
		return str.length > maxLength ? str.substring(0, maxLength) + "..." : str;
	}

	function calculateAverageRating(reviews) {
		if (reviews.length > 0) {
			const sumOfRatings = reviews.reduce((sum, review) => sum + review.rating, 0);
			const averageRating = sumOfRatings / reviews.length;
			return averageRating.toFixed(1); // Formatted average
		} else {
			return '';
		}
	}

	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<main>
			<div id="alert-template" style={{ display: 'none' }}><br />
				<div class="alert alert-warning alert-dismissible" role="alert">
					<div id="alertText"></div>
					<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
				</div>
			</div>
			<div id="message"></div>

			<section class="py-5 p-list-two">
				<div class="container">
					<div class="row">
						<div class="col-lg-3">
							<div class="filters shadow-sm rounded bg-white mb-4">
								<div class="filters-header border-bottom pl-4 pr-4 pt-3 pb-3">
									<h5 class="m-0">Filter By</h5>
								</div>
								<div class="filters-body">
									<div id="accordion">
										<div class="filters-card border-bottom p-4">
											<div class="filters-card-header" id="headingOne">
												<h6 class="mb-0">
													<a href="#" class="btn-link">
														Search Words <i class="mdi mdi-chevron-down float-right"></i>
													</a>
												</h6>
												{keywords ? keywords : 'none'}
											</div>
										</div>
										<div class="filters-card border-bottom p-4">
											<div class="filters-card-header" id="headingOne">
												<h6 class="mb-0">
													<a href="#" class="btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
														Categories <i class="mdi mdi-chevron-down float-right"></i>
													</a>
												</h6>
											</div>
											<br />
											<div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
												<select
													id="categorySelect"
													name="categorySelect"
													value={category}
													onChange={handleCategoryChange}
													className="custom-select form-control border-0 shadow-sm form-control-lg"
												>
													<option key="" value="">All</option>
													{categories.map(category => (
														<optgroup key={category.genCategoryName} label={category.genCategoryName}>
															{category.subCategories.map(subCategory => (
																<option key={subCategory.value} value={subCategory.value}>
																	{subCategory.subCategoryName}
																</option>
															))}
														</optgroup>
													))}
												</select>
											</div>
										</div>
										<div class="filters-card border-bottom p-4">
											<div class="filters-card-header" id="headingTwo">
												<h6 class="mb-0">
													<a href="#" class="btn-link" data-toggle="collapse" data-target="#collapsetwo" aria-expanded="true" aria-controls="collapsetwo">
														Distance <i class="mdi mdi-chevron-down float-right"></i>
													</a>
												</h6>
											</div>
											<br />
											<div id="collapsetwo" class="collapse show" aria-labelledby="headingTwo" data-parent="#accordion">
												<select id="categorySelect" name="categorySelect"
													value={distance} onChange={handleDistanceChange}
													class="custom-select form-control border-0 shadow-sm form-control-lg">
													<option key="" value="">Any Distance</option>
													{distances.map(option => (
														<option key={option.value} value={option.value}>
															{option.label} Miles
														</option>
													))}
												</select>
											</div>
										</div>

									</div>
								</div>
							</div>
						</div>
						<div class="col-lg-9 view_slider recommended">
							<div class="row">
								<div class="col-lg-12">
									<div class="sorting-div d-flex align-items-center justify-content-between">
										<p class="mb-2">{totalCount} Services available</p>
										<div class="sorting d-flex align-items-center">
											<p>Sortby</p>
											<select class="custom-select custom-select-sm border-0 shadow-sm ml-2">
												<option>Newest Arrivals</option>
											</select>
										</div>
									</div>
									<h3>Services With Selected Filters</h3>
								</div>
							</div>
							<div class="row">
								{services.map(service => (
									<div className={`col-12 col-sm-6 ${screenWidth < 1500 ? 'col-md-4' : 'col-md-3'}`}>
										<Link to={`/serviceDetails?id=${service.id}`}>
											<img class="img-fluid" src={`${service.images[0].imgUrl}`} />
										</Link>
										<div class="inner-slider">
											<div class="inner-wrapper">
												<div class="d-flex align-items-center">
													<span class="seller-image">
														<img class="img-fluid" src={`${service.ownerInfo.images[0].imgUrl}`} />
													</span>
													<span class="seller-name" >
														<Link to={`/serviceDetails?id=${service.id}`}>{truncateString(service.title, 70)}</Link>
														{/*
														<span class="level hint--top level-one-seller">
															Level 1 Seller
														</span>
														*/}
													</span>
												</div>
												{/*
												<h3>
													<span>{truncateString(service.detail, 100)}</span>
												</h3>
												*/}
												<div class="content-info">
													<div class="rating-wrapper">
														<span class="gig-rating text-body-2">
															<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" width="15" height="15">
																<path fill="currentColor" d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z">
																</path>
															</svg>
															{calculateAverageRating(service.reviews)}
															<span>({service.reviews.length})</span>
														</span>
													</div>
												</div>
												<div class="footer">
													<Link onClick={(event) => handleFavoriteClick(service.id, event)} >
														<i className={service.favorite ? "fa-solid fa-heart fa-lg" : "fa-duotone fa-heart fa-lg"} id={`heart-icon-${service.id}`}></i>
													</Link>
													<div class="price">
														<a href="#">
															Cost Per Hour <span> ${service.cost}</span>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								))}
							</div>

							<div id="pagination">
								<nav aria-label="Page navigation example">
									<ul className="pagination justify-content-center">
										<li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
											<a
												className="page-link"
												href="#"
												onClick={(e) => { e.preventDefault(); paginate(Math.max(1, currentPage - 1)); }}
												tabIndex="-1"
											>
												Previous
											</a>
										</li>
										{pageNumbers.map((number) => (
											<li
												className={number === currentPage ? "page-item active" : "page-item"}
												key={number}
											>
												<a
													className="page-link"
													href="#"
													onClick={(e) => { e.preventDefault(); paginate(number); }}
												>
													{number}
												</a>
											</li>
										))}
										<li className={(currentPage >= totalPages || totalCount === 0) ? "page-item disabled" : "page-item"}>
											<a
												className="page-link"
												href="#"
												onClick={(e) => { e.preventDefault(); paginate(Math.min(totalPages, currentPage + 1)); }}
											>
												Next
											</a>
										</li>
									</ul>
								</nav>
							</div>


						</div>
					</div>
				</div>
			</section>
		</main>
	);
}

export default Search;
