import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';

function CreateBusiness({ hasBusiness, setHasBusiness }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSubmitting(true);

		var unindexed_array = $('#target :input[name!="file"]').serializeArray();
		var indexed_array = {};
		$.map(unindexed_array, function(n, i) {
			indexed_array[n['name']] = n['value'];
		});

		var user_id = localStorage.getItem('user_id');
		var user = { "id": user_id };
		indexed_array["owner"] = user;

		var serviceProviderIds = [];
		$('#serviceProviderIds li').each(function() {
			const taskId = $(this).attr('data-task-id');
			if (taskId) {
				serviceProviderIds.push(taskId);
			}
		});
		indexed_array["tasks"] = serviceProviderIds.map(id => ({ id: id }));
		indexed_array["percentageProfits"] = 50;

		if (uploadedImages.length === 0) {
			$("#alertText").html("Wait for images to upload before submiting. Try again in 5 seconds.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			setIsSubmitting(false);
			return;
		}
		if (uploadedImages.length > 0) {
			indexed_array["images"] = uploadedImages.map((img) => ({
				fileName: img.fileName,
				contentType: img.contentType,
				imgType: 'business',
				imgUrl: img.imgUrl,
			}));
		}

		const apiUrl2 = `${baseUrl}/businesses`;
		$.ajax({
			url: apiUrl2,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(msg) {
				localStorage.setItem('has_business', true);
				setHasBusiness(true);
				navigate('/businesses');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
				setIsSubmitting(false);
			}
		});
	};

	const [services, setServices] = useState([]);
	useEffect(() => {
		$("#lookupServiceProvider").click(function(event) {
			event.preventDefault();
			const newEmail = $("#serviceProviderEmail").val().trim();
			if (newEmail) {
				const apiUrl = `${baseUrl}/tasks/user/byEmail/` + newEmail;
				$.ajax({
					url: apiUrl,
					type: 'GET',
					processData: false,
					contentType: false,
					beforeSend: function(xhr) {
						const token = localStorage.getItem('token');
						xhr.setRequestHeader('Authorization', 'Bearer ' + token);
					},
					success: function(services) {
						setServices(services);
					},
					error: function(msg) {
						if (msg.responseText) {
							$("#alertText").html(msg.responseText);
							$("#message").html($("#alert-template").clone().removeAttr("style"));
						}
					}
				});
			}
		});

		$("ul").on("click", ".list-item-task-id", function() {
			$(this).remove();
		});
	}, []);

	useEffect(() => {
		$("#addServiceProvider").click(function(event) {
			event.preventDefault();
			const selectedServiceId = $('input[name="serviceSelection"]:checked').val();
			const selectedService = services.find(service => service.id === selectedServiceId);

			if (selectedService && $("#serviceProviderIds li[data-task-id='" + selectedServiceId + "']").length === 0) {
				// Include the service title and owner's email in the list item
				const newItem = $(`<li class='list-group-item list-item-task-id' data-task-id='${selectedServiceId}'>`)
					.text(`${selectedService.title} - ${selectedService.ownerInfo.userInfo.email}`);
				$("#serviceProviderIds").append(newItem);
			}

			$("#serviceProviderEmail").val("");
			setServices([]);
		});
	}, [services]);

	/**
	 * Image Uploading
	 */
	const compressImage = async (file) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};

		try {
			const compressedBlob = await imageCompression(file, options);

			// Convert the compressed Blob back to a File object
			const compressedFile = new File(
				[compressedBlob],
				`${uuidv4()}.${file.type.split('/')[1]}`,
				{ type: file.type, lastModified: Date.now() }
			);

			return compressedFile;
		} catch (error) {
			console.error('Error compressing the image:', error);
			throw error;
		}
	};

	const getSignedUrl = async (fileName, contentType) => {
		const url = new URL(`${baseUrl}/image/generateSignedUrl`);
		url.searchParams.append('fileName', fileName);
		url.searchParams.append('contentType', contentType);
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
		const data = await response.text();
		return data;
	};

	const uploadToGCS = async (file, signedUrl) => {
		await fetch(signedUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': file.type,
			},
			body: file,
		});
	};

	const [uploadedImages, setUploadedImages] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleFileChange = async (event) => {
		setIsSubmitting(true);
		const files = Array.from(event.target.files);
		const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));

		const uploadedFiles = await Promise.all(
			compressedFiles.map(async (file) => {
				const contentType = file.type;
				const fileName = file.name;
				const signedUrl = await getSignedUrl(fileName, contentType);

				await uploadToGCS(file, signedUrl);

				return {
					fileName,
					contentType,
					imgUrl: signedUrl.split('?')[0],
				};
			})
		);
		setUploadedImages(uploadedFiles);
		setIsSubmitting(false);
	};

	return (
		<main>

			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>
					<form id="target" onSubmit={handleSubmit}>
						<div class="row">

							<aside class="col-md-5">
								<div
									class="box mb-3 shadow-sm rounded bg-white profile-box text-center">
									<div class="py-4 px-3 border-bottom">
										<h6 class="m-0">Create A Business Page</h6>
									</div>
									<div class="p-3">
										<h6 class="m-0">
											Business Picture Or Logo <span class="text-danger">*</span>
										</h6>
										<br /> <input required type="file" class="" name="file"
											id="file" accept="image/*" onChange={handleFileChange} />
									</div>
								</div>
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">About</h6>
										<p class="mb-0 mt-0 small">
											Information about your company.
											Founding date, number of employees, experience and expertise, location, licenses, etc.</p>
									</div>
									<div class="box-body">
										<div class="p-3">
											<div class="form-group mb-4">
												<label class="mb-1">About The Business</label> <span
													class="text-danger">*</span>
												<div class="position-relative">
													<textarea maxlength="2500" required name="about" class="form-control"
														rows="4"
														placeholder="Enter info about your business. "></textarea>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* Business Contact Info Section */}
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Business Contact Info</h6>
										<p class="mb-0 mt-0 small">Optional business contact info that will be given to customers.</p>
									</div>
									<div class="box-body p-3">
										<div class="form-group">
											<label class="mb-1">Business Email</label>
											<input type="email" class="form-control" id="email" name="email" />
										</div>
										<div class="form-group mb-4">
											<label class="mb-1">Business Phone</label>
											<input type="text" class="form-control" id="phone" name="phone" />
										</div>
									</div>
								</div>

							</aside>
							<main class="col-md-7">
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Business Info</h6>
										<p class="mb-0 mt-0 small">Basic information for business page.</p>
									</div>
									<div class="box-body p-3">
										<div class="js-form-message">
											<label id="businessName" class="form-label">
												Business Name </label><span class="text-danger"> *</span>
											<div class="form-group">
												<input required class="form-control" type="text" maxlength="250"
													name="name" placeholder="Enter your business name"
													aria-label="Business name" aria-describedby="businessName" />
											</div>
										</div>
										<div class="form-group mb-4">
											<label class="mb-1">Services Offered </label> <span
												class="text-danger">*</span>
											<div class="position-relative">
												<textarea maxlength="2500" required name="overview" class="form-control"
													rows="4"
													placeholder="Enter overview of the services your business offers."></textarea>
											</div>
										</div>
									</div>
								</div>
								{/*
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Service Providers</h6>
										<p class="mb-0 mt-0 small">Info about the service providers
											of the business.</p>
									</div>
									<div class="box-body p-3">
										<div class="js-form-message">
											<div class="form-group">

												<label class="mb-1">Percentage % of the
													service provider's profits that go to the business. </label> <span
														class="text-danger">*</span><br />
												<select style={{ width: '48%' }}
													name="percentageProfits"
													class="form-control custom-select" required
													data-msg="Please select percentage of profits that goes to business."
													data-error-class="u-has-error"
													data-success-class="u-has-success">
													<option value="0" selected>0</option>
													<option value="10">10</option>
													<option value="20">20</option>
													<option value="30">30</option>
													<option value="40">40</option>
													<option value="50">50</option>
													<option value="60">60</option>
													<option value="70">70</option>
													<option value="80">80</option>
													<option value="90">90</option>
													<option value="100">100</option>
												</select>
											</div>
										</div>
									</div>
								</div>
								*/}


								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Link Service Providers To This Business</h6>
										<p class="mb-0 mt-0 small">Look up service providers by their email and add them.</p>
									</div>
									<div class="box-body p-3">
										<div class="js-form-message">
											<label id="businessName" class="form-label">
												Lookup Service Provider By Email: </label>
											<div class="form-group">
												<input type="text" maxlength="250" class="form-control" id="serviceProviderEmail" /><br />
												<button id="lookupServiceProvider" class="btn btn-success">Lookup Provider By Email</button><br /><br />

												{services.length > 0 && (
													<fieldset class="form-group">
														<div class="row">
															<legend class="col-form-label col-sm-10 pt-0">Select which of their services you would like to link to this business?</legend>
															<div class="col-sm-10">
																{services.map(service => (
																	<div class="form-check">
																		<input class="form-check-input" type="radio" name="serviceSelection" id={`radio-${service.id}`} value={service.id} />
																		<label class="form-check-label" for={`radio-${service.id}`}>
																			{service.title}
																		</label>
																	</div>
																))}<br />
																<button id="addServiceProvider" class="btn btn-success">Add Provider</button><br /><br />
															</div>

														</div>
													</fieldset>
												)}

												<div class="list-container">
													<ul class="list-group" id="serviceProviderIds">
													</ul>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="mb-3 text-right">
									<button type="submit" disabled={isSubmitting} class="btn btn-success">Create Business Page</button>
								</div>
							</main>
						</div>
					</form>
				</div>
			</div>

		</main>
	);
}

export default CreateBusiness;
