import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function BusinessDetails() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [business, setBusiness] = useState();

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var id = queryParams.get('id');

	useEffect(() => {

		const apiUrl = `${baseUrl}/businesses/${id}`
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setBusiness(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		})
	}, [])


	const handleContactUser = (event) => {
		event.preventDefault();
		const indexed_array = {};
		var user_id = localStorage.getItem('user_id');
		if (!user_id) {
			navigate('/messages');
		}
		var user = { "id": user_id };
		indexed_array["fromUser"] = user;
		var to_user = { "id": business.ownerInfo.id };
		indexed_array["toUser"] = to_user;

		indexed_array["message"] = "Intro: Hello, I have question about your business or services.";

		const apiUrl = `${baseUrl}/messages`;
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function() {
				navigate('/messages');
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	return (
		<main>
			{business && (
				<div>
					<div class="profile-cover text-center">
						<img class="img-fluid" src="images/job-profile.jpg" alt="" />
					</div>
					<div class="bg-white shadow-sm-bottom">
						<div class="container">
							<div class="row">
								<div class="col-md-12">
									<div class="d-flex align-items-center py-3">
										<div class="profile-left">
											<h5 class="font-weight-bold text-dark mb-1 mt-0">{business.name}</h5>
											<p class="mb-0 text-muted">
												Business Page</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="py-5">
						<div class="container">
							<div class="row">

								<main class="col col-xl-5 order-xl-2 col-lg-12 order-lg-1 col-md-12 col-sm-12 col-12">
									<div class="box shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">Overview</h6>
										</div>
										<div class="box-body p-3">
											<p>{business.overview}
											</p>
										</div>
									</div>
									<div class="box shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">About</h6>
										</div>
										<div class="box-body p-3">
											<p>{business.about}
											</p>
										</div>
									</div>

								</main>

								<aside class="col col-xl-3 order-xl-1 col-lg-6 order-lg-2 col-md-6 col-sm-6 col-12">

									<div class="box mb-2 shadow-sm rounded bg-white profile-box text-center">
										<div class="p-3">
											<a style={{ maxHeight: "440px", objectFit: "contain", width: "auto" }} >
												<img class="img-fluid" src={`${business.images[0].imgUrl}`} />
											</a>
										</div>
									</div>


									<div class="box shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">Contact Business</h6>
										</div>
										<div class="box-body p-3">
											<Link class="fit-button" to="#" onClick={handleContactUser}>
												<button class="btn btn-sm btn-success">Send A Message</button>
											</Link><br /><br />
											<p>
												<strong>Email </strong><br /> {business.email ? business.email : 'Not Available'}
											</p>
											<p>
												<strong>Phone </strong><br /> {business.phone ? business.phone : 'Not Available'}
											</p>
										</div>
									</div>

								</aside>

								<aside class="col col-xl-4 order-xl-3 col-lg-6 order-lg-3 col-md-6 col-sm-6 col-12">

									<div class="box shadow-sm rounded bg-white mb-3">
										<div class="box-title border-bottom p-3">
											<h6 class="m-0">Service Providers</h6>
										</div>
										<div class="box-body p-3">
											{business.tasks.map(task => (
												<div class="d-flex align-items-center dlevitin-post-header mb-3 people-list">
													<div class="dropdown-list-image mr-3">
														<img class="rounded-circle" src={`${task.ownerInfo.images[0].imgUrl}`} alt="" />
														<div class="status-indicator bg-success"></div>
													</div>
													<div class="font-weight-bold mr-2">
														<div class="text-truncate">
															{task.ownerInfo.userInfo.name.length > 25
																? task.ownerInfo.userInfo.name.substring(0, 25) + '..'
																: task.ownerInfo.userInfo.name}</div>
														<div class="small text-gray-500">Service Provider
														</div>
													</div>
													<span class="ml-auto">
														<Link to={`/userDetails?id=${task.ownerInfo.id}`}>
															<button type="button"
																class="btn btn-outline-success">View Profile</button>
														</Link>
													</span>
												</div>
											))}
										</div>
									</div>

								</aside>
							</div>
						</div>
					</div>
				</div>
			)}
		</main>
	);
}

export default BusinessDetails;
