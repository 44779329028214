import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation, Link } from 'react-router-dom';

function Faq() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	var user_id = localStorage.getItem('user_id');
	var profile_id = localStorage.getItem('profile_id');

	return (
		<main>
			<div class="py-5">
				<div class="container">

					<div class="row">
						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">General Questions</h6>
									<p class="mb-0 mt-0 small">General questions about Task Beehive</p>
								</div>
								<div class="box-body p-3">
									<div>
										<b>How does the payment between customer and service provider work?</b><br /><br />
										The customer and service provider agree on a payment method and whether payment is required before or after job completion. Customers can pay with cash, check, or credit card (using Square, Venmo, etc) directly to the provider.
										<br />
									</div>
									<hr />
									<div>
										<b>Found a bug or issue with the TaskBeehive.com website or your account?</b><br /><br />
										You can contact us by clicking <Link to="/contact">Contact</Link>. Please allow up to 7 days for a response.
										<br />
									</div>
									<hr />
									<div>
										<b>How to report a service or user?</b><br /><br />
										If you find a service with unlawful or inappropriate content, you can click the 'report' link at the top of the service page and provide the reason for the report in the detail section. TaskBeehive will be notified, investigate, and act in accordance with our policy.
										<br />
									</div>
									<hr />
									<div>
										<b>Did the service provider not show up, do a poor job, or cause damage?</b><br /><br />
										Payment is made directly from the client to the provider. TaskBeehive does not collect money for orders, so no refund is needed. You can leave a review and comment so future users will know your feedback.
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Service Provider Questions</h6>
									<p class="mb-0 mt-0 small">General questions for service provider</p>
								</div>
								<div class="box-body p-3">
									<div>
										<b>How does the payment between customer and service provider work?</b><br /><br />
										The customer and service provider agree on the payment type and whether payment is required before or after job completion. Customers can pay with cash, check, or credit card (using Square, Venmo, etc) directly to the provider. Providers are responsible for paying taxes on their earnings. TaskBeehive collects a 5% fee from the provider's earnings through a monthly invoice.										<br />
									</div>
									<hr />
									<div>
										<b>How to confirm an order to a customer?</b><br /><br />
										You can send an order confirmation message to the customer by going to <Link to="/ordersServiceProvider">Customer Orders</Link>, clicking 'View Order Details', and 'Contact Customer'. While this is optional, it is highly recommended.
										<br />
									</div>
									<hr />
									<div>
										<b>What is the cancellation policy for service providers?</b><br /><br />
										If you need to cancel an order, it is recommended to do so as soon as possible to avoid keeping the customer waiting. You can cancel until the first booked day and time, after which cancellation will no longer be possible. The customer may leave a negative review if this occurs.
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Business Page Questions</h6>
									<p class="mb-0 mt-0 small">General questions for service provider</p>
								</div>
								<div class="box-body p-3">
									<div>
										<b>How do i attach service providers to my business page?</b><br /><br />
										When you create or edit a business page, the form allows you to attach a service provider. You can look up a service provider by their email and then attach their service to your business. To add yourself, type your own email and attach your service.
										<br />
									</div>
									<hr />
									<div>
										<b>Can i have multiple service providers under my business page?</b><br /><br />
										Yes, you can attach multiple service providers to a single business page. The list of service providers will be displayed on the business page.
										<br />
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Referral Partnership Program</h6>
									<p class="mb-0 mt-0 small">General questions about the referral partnership program</p>
								</div>
								<div class="box-body p-3">
									<div>
										<b>How does the referral partnership program work?</b><br /><br />
										You get a referral link to share with others. For every service provider you recommend that signs up, you receive a percentage of the earnings from every job they complete for one year. See more details here: <Link to="/referral">Referral Partner Program</Link>
										<br />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Faq;
