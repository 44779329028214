import React, { useEffect, useState, useRef } from 'react';
import $ from 'jquery';
import { useNavigate, Link } from 'react-router-dom';

function Account() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variables

	var user_id = localStorage.getItem('user_id')

	return (
		<main>
			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<div class="row">
						<div class="col-md-6">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0">Admin Panel</h6>
									<p class="mb-0 mt-0 small">Quick links for admin</p>
								</div>
								<div class="box-body p-3">
									{user_id && (
										<div>
											<Link className="dropdown-item" to="/adminReportedServices">
												<i class="fa-regular fa-flag"></i> Admin Reported Services
											</Link>
											<Link className="dropdown-item" to="/adminUserManagement">
												<i class="fa-regular fa-users"></i> Admin User Management
											</Link>
										</div>
									)}

								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</main>
	);
}

export default Account;
