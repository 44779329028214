import React, { useState, useEffect } from "react";
import $ from 'jquery';
import { Link, useLocation } from 'react-router-dom';

function Partner() {
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable
	const location = useLocation();

	const queryParams = new URLSearchParams(location.search);
	var partnerId = queryParams.get('id');
	if (partnerId) {
		localStorage.setItem('partner_id', partnerId);
	}

	return (
		<main>
			<section class="py-5 homepage-search-block position-relative">
				<div class="container">
					<div class="row py-lg-2">
						<div class="col-lg-12">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0"><b> Welcome! You've Been Referred To Us By Our Partner </b></h6>
									<p class="mb-0 mt-0 small">Become a service provider, start a business, offer a service, manage your own schedule.
										<br />
									</p>
								</div>
								<div class="box-body">
									<div class="p-3">
										<b>How It Works</b>: <br />
										A partner has referred you to TaskBeehive. Join today as a service provider, and the referring partner will receive credit. Read about the benefits of joining below. Among the many features, you can create a business page, offer services to customers, manage your own schedule, track customers and earnings, and maximize your earning potential.
										<br /><br />
										<b>Join Task Beehive</b>:<br /><br />
										<Link to="/register?serviceProvider=true">
											<button class="btn btn-sm btn-success" type="submit"> Register As A Service Provider </button>
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row py-lg-2">
						<div class="col-lg-12">
							<div class="shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3" id="benefits-for-service-providers">
									<h6 class="m-0"> Benefits Of Joining For Service Providers And Businesses
									</h6>
								</div>
								<div class="box-body">
									<br />
									<div class="container">
										<div class="row justify-content-center align-items-center">
											<div class="col-lg-6">
												<div class="text-content justify-content-center align-items-center">
													<p style={{ fontSize: "13px" }}>
														<b>Service Providers</b><br /> Work for yourself, connect with customers, and turn your skills or passion into a business.
														<br /><br />
														<b>Businesses</b><br /> Find new customers, create a business page, manage providers, and track their earnings and reviews.
													</p>
													<Link to="/about">
														<button class="btn btn-sm btn-success">Learn More About Task Beehive</button>
													</Link>
												</div>
											</div>
											<div class="col-lg-6">
												<div class="p-3" style={{ maxWidth: '560px' }}>
													<div class="video-responsive" >
														<iframe
															width="560"
															height="315"
															src="https://www.youtube.com/embed/3wWNVWbyL8g?rel=0&showinfo=0&autoplay=0&controls=0"
															frameborder="0"
															allowfullscreen
															title="Embedded YouTube"
														></iframe>
													</div>
												</div>
											</div>
										</div>
									</div>
									<br />
								</div>
							</div>
						</div>
					</div>
				</div>
			</section >
		</main >
	);
}

export default Partner;