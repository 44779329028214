import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Alerts() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [notifications, setNotifications] = useState();

	useEffect(() => {
		function method() {
			const user_id = localStorage.getItem('user_id');
			const apiUrl = `${baseUrl}/alerts/user/${user_id}`;
			$.ajax({
				url: apiUrl,
				type: 'GET',
				processData: false,
				contentType: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(data) {
					setNotifications(data);
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		}
		method();
		const intervalId = setInterval(method, 60000);
		return () => clearInterval(intervalId);
	}, []);

	function formatDate(isoDateString) {
		const date = new Date(isoDateString);
		const options = {
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: '2-digit',
			hour12: true
		};
		return date.toLocaleTimeString('en-US', options);
	}

	function formatDetail(detail) {
		return { __html: detail.replace(/\n/g, '<br/>') };
	}

	return (
		<main>
			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<div class="row">
						<div class="col-lg-12 left">
							<div class="box shadow-sm rounded bg-white mb-3">
								<div class="box-title border-bottom p-3">
									<h6 class="m-0"> Notifications:</h6>
								</div>
								<div class="box-body p-0" style={{ overflowY: 'auto', maxHeight: '600px' }}>
									{notifications && notifications.length ? (
										notifications.map(n => (
											<div class="p-3 d-flex flex-column align-items-start border-bottom dlevitin-post-header">
												<div class="d-flex align-items-center" style={{ width: '100%' }}>
													<i class="fa-light fa-bell fa-lg" style={{ marginRight: '12px' }}></i>
													<div class="font-weight-bold mr-3">
														{n.title}
													</div>
													<span class="ml-auto mb-auto text-right text-muted">
														<div class="text-right text-muted pt-1">{formatDate(n.dateTime)}</div>
													</span>
												</div>
												<div class="d-flex align-items-center mt-2">
													<div class="mr-3"
														dangerouslySetInnerHTML={{ __html: n.detail.replace(/\n/g, '<br/>') }}>
													</div>
												</div>
												{n.entityUrl &&
													<div class="d-flex align-items-center mt-2">
														<div>
															<Link to={n.entityUrl}>
																<button class="btn btn-sm btn-success">View Details</button>
															</Link>
														</div>
													</div>
												}
											</div>

										))
									) : (
										<div
											class="p-3 d-flex align-items-center border-bottom dlevitin-post-header">
											<div>You have no recent notifications</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main >
	);
}

export default Alerts;
