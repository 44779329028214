import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

function Categories() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	// Categories state
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var genCategory = queryParams.get('genCategory');

	// Categories effect
	useEffect(() => {

		const apiUrl3 = `${baseUrl}/categories`;
		$.ajax({
			url: apiUrl3,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				// Organize categories by genCategoryName
				const categoriesByGenCategory = {};
				data.forEach(entry => {
					const genCategoryName = entry.genCategoryName;
					if (!categoriesByGenCategory[genCategoryName]) {
						categoriesByGenCategory[genCategoryName] = [];
					}
					categoriesByGenCategory[genCategoryName].push({
						value: entry.id,
						genCategoryName: entry.genCategoryName,
						subCategoryName: entry.subCategoryName,
						detail: entry.detail
					});
				});
				setCategories(categoriesByGenCategory);
				setLoading(false);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])


	useEffect(() => {
		if (!categories) {
			return
		}
		if (genCategory) {
			const targetElement = document.getElementById(genCategory.replace(/\s+/g, ''));
			if (targetElement) {
				targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	}, [categories])

	return (
		<main>
			<section class="py-5 p-list-two">
				<div class="container">
					<div class="row">
						<div class="col-lg-12 view_slider recommended">
							<div class="row">
								<div class="col-lg-12">
									<h6>Categories:</h6><br />
								</div>
							</div>
							<div class="row">
								{Object.keys(categories).map(genCategoryName => {
									const modifiedName = genCategoryName.replace(/\s/g, '') + '.png';
									return (
										<div class="col-md-4" id={genCategoryName.replace(/\s+/g, '')} >
											<div class="shadow-sm rounded bg-white mb-3">
												<div class="box-title border-bottom p-2" style={{ textAlign: 'center' }}>
													<br /><img src={`images/categories/icons/icons2/${modifiedName}`}
														style={{ width: '40%', maxWidth: '120px', borderRadius: '5px', opacity: 1.0 }}
													/><br /><br />
													<h6 class="m-0">{genCategoryName}</h6>
												</div>
												<div class="box-body p-3">
													{categories[genCategoryName].map(category => (
														<div>
															<Link to={`/search?keywords=&category=${category.value}`}>
																<span>{category.subCategoryName}</span>
															</Link>
															<p class="mb-0 mt-0 small">{category.detail}</p><br />
														</div>
													))}
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
		</main >
	);
}

export default Categories;
