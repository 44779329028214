import React from 'react';
import { Link } from 'react-router-dom';

function Footer({ userId, setUserId, profileId, setProfileId }) {

	return (

		<footer class="bg-white border-top">
			<div class="container">
				<div class="d-flex justify-content-between">

					<div class="footer-list">
						<h2>Go To</h2>
						<ul class="list">

							{userId && (
								<div>
									<li><Link to="/orders">Orders</Link></li>
									<li><Link to="/alerts">Notifications</Link></li>
									<li><Link to="/messages">Messages</Link></li>
									<li><Link to="/search?keywords=">Search</Link></li>
									<li><Link to="/favorites">Favorites</Link></li>
								</div>
							)}
							{!userId && (
								<div>
									<li><Link to="/login">Login</Link></li>
									<li><Link to="/register">Sign Up</Link></li>
								</div>
							)}
						</ul>
					</div>
					<div class="footer-list">
						<h2>Service Provider</h2>
						<ul class="list">
							{!profileId && (
								<li><Link to="/becomeSeller">Sign Up As Provider</Link></li>
							)}

							{profileId && (
								<div>
									<li><Link to="createService">Create Service</Link></li>
									<li><Link to="services">Your Services</Link></li>
									<li><Link to="createBusiness">Create Business</Link></li>
									<li><Link to="businesses">View Business</Link></li>
								</div>
							)}
						</ul>
					</div>
					<div class="footer-list">
						<h2>Categories</h2>
						<ul class="list">
							<li><Link to="/categories">View All Categories</Link></li>
						</ul>
					</div>
					<div class="footer-list">
						<h2>Community</h2>
						<ul class="list">
							<li><Link to="/about">About</Link></li>
							<li><Link to="/contact">Contact</Link></li>
							<li><Link to="/referral">Referral Partner Program</Link></li>
							<li><Link to="/faq">FAQ</Link></li>
						</ul>
					</div>

				</div>
				<div class="copyright">
					<div class="logo">
						<Link to="/"> <img src="images/logo7.png" />
						</Link>
					</div>
					<p>Task Beehive LLC 2024. All rights reserved.</p>
					<ul class="social">{/*
						<li>
							<Link to="https://www.facebook.com/profile.php?id=61554869710534">
								<i class="fa-brands fa-facebook" aria-hidden="true"></i>
							</Link>
						</li>*/}
						<li>
							<Link to="https://twitter.com/TaskBeehive" target="_blank">
								<i class="fa-brands fa-twitter"></i>
							</Link>
						</li>
						<li>
							<Link to="https://www.linkedin.com/company/task-beehive" target="_blank">
								<i class="fa-brands fa-linkedin" aria-hidden="true" ></i>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>

	);
}

export default Footer;
