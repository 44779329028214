import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

function EmailVerification({ userId, setUserId, profileId, setProfileId }) {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	var userId = queryParams.get('userId');
	var forward = queryParams.get('forward');

	const [user, setUser] = useState();
	const [hasSubmitted, setHasSubmitted] = useState(false);

	useEffect(() => {
		if (!userId) {
			$("#alertText").html("User ID not found in query parameters.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			return;
		}

		const apiUrl = `${baseUrl}/users/` + userId;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(user) {
				setUser(user)
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const resendVerificationEmail = (event) => {
		setHasSubmitted(true);
		const apiUrl = `${baseUrl}/users/resendEmailVerification/` + userId;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
				setHasSubmitted(false);
			}
		});
	};

	// Poll if email verified
	useEffect(() => {
		function method() {
			const apiUrl = `${baseUrl}/users/` + userId;
			$.ajax({
				url: apiUrl,
				type: 'GET',
				processData: false,
				contentType: false,
				beforeSend: function(xhr) {
					const token = localStorage.getItem('token');
					xhr.setRequestHeader('Authorization', 'Bearer ' + token);
				},
				success: function(user) {
					if (user.emailConfirmed) {
						navigate(`${forward}`);
					}
				},
				error: function(msg) {
					if (msg.responseText) {
						$("#alertText").html(msg.responseText);
						$("#message").html($("#alert-template").clone().removeAttr("style"));
					}
				}
			});
		}
		method();
		const intervalId = setInterval(method, 1000);
		return () => clearInterval(intervalId);
	}, [user]);

	return (
		<main>
			<div class="">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					{user && (
						<div class="row justify-content-center align-items-center d-flex vh-80">
							<div class="col-lg-8">
								<div class="dlevitin-login py-4">
									<div class="col-md-30">
										<div class="shadow-sm rounded bg-white mb-3">
											<div class="box-body p-5" >
												<div class="text-center mb-4">
													<h5 class="font-weight-bold mt-3">Email Verification</h5>
													<p class="text-muted">Hello {user.name}, please verify your email. </p>
												</div>
												<p>
													<b>Your Email</b>: {user.email}
												</p>
												<p>
													Please verify your email by clicking the "<b>Confirm Your Email</b>" button in the message we <b>sent to your inbox</b>. If you did not receive the email, check your spam folder or verify the email address you provided.
												</p>
												<button class="btn btn-success btn-block" style={{ maxWidth: '180px', marginTop: '5px' }}
													type="submit" disabled={hasSubmitted} onClick={resendVerificationEmail}>Resend Verification Email</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

				</div>
			</div>
		</main>
	);
}

export default EmailVerification;
