import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Register from './components/Register';
import Login from './components/Login';
import BecomeSeller from './components/BecomeSeller';
import CreateBusiness from './components/CreateBusiness';
import CreateService from './components/CreateService';
import PageNotFound from './components/PageNotFound';
import CheckLogin from './components/CheckLogin';
import CheckAdmin from './components/CheckAdmin';
import CheckProfile from './components/CheckProfile';
import Search from './components/Search';
import Invoice from './components/Invoice';
import Messages from './components/Messages';
import ServiceDetails from './components/ServiceDetails';
import UserDetails from './components/UserDetails';
import BusinessDetails from './components/BusinessDetails';
import Privacy from './components/Privacy';
import Contact from './components/Contact';
import Review from './components/Review';
import ServiceProviderAgreement from './components/ServiceProviderAgreement';
import UserAgreement from './components/UserAgreement';
import Order from './components/Order';
import Categories from './components/Categories';
import Alerts from './components/Alerts';
import Orders from './components/Orders';
import Services from './components/Services';
import Businesses from './components/Businesses';
import EditSchedule from './components/EditSchedule';
import EditSeller from './components/EditSeller';
import EditUser from './components/EditUser';
import EditService from './components/EditService';
import EditBusiness from './components/EditBusiness';
import About from './components/About';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import EmailSettings from './components/EmailSettings';
import EmailConfirmed from './components/EmailConfirmed';
import EmailVerification from './components/EmailVerfication';
import OrderDetails from './components/OrderDetails';
import OrdersServiceProvider from './components/OrdersServiceProvider';
import DailySchedule from './components/DailySchedule';
import Referral from './components/Referral';
import Partner from './components/Partner';
import Favorites from './components/Favorites';
import Earnings from './components/Earnings';
import EarningsBusiness from './components/EarningsBusiness';
import Faq from './components/Faq';
import Account from './components/Account';
import ScrollToTop from './components/ScrollToTop';
import RequestPayout from './components/RequestPayout';
import AdminReportedServices from './components/AdminReportedServices';
import AdminPanel from './components/AdminPanel';
import AdminUserManagement from './components/AdminUserManagement';

function App() {

	const [userId, setUserId] = useState(localStorage.getItem('user_id'));
	const [profileId, setProfileId] = useState(localStorage.getItem('profile_id'));
	const [hasBusiness, setHasBusiness] = useState(localStorage.getItem('has_business') === 'true');

	return (
		<Router>
			<Header userId={userId} setUserId={setUserId} profileId={profileId} setProfileId={setProfileId} hasBusiness={hasBusiness} setHasBusiness={setHasBusiness} />
			<ScrollToTop />

			<Routes>
				<Route exact path="/" element={<Home />} />
				<Route path="/register" element={<Register userId={userId} setUserId={setUserId} profileId={profileId} setProfileId={setProfileId} />} />
				<Route path="/login" element={<Login userId={userId} setUserId={setUserId} profileId={profileId} setProfileId={setProfileId} hasBusiness={hasBusiness} setHasBusiness={setHasBusiness} />} />
				<Route path="/search" element={<Search />} />
				<Route path="/serviceDetails" element={<ServiceDetails />} />
				<Route path="/userDetails" element={<UserDetails />} />
				<Route path="/businessDetails" element={<BusinessDetails />} />
				<Route path="/categories" element={<Categories />} />
				<Route path="/about" element={<About />} />
				<Route path="/resetPassword" element={<ResetPassword />} />
				<Route path="/changePassword" element={<ChangePassword />} />
				<Route path="/emailSettings" element={<EmailSettings />} />
				<Route path="/orderDetails" element={<OrderDetails />} />
				<Route path="/partner" element={<Partner />} />
				<Route path="/faq" element={<Faq />} />
				<Route path="/businessEarnings" element={<EarningsBusiness />} />
				<Route path='/' element={<CheckLogin />}>
					<Route path="/order" element={<Order />} />
					<Route path="/orders" element={<Orders />} />
					<Route path="/businesses" element={<Businesses />} />
					<Route path="/createBusiness" element={<CreateBusiness hasBusiness={hasBusiness} setHasBusiness={setHasBusiness} />} />
					<Route path="/becomeSeller" element={<BecomeSeller userId={userId} setUserId={setUserId} profileId={profileId} setProfileId={setProfileId} />} />
					<Route path="/invoice" element={<Invoice />} />
					<Route path="/messages" element={<Messages />} />
					<Route path="/alerts" element={<Alerts />} />
					<Route path="/review" element={<Review />} />
					<Route path="/editUser" element={<EditUser />} />
					<Route path="/referral" element={<Referral />} />
					<Route path="/favorites" element={<Favorites />} />
					<Route path="/account" element={<Account />} />
					<Route path="/requestPayout" element={<RequestPayout />} />
					<Route path="/emailConfirmed" element={<EmailConfirmed />} />
					<Route path="/emailVerification" element={<EmailVerification />} />
				</Route>
				<Route path='/' element={<CheckProfile />}>
					<Route path="/ordersServiceProvider" element={<OrdersServiceProvider />} />
					<Route path="/dailySchedule" element={<DailySchedule />} />
					<Route path="/services" element={<Services />} />
					<Route path="/createService" element={<CreateService />} />
					<Route path="/editSchedule" element={<EditSchedule />} />
					<Route path="/editService" element={<EditService />} />
					<Route path="/editSeller" element={<EditSeller />} />
					<Route path="/editBusiness" element={<EditBusiness />} />
					<Route path="/earnings" element={<Earnings />} />
				</Route>
				<Route path='/' element={<CheckAdmin />}>
					<Route path="/adminReportedServices" element={<AdminReportedServices />} />
					<Route path="/adminUserManagement" element={<AdminUserManagement />} />
					<Route path="/adminPanel" element={<AdminPanel />} />
				</Route>
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/pageNotFound" element={<PageNotFound />} />
				<Route path="/serviceProviderAgreement" element={<ServiceProviderAgreement />} />
				<Route path="/userAgreement" element={<UserAgreement />} />
				<Route path="*" element={<Navigate to="/pageNotFound" />} />
			</Routes>

			<Footer userId={userId} setUserId={setUserId} profileId={profileId} setProfileId={setProfileId} />
		</Router>
	);
}

export default App;
