import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import $ from 'jquery';
import { useNavigate, useLocation } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import { v4 as uuidv4 } from 'uuid';
import '../static/css/mark-your-calendar.css'
import '../static/js/mark-your-calendar.js'
import sliders from '../static/js/custom2.js'

function CreateService() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	// Load in dates
	const [categories, setCategories] = useState([]);

	// Load in costs
	const costs = [];
	for (let i = 15; i <= 1000; i = i + 5) {
		costs.push({ value: i, label: i });
	}

	// Load in costs
	const hoursPerService = [];
	for (let i = 2; i <= 168; i = i + 1) {
		hoursPerService.push({ value: i, label: i });
	}

	useEffect(() => {
		$("#repeatWeekly").prop("checked", true);

		const apiUrl3 = `${baseUrl}/categories`;
		$.ajax({
			url: apiUrl3,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				const groupedCategories = data.reduce((acc, entry) => {
					if (acc[entry.genCategoryName]) {
						acc[entry.genCategoryName].subCategories.push({
							value: entry.id,
							subCategoryName: entry.subCategoryName
						});
					} else {
						acc[entry.genCategoryName] = {
							genCategoryName: entry.genCategoryName,
							subCategories: [{ value: entry.id, subCategoryName: entry.subCategoryName }]
						};
					}
					return acc;
				}, {});
				const categories = Object.values(groupedCategories);
				categories.forEach(category => {
					category.subCategories.sort((a, b) => a.subCategoryName.localeCompare(b.subCategoryName));
				});
				categories.sort((a, b) => a.genCategoryName.localeCompare(b.genCategoryName));
				setCategories(categories);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, []);

	const [hasNotRun, setHasNotRun] = useState(true);
	useLayoutEffect(() => {
		if (categories.length > 0 && hasNotRun) {
			setHasNotRun(false);
			sliders.loadSelect();
		}
	}, [categories])

	/**
	 * Image Uploading
	 */
	const compressImage = async (file) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};

		try {
			const compressedBlob = await imageCompression(file, options);

			// Convert the compressed Blob back to a File object
			const compressedFile = new File(
				[compressedBlob],
				`${uuidv4()}.${file.type.split('/')[1]}`,
				{ type: file.type, lastModified: Date.now() }
			);

			return compressedFile;
		} catch (error) {
			console.error('Error compressing the image:', error);
			throw error;
		}
	};

	const getSignedUrl = async (fileName, contentType) => {
		const url = new URL(`${baseUrl}/image/generateSignedUrl`);
		url.searchParams.append('fileName', fileName);
		url.searchParams.append('contentType', contentType);
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + localStorage.getItem('token'),
			},
		});
		const data = await response.text();
		return data;
	};

	const uploadToGCS = async (file, signedUrl) => {
		await fetch(signedUrl, {
			method: 'PUT',
			headers: {
				'Content-Type': file.type,
			},
			body: file,
		});
	};

	const [uploadedImages, setUploadedImages] = useState([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleFileChange = async (event) => {
		setIsSubmitting(true);
		const files = Array.from(event.target.files);
		if (files.length > 3) {
			$("#alertText").html("Maximum of 3 images is allowed. Please re-upload the images.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			return;
		}
		const compressedFiles = await Promise.all(files.map((file) => compressImage(file)));

		const uploadedFiles = await Promise.all(
			compressedFiles.map(async (file) => {
				const contentType = file.type;
				const fileName = file.name;
				const signedUrl = await getSignedUrl(fileName, contentType);

				await uploadToGCS(file, signedUrl);

				return {
					fileName,
					contentType,
					imgUrl: signedUrl.split('?')[0],
				};
			})
		);
		setUploadedImages(uploadedFiles);
		setIsSubmitting(false);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSubmitting(true);

		if (captchaInput !== captcha) {
			setCaptcha(generateCaptcha());
			setCaptchaInput('');
			$("#alertText").html("The captcha you typed didn't match the one provided. Please try again.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			setIsSubmitting(false);
			return;
		}

		const unindexed_array = $('#target :input[name!="file"]').serializeArray();
		const indexed_array = {};
		$.map(unindexed_array, function(n, i) {
			indexed_array[n['name']] = n['value'];
		});

		if (uploadedImages.length === 0) {
			$("#alertText").html("Wait for images to upload before submiting. Try again in 5 seconds.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			setIsSubmitting(false);
			return;
		}

		indexed_array["images"] = uploadedImages.map((img) => ({
			fileName: img.fileName,
			contentType: img.contentType,
			imgType: 'service',
			imgUrl: img.imgUrl,
		}));

		const profile_id = localStorage.getItem('profile_id');
		const profile = { id: profile_id };
		indexed_array["profile"] = profile;

		const category_id = $('#categorySelect').val();
		const category = { id: category_id };
		indexed_array["category"] = category;

		const apiUrl2 = `${baseUrl}/tasks`;
		$.ajax({
			url: apiUrl2,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(task) {
				const indexed_array = [];
				for (let i = 0; i < availability.length; i++) {
					const avail = {};
					const availTask = { id: task.id };
					avail["task"] = availTask;
					const date = new Date(availability[i]);
					avail["availableAt"] = date.toISOString();
					avail["isReserved"] = false;
					avail["repeatWeekly"] = $("#repeatWeekly").is(":checked");
					indexed_array.push(avail);
				}
				const apiUrl3 = `${baseUrl}/availability`;
				$.ajax({
					url: apiUrl3,
					type: 'POST',
					data: JSON.stringify(indexed_array),
					contentType: 'application/json; charset=utf-8',
					dataType: 'json',
					async: false,
					beforeSend: function(xhr) {
						const token = localStorage.getItem('token');
						xhr.setRequestHeader('Authorization', 'Bearer ' + token);
					},
					success: function(msg) {
						navigate('/services');
					},
					error: function(msg) {
						if (msg.responseText) {
							$("#alertText").html(msg.responseText);
							$("#message").html($("#alert-template").clone().removeAttr("style"));
						}
						setIsSubmitting(false);
					}
				});

			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
				setIsSubmitting(false);
			}
		});
	};

	const initSchedulerRef = useRef(false);
	const [availability, setAvailability] = useState([]);
	useEffect(() => {
		if (initSchedulerRef.current) {
			return;
		}
		initSchedulerRef.current = true;
		$('#picker').markyourcalendar({
			availability: [
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
				['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
			],
			isMultiple: true,
			onClick: function(ev, data) {
				setAvailability(data);
				console.log(availability);
			},
			onClickNavigator: function(ev, instance) {
				var arr = [
					[
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'],
						['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00']
					]
				]
				instance.setAvailability(arr[0]);
			}
		});
	}, []);

	// Captcha
	const [captcha, setCaptcha] = useState('');
	const [captchaInput, setCaptchaInput] = useState('');
	function generateCaptcha() {
		const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
		let result = '';
		for (let i = 0; i < 4; i++) {
			result += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return result;
	}
	useEffect(() => {
		setCaptcha(generateCaptcha());
	}, []);
	const handleCaptchaInputChange = (event) => {
		setCaptchaInput(event.target.value);
	};

	return (
		<main>
			<div class="py-5">
				<div class="container">
					<div id="alert-template" style={{ display: 'none' }}><br />
						<div class="alert alert-warning alert-dismissible" role="alert">
							<div id="alertText"></div>
							<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
						</div>
					</div>
					<div id="message"></div>

					<form id="target" onSubmit={handleSubmit}>
						<div class="row">

							<aside class="col-md-5">
								<div
									class="box mb-3 shadow-sm rounded bg-white profile-box text-center">
									<div class="py-4 px-3 border-bottom">
										<h6 class="m-0">Create A Service</h6>
									</div>
									<div class="p-3">
										<h6 class="m-0">
											Upload Service Pictures <span class="text-danger">*</span>
										</h6>
										<p class="mb-0 mt-0 small">Multi-select up to 3 images</p>
										<br /> <input required type="file" class="" name="file"
											id="file" accept="image/*" multiple="multiple" onChange={handleFileChange} />
									</div>
								</div>
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Service Offer Details</h6>
										<p class="mb-0 mt-0 small">What the customer expects to recieve, and payment options.</p>
									</div>
									<div class="box-body">
										<div class="p-3">
											<div class="form-group mb-4">
												<label class="mb-1">Offer Details</label> <span
													class="text-danger">*</span>
												<div class="position-relative">
													<textarea maxlength="2500" required name="about" class="form-control"
														rows="4"
														placeholder="What the customer expects to recieve, and payment options"></textarea>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Captcha Check
										</h6>
										<p class="mb-0 mt-0 small">Type the characters you see in the image below to confirm that you're not a robot.</p>
									</div>
									<div class="box-body">
										<div class="p-3">
											Captcha:
											<svg width="200" height="50" style={{ marginLeft: '20px' }} xmlns="http://www.w3.org/2000/svg">
												<rect x="1" y="1" width="198" height="48" fill="white" stroke="black" strokeWidth="1" />
												{captcha.split('').map((char, index) => (
													<text
														key={index}
														x={20 + 30 * index}
														y="30"
														fontSize="20"
														fontFamily="monospace"
														fill="black"
														transform={`rotate(${Math.random() * 10 - 5} ${20 + 30 * index}, 30)`}
													>
														{char}
													</text>
												))}
											</svg><br /><br />
											<div class="js-form-message">
												<label id="titleOfService" class="form-label">
													Type The Captcha Above:
												</label>
												<div class="form-group">
													<input required class="form-control" type="text" maxlength="6" value={captchaInput} onChange={handleCaptchaInputChange} />
												</div>
											</div>
										</div>
									</div>
								</div>

							</aside>
							<main class="col-md-7">
								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0">Service Information</h6>
										<p class="mb-0 mt-0 small">Basic information for the service
											being provided.</p>
									</div>
									<div class="box-body p-3">
										<div class="js-form-message">
											<label id="titleOfService" class="form-label"> Title
												Of Service <span class="text-danger">*</span>
											</label>
											<div class="form-group">
												<input required class="form-control" type="text" maxlength="500"
													name="title"
													placeholder="Enter the title of the service"
													aria-label="Enter the title of the service"
													aria-describedby="titleOfService"
													data-msg="Please enter a valid title of service"
													data-error-class="u-has-error"
													data-success-class="u-has-success" />
											</div>
										</div>
										<div class="form-group mb-4">
											<label class="mb-1">Details Of Service</label> <span
												class="text-danger">*</span>
											<div class="position-relative">
												<textarea maxlength="2500" required name="detail" class="form-control"
													rows="4"
													placeholder="Enter the details of the service provided"></textarea>
											</div>
										</div>
										<div class="row">
											<div class="col-sm-6 mb-2">
												<div class="form-group mb-4">
													<label class="mb-1">Cost Per Hour Of Work</label> <span
														class="text-danger">*</span>
													<select required id="cost" name="cost"
														class="form-control custom-select"
														data-msg="Select cost per hour of work."
														data-error-class="u-has-error"
														data-success-class="u-has-success">
														<option value="" disabled selected>Select Cost Per Hour</option>
														{costs.map(option => (
															<option key={option.value} value={option.value}>
																${option.label}
															</option>
														))}
													</select>
												</div>
											</div>

											<div class="col-sm-6 mb-2">
												<div class="js-form-message">
													<div class="form-group">
														<label class="mb-1">Service Category</label> <span
															class="text-danger">*</span>
														<select required id="categorySelect" name="categorySelect" className="custom-select form-control border-0 shadow-sm form-control-lg">
															<option value="" disabled selected>Select Category</option>
															{categories.map(category => (
																<optgroup key={category.genCategoryName} label={category.genCategoryName}>
																	{category.subCategories.map(subCategory => (
																		<option key={subCategory.value} value={subCategory.value}>
																			{subCategory.subCategoryName}
																		</option>
																	))}
																</optgroup>
															))}
														</select>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div class="shadow-sm rounded bg-white mb-3">
									<div class="box-title border-bottom p-3">
										<h6 class="m-0"> Schedule Service Availability</h6>
										<p class="mb-0 mt-0 small">Select when you are available to provide this service. Can customize it later.</p>
									</div>

									<div class="box-body p-3">
										<div class="form-group mb-4">
											<label class="mb-1">Minimum Hours Customer Can Book Per Order</label> <span
												class="text-danger">*</span><br/>
											<select style={{ maxWidth: '48%' }} id="minimumHours" name="minimumHours"
												class="form-control custom-select" required
												data-msg="Select cost per hour of work."
												data-error-class="u-has-error"
												data-success-class="u-has-success">
												<option value="1">1 hour</option>
												{hoursPerService.map(option => (
													<option key={option.value} value={option.value}>
														{option.label} hours
													</option>
												))}
											</select>
										</div>
										<div class="form-group mb-4">
											<label class="mb-1">If Checked, The Selected Schedule Will Repeat Weekly For 3 Months</label>
											<div class="custom-control custom-checkbox">
												<input type="checkbox" class="custom-control-input" id="repeatWeekly" name="repeatWeekly" />
												<label class="custom-control-label" for="repeatWeekly">
													Repeat Selected Dates And Times Weekly
												</label><br />
											</div>
										</div>

										<div id="picker"></div>
									</div>
								</div>
								<div class="mb-3 text-right">
									<button type="submit" class="btn btn-success" disabled={isSubmitting}>Create And Publish Service</button>
								</div>
							</main>
						</div>
					</form>
				</div>
			</div>

		</main>
	);
}

export default CreateService;
