import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

function Businesses() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [businesses, setBusinesses] = useState([]);

	useEffect(() => {

		var user_id = localStorage.getItem('user_id');
		const apiUrl = `${baseUrl}/businesses/user/` + user_id;
		$.ajax({
			url: apiUrl,
			type: 'GET',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				setBusinesses(data);
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	}, [])

	const handleDeleteBusiness = () => {
		const apiUrl2 = `${baseUrl}/businesses/${currentModalId}`;
		$.ajax({
			url: apiUrl2,
			type: 'DELETE',
			processData: false,
			contentType: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(data) {
				navigate(0, { replace: true });
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
			}
		});
	};

	const [currentModalId, setCurrentModalId] = useState(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const handleOpenModal = (id) => {
		setCurrentModalId(id);
		setIsModalOpen(true)
	};
	const handleCloseModal = () => setIsModalOpen(false);

	return (
		<main>
			<div class="main-page second py-5">
				<div class="container">
					<div class="row">
						<div class="col-md-12">
							<div class="d-flex align-items-center mb-3 pb-3 border-bottom">
								<h2 class="mb-0 p-0">Businesses</h2>

								<Link class="btn btn-sm btn-success ml-auto" to="/createBusiness">Create A New Business</Link>							</div>
							<ul class="nav dlevitin-tabs nav-tabs flex-column flex-sm-row ">
								<li class="nav-item">
									<a href="#active" data-toggle="tab" class="nav-link make-black active">
										ALL BUSINESSES
									</a>
								</li>
							</ul>
							<div class="tab-content dlevitin-table bg-white rounded shadow-sm px-3 pt-1">
								<div class="tab-pane active" id="active">
									<div class="table-responsive box-table mt-3">
										<table class="table table-bordered">
											<thead>
												<tr>
													<th>BUSINESS TITLE</th>
													<th>ACTIONS</th>
												</tr>
											</thead>
											<tbody>
												{businesses.length > 0 ? (
													businesses.map(business => (
														<tr>
															<td style={{ minWidth: '200px' }}>
																<Link to={`/businessDetails?id=${business.id}`} class="make-black">
																	{business.images[0].imgUrl &&
																		<img class="order-proposal-image" src={`${business.images[0].imgUrl}`} />
																	}
																	<p class="order-proposal-title">{business.name}</p>
																</Link>
															</td>
															<td>
																{business.cancelled ? (
																	<button class="btn btn-sm btn-success">Deleted</button>
																) : (
																	<div>
																		<Link to={`/businessDetails?id=${business.id}`}>
																			<button style={{ margin: '5px' }} class="btn btn-sm btn-success">View Business</button>
																		</Link>
																		<Link to={`/editBusiness?id=${business.id}`}>
																			<button style={{ margin: '5px' }} class="btn btn-sm btn-success">Edit Business</button>
																		</Link>
																		<Link to={`/businessEarnings?id=${business.id}`}>
																			<button style={{ margin: '5px' }} class="btn btn-sm btn-success">Earnings</button>
																		</Link>
																		<button onClick={() => handleOpenModal(business.id)} class="btn btn-sm btn-success">Delete Business</button>
																	</div>
																)}
															</td>
														</tr>
													))
												) : (
													<tr>
														<td colSpan="5">No Businesses</td>
													</tr>
												)}
												<Modal isOpen={isModalOpen} class="cancelModal" onRequestClose={handleCloseModal} style={{ content: { width: '500px', height: '200px', margin: 'auto' } }}>
													<div style={{ textAlign: 'center' }}>
														<br /><h5>Are you sure you want to delete your business?</h5><br />
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleDeleteBusiness()}>Yes, Continue</button>
														<button style={{ margin: '3px' }} class="btn btn-sm btn-success" onClick={() => handleCloseModal()}>No, Go Back</button>
													</div>
												</Modal>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
}

export default Businesses;
