import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Contact() {
	const navigate = useNavigate();
	const baseUrl = process.env.REACT_APP_REST_API_URL; // Accessing the REST_API_URL environment variable

	const [isSubmitting, setIsSubmitting] = useState(false);

	const handleContact = (event) => {
		event.preventDefault();
		setIsSubmitting(true);

		if (captchaInput != captcha) {
			setCaptcha(generateCaptcha()); // regenerate captcha
			setCaptchaInput(''); // clear input
			$("#alertText").html("The captcha you typed didn't match the one provided. Please try again.");
			$(".alert").removeClass("alert-success").addClass("alert-warning");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			setIsSubmitting(false);		
			return;
		}

		var unindexed_array = $('#target').serializeArray();
		var indexed_array = {};
		$.map(unindexed_array, function(n, i) {
			indexed_array[n['name']] = n['value'];
		});

		const email = $('#email').val();
		if (!validateEmail(email)) {
			$("#alertText").html("Incorrect email format. Please try again.");
			$("#message").html($("#alert-template").clone().removeAttr("style"));
			setIsSubmitting(false);
			return false;
		}

		const apiUrl = `${baseUrl}/emails`;
		$.ajax({
			url: apiUrl,
			type: 'POST',
			data: JSON.stringify(indexed_array),
			contentType: 'application/json; charset=utf-8',
			dataType: 'json',
			async: false,
			beforeSend: function(xhr) {
				const token = localStorage.getItem('token');
				xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			},
			success: function(msg) {
				$("#alertText").html("Message was succesfully sent to Task Beehive.");
				$(".alert").removeClass("alert-warning").addClass("alert-success");
				$("#message").html($("#alert-template").clone().removeAttr("style"));
			},
			error: function(msg) {
				if (msg.responseText) {
					$("#alertText").html(msg.responseText);
					$(".alert").removeClass("alert-success").addClass("alert-warning");
					$("#message").html($("#alert-template").clone().removeAttr("style"));
				}
				setIsSubmitting(false);
			}
		});

		setCaptcha(generateCaptcha()); // regenerate captcha
		setCaptchaInput(''); // clear input
	};

	// Captcha
	const [captcha, setCaptcha] = useState('');
	const [captchaInput, setCaptchaInput] = useState('');
	function generateCaptcha() {
		const characters = '0123456789abcdefghijklmnopqrstuvwxyz';
		let result = '';
		for (let i = 0; i < 4; i++) {
			result += characters.charAt(Math.floor(Math.random() * characters.length));
		}
		return result;
	}
	useEffect(() => {
		setCaptcha(generateCaptcha());
	}, []);
	const handleCaptchaInputChange = (event) => {
		setCaptchaInput(event.target.value);
	};

	function validateEmail(email) {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(String(email).toLowerCase());
	}

	return (
		<main>
			<div class="container">
				<div id="alert-template" style={{ display: 'none' }}><br />
					<div class="alert alert-warning alert-dismissible" role="alert">
						<div id="alertText"></div>
						<button type="button" class="close" data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span></button>
					</div>
				</div>
				<div id="message"></div>
				<div class="row justify-content-center align-items-center py-4">
					<div class="col-md-10">
						<div class="shadow-sm rounded bg-white mb-3">
							<div class="box-body p-3" >
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 section-title text-center mb-7">
										<h5 class="font-weight-bold mt-4">Contact Task Beehive</h5>
										<p class="text-muted">Contact us for feedback, questions, site issues, investment, or media requests.</p>

									</div>
								</div>
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 section-title text-center mb-7">
										Please first view the <Link to="/faq"> Frequently Asked Questions</Link>. If your question has not been answered you can send a message to TaskBeehive with the form below.
									</div><br /><br /><br /><br /><br />
								</div>
								<div class="row justify-content-center align-items-center">
									<div class="col-lg-7 col-md-7 mb-7">
										<form id="target" onSubmit={handleContact}>
											<div class="form-group">
												<label>Full Name <small class="text-danger">*</small></label> <input
													type="text" maxlength="250" placeholder="Your Name" class="form-control"
													id="name" name="name" required
													data-validation-required-message="Please enter your name." />
												<p class="help-block"></p>
											</div>
											<div class="form-group">
												<div class="controls">
													<label>Email Address <small class="text-danger">*</small></label>
													<input type="email" maxlength="250" placeholder="youremail@gmail.com"
														class="form-control" id="email" name="email" required
														data-validation-required-message="Please enter your email address." />
												</div>
											</div>
											<div class="form-group">
												<div class="controls">
													<label>Subject <small class="text-danger">*</small></label>
													<input type="text" maxlength="500" placeholder="Subject"
														class="form-control" id="subject" name="subject" required
														data-validation-required-message="Please enter the subject of your email." />
												</div>
											</div>
											<div class="form-group">
												<label>Message <small class="text-danger">*</small></label>
												<textarea maxlength="2500" placeholder="Hi there, I would like to ..." rows="4"
													cols="100" class="form-control" id="message" name="message" required
													data-validation-required-message="Please enter your message"
												></textarea>
											</div>

											<div class="shadow-sm rounded bg-white mb-3">
												<div class="box-title border-bottom p-3">
													<h6 class="m-0">Captcha Check
													</h6>
													<p class="mb-0 mt-0 small">Type the characters you see in the image below to confirm that you're not a robot.</p>
												</div>
												<div class="box-body">
													<div class="p-3">
														Captcha:
														<svg width="200" height="50" style={{ marginLeft: '20px' }} xmlns="http://www.w3.org/2000/svg">
															<rect x="1" y="1" width="198" height="48" fill="white" stroke="black" strokeWidth="1" />
															{captcha.split('').map((char, index) => (
																<text
																	key={index}
																	x={20 + 30 * index}
																	y="30"
																	fontSize="20"
																	fontFamily="monospace"
																	fill="black"
																	transform={`rotate(${Math.random() * 10 - 5} ${20 + 30 * index}, 30)`}
																>
																	{char}
																</text>
															))}
														</svg><br /><br />
														<div class="js-form-message">
															<label id="titleOfService" class="form-label">
																Type The Captcha Above:
															</label>
															<div class="form-group">
																<input required class="form-control" type="text" maxlength="6" value={captchaInput} onChange={handleCaptchaInputChange} />
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="text-right">
												<button type="submit" disabled={isSubmitting} class="btn btn-success"
													id="sendMessageButton">Send Message</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main >
	);
}

export default Contact;
